/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import './unidades.css';

//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { Button, Snackbar } from '@material-ui/core';
import { ThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';

//COMPONENTS
import CustomToolbar from './components/customToolbar';
import ModalEdit from './components/modalEdit';
import ModalDelete from './components/modalDelete';
import ModalAdicionar from './components/modalAdicionar';

//ACTIONS
import GetUnidades from '../../../utils/redux/actions/unidades/unidadesAction';
import GetUnidade from '../../../utils/redux/actions/unidades/getUnidadeAction';
import GetParceirosAlimentacao from '../../../utils/redux/actions/parceiros/getParceirosAlimentacaoAction';
import GetParceirosLavandaria from '../../../utils/redux/actions/parceiros/getParceirosLavandariaAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    color: theme.palette.getContrastText(yellow[600]),
    // backgroundColor: yellow[600],
    border: `1px solid #fdd835`,
    '&:hover': {
      // backgroundColor: yellow[700],
      border: '1px solid yellow[700]',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnAdicionar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
}));

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Unidades = () => {
  const classes = useStyles();
  const [responsive] = useState('vertical');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [unidade, setUnidade] = useState({
    parceiroAlimentacaoId: '',
    parceiroLavandariaId: '',
    descricao: '',
    direcoes: '',
    morada: '',
    codpostal: '',
    localidade: '',
  });
  const [unidadeId, setUnidadeId] = useState();
  const [unidadeNome, setUnidadeNome] = useState();
  const [parceiroAlimentacaoSelecionado, setParceiroAlimentacaoSelecionado] = useState({
    id: 0,
    nome: '',
  });
  const [parceiroLavandariaSelecionado, setParceiroLavandariaSelecionado] = useState({
    id: 0,
    nome: '',
  });
  const dispatch = useDispatch();

  const parceiros = useSelector((state) => state.parceiros.parceiros);

  const parceirosAlimentacao = useSelector((state) => state.parceiros.parceirosAlimentacao);
  const parceirosLavandaria = useSelector((state) => state.parceiros.parceirosLavandaria);

  // console.log({ parceirosAlimentacao, parceirosLavandaria });

  const resetUnidade = () => {
    setUnidade({
      parceiroAlimentacaoId: '',
      parceiroLavandariaId: '',
      descricao: '',
      direcoes: '',
      morada: '',
      codpostal: '',
      localidade: '',
    });
  };

  const unidades = useSelector((state) => state.unidades.unidades);
  const unidadeInfo = useSelector((state) => state.unidades.unidade);

  const fetchUnidades = async () => {
    await dispatch(GetUnidades()).then(() => dispatch({ type: 'CLEAR_DATA_UNIDADE' }));
  };

  const fetchParceiros = () => {
    dispatch(GetParceirosAlimentacao());
    dispatch(GetParceirosLavandaria());
  };

  useEffect(() => fetchUnidades(), []);
  useEffect(() => fetchParceiros(), []);

  const fetchUnidade = (id, e) => {
    e.preventDefault();
    return dispatch(GetUnidade(id));
  };

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setErrorsUnidade({});
    if (btn == 'edit') {
      setUnidadeId(id);
      setUnidadeNome(tableMeta.rowData[0]);
      fetchUnidade(id, e).then(() => setOpenEdit(true));
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setUnidadeId(id);
      setUnidadeNome(tableMeta.rowData[0]);
    } else {
      setOpenAdicionar(true);
      resetUnidade();
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    setErrorsUnidade({});
    if (btn == 'edit') {
      setOpenEdit(false);
      // setParceiroSelecionado({
      //   parceirosId: '',
      //   descricao: '',
      // });

      // dispatch({ type: 'CLEAR_DATA_UNIDADE' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else {
      setOpenAdicionar(false);
      // resetCategoria();
    }
  };

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackAdd, setOpenSnackAdd] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackDelete, setOpenSnackDelete] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'edit') {
      setOpenSnack(true);
    } else if (btn == 'delete') {
      setOpenSnackDelete(true);
    } else if (btn == 'add') {
      setOpenSnackAdd(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenSnackDelete(false);
    setOpenSnackAdd(false);
  };

  //------------------------------------------------------------------------//

  const columns = [
    {
      name: 'descricao',
      label: 'Descrição',
    },
    {
      name: 'parceiroAlimentacaoNome',
      label: 'Parceiro de Alimentação',
    },
    {
      name: 'parceiroLavandariaNome',
      label: 'Parceiro de Lavandaria',
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="table-btns-categorias">
              <Button
                onClick={(e) => handleOpen('edit', e, value, tableMeta)}
                className={classes.btnEditar}
                style={{ marginRight: '20px' }}
              >
                <EditIcon style={{ color: 'white' }} />
              </Button>

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
                className={classes.btnApagar}
              >
                <DeleteIcon style={{ color: 'white' }} />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    customToolbar: () => {
      return <CustomToolbar handleOpen={handleOpen} />;
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  // -------------------------- Validacao unidades --------------------//
  const [errorsUnidade, setErrorsUnidade] = useState({});

  const validationUnidade = (fieldValues = unidade) => {
    const temp = { ...errorsUnidade };

    if ('parceiroAlimentacaoId' in fieldValues)
      temp.parceiroAlimentacaoId = fieldValues.parceiroAlimentacaoId ? '' : 'Campo Obrigatório';
    if ('parceiroLavandariaId' in fieldValues)
      temp.parceiroLavandariaId = fieldValues.parceiroLavandariaId ? '' : 'Campo Obrigatório';
    if ('descricao' in fieldValues)
      temp.descricao = fieldValues.descricao ? '' : 'Campo Obrigatório';
    if ('morada' in fieldValues) temp.morada = fieldValues.morada ? '' : 'Campo Obrigatório';
    if ('codpostal' in fieldValues)
      temp.codpostal = fieldValues.codpostal ? '' : 'Campo Obrigatório';
    if ('localidade' in fieldValues)
      temp.localidade = fieldValues.localidade ? '' : 'Campo Obrigatório';
    if ('direcoes' in fieldValues) temp.direcoes = fieldValues.direcoes ? '' : 'Campo Obrigatório';

    setErrorsUnidade({
      ...temp,
    });
    if (fieldValues == unidade) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <>
        <Snackbar open={openSnackAdd} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Unidade adicionada com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Unidade editada com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackDelete} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Unidade removida com sucesso!
          </Alert>
        </Snackbar>
        <ModalAdicionar
          openAdicionar={openAdicionar}
          handleClose={handleClose}
          unidade={unidade}
          setUnidade={setUnidade}
          validationUnidade={validationUnidade}
          errorsUnidade={errorsUnidade}
          fetchUnidades={fetchUnidades}
          handleOpenAlert={handleOpenAlert}
          parceirosAlimentacao={parceirosAlimentacao}
          parceirosLavandaria={parceirosLavandaria}
        />
        <ModalEdit
          openEdit={openEdit}
          handleClose={handleClose}
          unidade={unidade}
          setUnidade={setUnidade}
          validationUnidade={validationUnidade}
          errorsUnidade={errorsUnidade}
          fetchUnidades={fetchUnidades}
          unidadeId={unidadeId}
          handleOpenAlert={handleOpenAlert}
          unidadeInfo={unidadeInfo}
          unidadeNome={unidadeNome}
          parceiroAlimentacaoSelecionado={parceiroAlimentacaoSelecionado}
          setParceiroAlimentacaoSelecionado={setParceiroAlimentacaoSelecionado}
          parceiroLavandariaSelecionado={parceiroLavandariaSelecionado}
          setParceiroLavandariaSelecionado={setParceiroLavandariaSelecionado}
          parceiros={parceiros}
          parceirosAlimentacao={parceirosAlimentacao}
          parceirosLavandaria={parceirosLavandaria}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          unidadeId={unidadeId}
          fetchUnidades={fetchUnidades}
          unidadeNome={unidadeNome}
          handleOpenAlert={handleOpenAlert}
        />
        <MUIDataTable
          title="Unidades"
          description="Nada para mostrar"
          data={unidades}
          columns={columns}
          options={options}
        />
        {/* </MuiThemeProvider> */}
      </>
    </ThemeProvider>
  );
};

export default Unidades;
