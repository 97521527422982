import Instance from '../../../../Axios';

const EditarTooltipLimpeza = (ydata, id) => async (dispatch) => {
  await Instance()
    .put(`/backend/checklist/tooltip/${id}`, { ydata })
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'PUT_TOOLTIP_LIMPEZA', payload: data });
      //   show('success', 'Foram associados novos funcionários á obra');
    })
    .catch((err) => {
      // eslint-disable-next-line eqeqeq
      if (err.response.status != 200) {
        // show('warning', 'Preencha todos os campos');
      }
    });
};

export default EditarTooltipLimpeza;
