/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import AddIcon from '@material-ui/icons/Add';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
//COMPONENTS
import ModalEdit from './components/modalEdit';
import ModalDelete from './components/modalDelete';
import ModalAdicionar from './components/modalAdicionar';
import CustomToolbar from './components/customToolbar';

//ACTIONS
import GetItensLimpeza from '../../../../utils/redux/actions/limpeza/Items/itensLimpezaAction';
import GetItemLimpeza from '../../../../utils/redux/actions/limpeza/Items/getItemLimpezaAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    color: theme.palette.getContrastText(yellow[600]),
    // backgroundColor: yellow[600],
    border: `1px solid #fdd835`,
    '&:hover': {
      // backgroundColor: yellow[700],
      border: '1px solid yellow[700]',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnAdicionar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
}));
const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ItensLimpeza = () => {
  const classes = useStyles();
  const [responsive] = useState('vertical');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [item, setItem] = useState({
    descricao: '',
    categoriasId: 1,
  });

  const dispatch = useDispatch();

  const resetItem = () => {
    setItem({
      descricao: '',
      categoriasId: 1,
    });
  };

  const fetchItensLimpeza = () => {
    dispatch(GetItensLimpeza());
    // dispatch({ type: 'CLEAR_DATA_PARCEIRO' });
  };

  const fetchItemLimpeza = (id) => {
    return dispatch(GetItemLimpeza(id));
    // dispatch({ type: 'CLEAR_DATA_PARCEIRO' });
  };

  const itensLimpeza = useSelector((state) => state.limpeza.itensLimpeza);

  const categoriasLimpeza = useSelector((state) => state.limpeza.categoriasLimpeza);

  const itemInfo = useSelector((state) => state.limpeza.itemLimpeza);

  // const fetchParceiro = (id, e) => {
  //   e.preventDefault();
  //   return dispatch(GetParceiro(id));
  // };

  const [itemId, setItemId] = useState();
  const [itemNome, setItemNome] = useState();

  useEffect(() => fetchItensLimpeza(), []);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    validationItensLimpeza({});
    if (btn == 'edit') {
      setItemId(id);
      setItemNome(tableMeta.rowData[0]);
      fetchItemLimpeza(id, e).then(() => setOpenEdit(true));
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setItemId(id);
      setItemNome(tableMeta.rowData[0]);
    } else {
      resetItem();
      setOpenAdicionar(true);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    validationItensLimpeza({});
    if (btn == 'edit') {
      setOpenEdit(false);
      // dispatch({ type: 'CLEAR_DATA_PARCEIRO' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else {
      setOpenAdicionar(false);
      resetItem();
    }
    // resetParceiro();
  };

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackAdd, setOpenSnackAdd] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackDelete, setOpenSnackDelete] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'edit') {
      setOpenSnack(true);
    } else if (btn == 'delete') {
      setOpenSnackDelete(true);
    } else if (btn == 'add') {
      setOpenSnackAdd(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenSnackDelete(false);
    setOpenSnackAdd(false);
  };

  //-------------------------------------------------------------------------//

  const columns = [
    {
      name: 'descricao',
      label: 'Descrição',
    },
    {
      name: 'categoriaDescricao',
      label: 'Categoria',
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns">
              <Button
                onClick={(e) => handleOpen('edit', e, value, tableMeta)}
                className={classes.btnEditar}
                style={{ marginRight: '20px' }}
              >
                <EditIcon style={{ color: 'white' }} />
              </Button>
              {/* <Button
                onClick={(e, tableMeta) =>
                  // window.alert(`Clicked "Edit" for row ${tableMeta.rowIndex}`)
                  handleOpen('edit', e, value)
                }
                className={classes.btnEditar1}
                style={{ marginRight: '20px' }}
              >
                <EditIcon style={{ color: '#fdd835' }} />
              </Button>
              <Tooltip
                title={'Remover Parceiro'}
                style={{ height: '40px', width: '40px', marginRight: '20px' }}
              >
                <IconButton
                  className={classes.iconButton}
                  // onClick={this.handleClick}
                >
                  <DeleteIcon style={{ color: '#f44336' }} />
                </IconButton>
              </Tooltip> */}

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
                className={classes.btnApagar}
              >
                <DeleteIcon style={{ color: 'white' }} />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,
    customToolbar: () => {
      return <CustomToolbar handleOpen={handleOpen} />;
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  // -------------------------- Validacao do parceiro -----------------//
  const [errorsItensLimpeza, setErrorsItensLimpeza] = useState({});

  const validationItensLimpeza = (fieldValues = item) => {
    const temp = { ...errorsItensLimpeza };
    if ('descricao' in fieldValues)
      temp.descricao = fieldValues.descricao ? '' : 'Campo Obrigatório';
    if ('categoriasId' in fieldValues)
      temp.categoriasId = fieldValues.categoriasId ? '' : 'Campo Obrigatório';

    setErrorsItensLimpeza({
      ...temp,
    });
    if (fieldValues == item) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <>
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Categoria Editada com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackDelete} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Categoria Removida com sucesso!
          </Alert>
        </Snackbar>

        <Snackbar open={openSnackAdd} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Categoria Adicionada com sucesso!
          </Alert>
        </Snackbar>
        {/* <Button
          className={classes.btnAdicionar}
          onClick={(e) => {
            handleOpen('add', e);
          }}
        >
          Adicionar Parceiro
        </Button> */}
        <ModalAdicionar
          openAdicionar={openAdicionar}
          handleClose={handleClose}
          item={item}
          setItem={setItem}
          validationItensLimpeza={validationItensLimpeza}
          errorsItensLimpeza={errorsItensLimpeza}
          fetchItensLimpeza={fetchItensLimpeza}
          categorias={categoriasLimpeza}
          handleOpenAlert={handleOpenAlert}
        />
        <ModalEdit
          openEdit={openEdit}
          handleClose={handleClose}
          item={item}
          setItem={setItem}
          validationItensLimpeza={validationItensLimpeza}
          errorsItensLimpeza={errorsItensLimpeza}
          fetchItensLimpeza={fetchItensLimpeza}
          itemInfo={itemInfo}
          itemId={itemId}
          itemNome={itemNome}
          handleOpenAlert={handleOpenAlert}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          itemId={itemId}
          fetchItensLimpeza={fetchItensLimpeza}
          itemNome={itemNome}
          handleOpenAlert={handleOpenAlert}
        />
        <MUIDataTable
          title="Itens"
          description="Nada para mostrar"
          data={itensLimpeza}
          columns={columns}
          options={options}
          style={{ marginBottom: '30px !important' }}
        />
        {/* </MuiThemeProvider> */}
      </>
    </ThemeProvider>
  );
};

export default ItensLimpeza;
