/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { Button, Snackbar } from '@material-ui/core';
import { ThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import lightBlue from '@material-ui/core/colors/lightBlue';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { FaAppleAlt } from 'react-icons/fa';
import MuiAlert from '@material-ui/lab/Alert';

//COMPONENTS
import CustomToolbar from './components/customToolbar';
import ModalEdit from './components/modalEdit';
import ModalDelete from './components/modalDelete';
import ModalAdicionar from './components/modalAdicionar';
import ModalCategorias from './components/modalCategorias';
import ModalProdutos from './components/modalProdutos';

//ACTIONS
import GetMenus from '../../../utils/redux/actions/menus/menusAction';
import GetMenu from '../../../utils/redux/actions/menus/getMenuAction';

import GetParceirosAlimentacao from '../../../utils/redux/actions/parceiros/getParceirosAlimentacaoAction';
import GetMenuCategorias from '../../../utils/redux/actions/menus/getMenuCategoriasAction';
import GetMenuProdutos from '../../../utils/redux/actions/menus/getMenuProdutosAction';
import GetLinguagens from '../../../utils/redux/actions/getLinguagensAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    color: theme.palette.getContrastText(yellow[600]),
    // backgroundColor: yellow[600],
    border: `1px solid #fdd835`,
    '&:hover': {
      // backgroundColor: yellow[700],
      border: '1px solid yellow[700]',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnAdicionar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  btnCategorias: {
    color: theme.palette.getContrastText(lightBlue[500]),
    backgroundColor: lightBlue[500],
    '&:hover': {
      backgroundColor: lightBlue[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: lightBlue[500],
      },
    },
  },
  btnProdutos: {
    color: theme.palette.getContrastText(green['A400']),
    backgroundColor: green['A400'],
    '&:hover': {
      backgroundColor: green['A700'],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green['A400'],
      },
    },
  },
}));

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Menus = () => {
  const classes = useStyles();
  const [responsive] = useState('vertical');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [openCategorias, setOpenCategorias] = useState(false);
  const [openProdutos, setOpenProdutos] = useState(false);
  const [menu, setMenu] = useState({
    descricao: '',
    parceirosId: '',
  });
  const [menuId, setMenuId] = useState();
  const [menuNome, setMenuNome] = useState();
  const [parceiroSelecionado, setParceiroSelecionado] = useState({
    descricao: '',
    parceirosId: 0,
  });
  const [defaultSelector, setDefaultSelector] = useState({
    id: 0,
    descricao: '',
    qtd: 0,
    ordem: '',
  });

  const [defaultSelectorProdutos, setDefaultSelectorProdutos] = useState({
    id: 0,
    descricao: '',
    extra: 0,
  });

  const dispatch = useDispatch();

  const parceiros = useSelector((state) => state.parceiros.parceirosAlimentacao);
  // console.log(parceiros);

  const resetMenu = () => {
    setMenu({
      descricao: '',
      parceirosId: '',
    });
  };
  const resetDefaultSelector = () => {
    setDefaultSelector({
      id: 0,
      descricao: '',
      qtd: 0,
    });
  };
  const resetDefaultSelectorProdutos = () => {
    setDefaultSelectorProdutos({
      id: 0,
      descricao: '',
      qtd: 0,
    });
  };

  const menus = useSelector((state) => state.menus.menus);

  const menuInfo = useSelector((state) => state.menus.menu);
  // console.log(menuInfo)

  const fetchMenus = () => {
    dispatch(GetMenus());
    dispatch({ type: 'CLEAR_DATA_MENU' });
  };

  const fetchParceiros = () => {
    dispatch(GetParceirosAlimentacao());
  };

  const fetchLinguagens = () => {
    dispatch(GetLinguagens()).then(() =>
      setLingState(linguagens.map((ling) => ({ linguasId: ling.id, descricao: '' }))),
    );
  };

  const linguagens = useSelector((state) => state.global.linguagens);
  const [lingState, setLingState] = useState({ id: 0, descricao: '' });
  const [empty, setEmpty] = useState(null);

  useEffect(() => fetchMenus(), []);
  useEffect(() => fetchParceiros(), []);
  useEffect(() => fetchLinguagens(), []);

  const fetchMenu = (id, e) => {
    e.preventDefault();
    dispatch(GetMenu(id)).then(() => setOpenEdit(true));
  };

  const fetchMenuCategorias = (id) => {
    dispatch(GetMenuCategorias(id)).then(() => {
      setOpenCategorias(true);
    });
  };

  const menuCategorias = useSelector((state) => state.menus.menuCategorias);

  const fetchProdutos = (id) => {
    dispatch(GetMenuProdutos(id)).then(() => setOpenProdutos(true));
  };

  const produtos = useSelector((state) => state.menus.menuProdutos);
  const [validationMessage, setValidationMessage] = useState(false);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setErrorsMenu({});
    setMenuId(id);
    if (btn == 'edit') {
      setMenuNome(tableMeta.rowData[0]);
      fetchMenu(id, e);
      setEmpty(null);
    } else if (btn == 'delete') {
      setOpenDelete(true);

      setMenuNome(tableMeta.rowData[0]);
    } else if (btn == 'categorias') {
      setMenuNome(tableMeta.rowData[0]);
      fetchMenuCategorias(id);
      resetDefaultSelector();
    } else if (btn == 'produtos') {
      setMenuNome(tableMeta.rowData[0]);
      fetchProdutos(id);
      resetDefaultSelectorProdutos();
      setValidationMessage(false);
    } else {
      setOpenAdicionar(true);
      resetMenu();
      setEmpty(null);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    setErrorsMenu({});
    if (btn == 'edit') {
      setOpenEdit(false);
      // setParceiroSelecionado({
      //   descricao: '',
      //   parceirosId: '',
      // });
      // dispatch({ type: 'CLEAR_DATA_MENU' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else if (btn == 'categorias') {
      setOpenCategorias(false);
    } else if (btn == 'produtos') {
      setOpenProdutos(false);
    } else {
      setOpenAdicionar(false);
      // resetCategoria();
    }
    // resetMenu();
  };
  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackAdd, setOpenSnackAdd] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackDelete, setOpenSnackDelete] = useState(false);
  const [openSnackCategorias, setOpenSnackCategorias] = useState(false);
  const [openSnackProdutos, setOpenSnackProdutos] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'edit') {
      setOpenSnack(true);
    } else if (btn == 'delete') {
      setOpenSnackDelete(true);
    } else if (btn == 'add') {
      setOpenSnackAdd(true);
    } else if (btn == 'categorias') {
      setOpenSnackCategorias(true);
    } else if (btn == 'produtos') {
      setOpenSnackProdutos(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenSnackDelete(false);
    setOpenSnackAdd(false);
    setOpenSnackCategorias(false);
    setOpenSnackProdutos(false);
  };

  //------------------------------------------------------------------------//

  const columns = [
    {
      name: 'descricao',
      label: 'Descrição',
    },
    {
      name: 'parceiroNome',
      label: 'Parceiro',
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-categorias">
              <Button
                onClick={(e) => handleOpen('edit', e, value, tableMeta)}
                className={classes.btnEditar}
                style={{ marginRight: '20px' }}
              >
                <EditIcon style={{ color: 'white' }} />
              </Button>

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
                className={classes.btnApagar}
                style={{ marginRight: '20px' }}
              >
                <DeleteIcon style={{ color: 'white' }} />
              </Button>

              <Button
                onClick={(e) => handleOpen('categorias', e, value, tableMeta)}
                className={classes.btnCategorias}
                style={{ marginRight: '20px' }}
              >
                <FormatListBulletedIcon style={{ color: 'white' }} />
              </Button>
              <Button
                onClick={(e) => handleOpen('produtos', e, value, tableMeta)}
                className={classes.btnProdutos}
                // style={{ marginRight: '20px' }}
              >
                <FaAppleAlt size="1.3em" style={{ color: 'white' }} />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    customToolbar: () => {
      return <CustomToolbar handleOpen={handleOpen} />;
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  // -------------------------- Validacao menus --------------------//
  const [errorsMenu, setErrorsMenu] = useState({});

  const validationMenu = (fieldValues = menu) => {
    const temp = { ...errorsMenu };
    // if ('descricao' in fieldValues)
    //   temp.descricao = fieldValues.descricao ? '' : 'Campo Obrigatório';
    if ('parceirosId' in fieldValues)
      temp.parceirosId = fieldValues.parceirosId ? '' : 'Campo Obrigatório';

    setErrorsMenu({
      ...temp,
    });
    if (fieldValues == menu) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//
  // -------------------------- Validacao Custo --------------------//

  // console.log(produtos.yData);

  const validationCategorias = (fieldValues = produtos.yData) => {
    // const temp = { ...errorsMenu };
    // if ('descricao' in fieldValues)
    //   temp.descricao = fieldValues.descricao ? '' : 'Campo Obrigatório';

    if (Array.isArray(fieldValues)) {
      fieldValues.map((value) => {
        if (value.custo > 10) {
          value.errorCusto = 'Max. 10€';
        } else {
          value.errorCusto = '';
        }

        return value;
      });
    } else {
      if (fieldValues.custo > 10) {
        fieldValues.errorCusto = 'Max. 10€';
      } else {
        fieldValues.errorCusto = '';
      }
    }

    // setErrorsMenu({
    //   ...temp,
    // });
    if (fieldValues == produtos.yData) {
      if (Array.isArray(fieldValues)) {
        return Object.values(fieldValues.map((a) => a.errorCusto)).every((x) => x == '');
      }
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <>
        <Snackbar open={openSnackAdd} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Menu adicionado com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Menu editado com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackDelete} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Menu removido com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackCategorias} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Categorias editadas com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackProdutos} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Produtos editados com sucesso!
          </Alert>
        </Snackbar>

        <ModalAdicionar
          openAdicionar={openAdicionar}
          handleClose={handleClose}
          menu={menu}
          setMenu={setMenu}
          validationMenu={validationMenu}
          errorsMenu={errorsMenu}
          fetchMenus={fetchMenus}
          handleOpenAlert={handleOpenAlert}
          parceiros={parceiros}
          //Linguagens
          linguagens={linguagens}
          lingState={lingState}
          setLingState={setLingState}
          empty={empty}
          setEmpty={setEmpty}
        />
        <ModalEdit
          openEdit={openEdit}
          handleClose={handleClose}
          menu={menu}
          setMenu={setMenu}
          validationMenu={validationMenu}
          errorsMenu={errorsMenu}
          fetchMenus={fetchMenus}
          menuId={menuId}
          handleOpenAlert={handleOpenAlert}
          menuInfo={menuInfo}
          menuNome={menuNome}
          parceiroSelecionado={parceiroSelecionado}
          setParceiroSelecionado={setParceiroSelecionado}
          parceiros={parceiros}
          //Linguagens
          linguagens={linguagens}
          lingState={lingState}
          setLingState={setLingState}
          empty={empty}
          setEmpty={setEmpty}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          menuId={menuId}
          fetchMenus={fetchMenus}
          menuNome={menuNome}
          handleOpenAlert={handleOpenAlert}
        />
        <ModalCategorias
          openCategorias={openCategorias}
          handleClose={handleClose}
          menuCategorias={menuCategorias}
          menuId={menuId}
          fetchMenus={fetchMenus}
          menuNome={menuNome}
          handleOpenAlert={handleOpenAlert}
          defaultSelector={defaultSelector}
          setDefaultSelector={setDefaultSelector}
        />
        <ModalProdutos
          openProdutos={openProdutos}
          handleClose={handleClose}
          menuId={menuId}
          fetchMenus={fetchMenus}
          menuNome={menuNome}
          handleOpenAlert={handleOpenAlert}
          produtos={produtos}
          defaultSelectorProdutos={defaultSelectorProdutos}
          setDefaultSelectorProdutos={setDefaultSelectorProdutos}
          validationCategorias={validationCategorias}
          //Mensagem de erro
          validationMessage={validationMessage}
          setValidationMessage={setValidationMessage}
        />
        <MUIDataTable
          title="Menus"
          description="Nada para mostrar"
          data={menus}
          columns={columns}
          options={options}
        />
        {/* </MuiThemeProvider> */}
      </>
    </ThemeProvider>
  );
};

export default Menus;
