/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import {
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableHead,
  Box,
  Typography,
} from '@material-ui/core';

import { makeStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';

// import AddIcon from '@material-ui/icons/Add';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import { format, startOfMonth, addDays } from 'date-fns';

//COMPONENTS
import CustomToolbar from './components/customToolbar';
import FormSelectors from './components/formSelectors';

//ACTIONS

import GetParceirosAlimentacao from '../../../../utils/redux/actions/parceiros/getParceirosAlimentacaoAction';

//Icons
import Adult from '../../../../images/icons/adult.png';
import Kid from '../../../../images/icons/kid.png';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    color: theme.palette.getContrastText(yellow[600]),
    // backgroundColor: yellow[600],
    border: `1px solid #fdd835`,
    '&:hover': {
      // backgroundColor: yellow[700],
      border: '1px solid yellow[700]',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnAdicionar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  table: {
    minWidth: 700,
  },
}));
const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

// ---------------------------- Window dimensions -------------------------//

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
// ---------------------------------------------------------------------//

const AnaliseDeVendasRelatorios = () => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [responsive] = useState('vertical');
  const dimensions = useWindowDimensions();
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [selectedDateInicio, setSelectedDateInicio] = useState(startOfMonth(new Date()));
  const [selectedDateFim, setSelectedDateFim] = useState(addDays(new Date(), 1));
  const [parceiro, setParceiro] = useState({
    id: 1,
  });
  const [parceiroSelecionado, setParceiroSelecionado] = useState({ id: 1 });

  const dispatch = useDispatch();
  let Total = 0;

  const analiseDeVendas = useSelector((state) => state.relatorios.analiseDeVendasRelatorio);

  const analiseDeVendasPDF = useSelector((state) => state.relatorios.analiseDeVendasPDF);

  const fetchParceiro = () => {
    dispatch(GetParceirosAlimentacao());

    // dispatch({ type: 'CLEAR_DATA_PARCEIRO' });
  };
  const parceiros = useSelector((state) => state.parceiros.parceirosAlimentacao);

  useEffect(() => fetchParceiro(), []);

  if (analiseDeVendas.length > 0) {
    if (!analiseDeVendas.find((venda) => venda.numPessoas === 'Total')) {
      analiseDeVendas &&
        analiseDeVendas.map((dados) => {
          Total += dados.total;
        });

      analiseDeVendas.push({
        numPessoas: 'Total',
        total: Total && Total.toFixed(2),
      });
    }
  }

  const columns = [
    {
      name: 'Reserva Id',
      label: 'Reserva Id',
      options: {
        filter: true,
        sort: false,
        empty: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          let r = analiseDeVendas.filter((reserva) => reserva.reservaId === tableMeta.rowData[6]);
          r = r.length > 0 ? r[0] : r;
          if (r.reservaBookingID || r.reservaSiteID) {
            return (
              <div
                className="table-btns-categorias"
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                <p key={r.id}>
                  {r.reservaBookingID != null ? r.reservaBookingID : r.reservaSiteID}
                  {r.reservaSiteID != null ? r.reservaSiteID : r.reservaBookingID}
                </p>
              </div>
            );
          }
        },
      },
    },
    {
      name: 'dataPequenoAlmoco',
      label: 'Data Peq. Almoço',
      options: {
        filter: true,
        sort: false,
        empty: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            const formatedCheckIn = format(new Date(value), 'dd-MM-yyyy');

            return (
              <div
                className="table-btns-categorias"
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                <p>{formatedCheckIn}</p>
              </div>
            );
          }
        },
      },
    },
    {
      name: 'clienteNome',
      label: 'Cliente',
    },
    {
      name: 'apartamentoNome',
      label: 'Apartamento',
    },
    {
      name: 'numPessoas',
      label: 'Nº Hóspedes',

      options: {
        filter: true,
        sort: false,
        empty: true,
        align: 'right',

        customBodyRender: (value, tableMeta, updateValue) => {
          let r = analiseDeVendas.filter((reserva) => reserva.reservaId === tableMeta.rowData[6]);

          const nAdultos = r[0].numPessoasAdultas;
          const nCriancas = r[0].numPessoasCriancas;
          const descricao = r[0].numPessoas;

          if (nAdultos != undefined || nCriancas != undefined) {
            return (
              <div
                className="table-btns-categorias"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                {nAdultos > 0 && nCriancas > 0 ? (
                  <p style={{ display: 'flex', alignItems: 'center' }}>
                    {nAdultos}
                    <img src={Adult} style={{ width: '20px', marginLeft: '3px' }} /> + {nCriancas}
                    <img src={Kid} style={{ width: '18px', marginLeft: '4px' }} />
                  </p>
                ) : null}
                {nAdultos > 0 && nCriancas <= 0 ? (
                  <p style={{ display: 'flex', alignItems: 'center' }}>
                    {nAdultos}
                    <img src={Adult} style={{ width: '20px', marginLeft: '3px' }} />
                  </p>
                ) : null}
              </div>
            );
          }
          if (descricao != undefined) {
            return (
              <div>
                <p>{descricao}</p>
              </div>
            );
          }
        },
      },
    },
    {
      name: 'total',
      label: 'Total',
      options: {
        filter: true,
        sort: false,
        empty: true,
        // align: 'right',

        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index} style={{ textAlign: 'end' }}>
              {column.label}
            </TableCell>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta);
          return (
            <div
              className="table-btns-categorias"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <p>{tableMeta.rowData[5]} €</p>
            </div>
          );
        },
      },
    },

    {
      name: 'reservaId',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
      },
    },
    {
      name: 'produtosAdulto',
      label: 'Produtos Adulto',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
      },
    },
    {
      name: 'produtosCrianca',
      label: 'Produtos Criança',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    search: false,
    responsive: 'standard',
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,
    customToolbar: () => {
      return (
        <CustomToolbar
          analiseDeVendas={analiseDeVendas}
          selectedDateInicio={selectedDateInicio}
          selectedDateFim={selectedDateFim}
          parceiroSelecionado={parceiroSelecionado}
          analiseDeVendasPDF={analiseDeVendasPDF}
        />
      );
    },
    // customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
    //   const startIndex = page * rowsPerPage;
    //   const endIndex = (page + 1) * rowsPerPage;
    //   return (
    //     <MUIDataTable
    //       data={[{ descricao: 'Total', Total, id: 0 }]}
    //       columns={columnsFooter}
    //       options={optionsFooter}
    //     />
    //   );
    // },
    expandableRows: true,
    isRowExpandable: (dataIndex) => {
      // const noExpand = analiseDeVendas && analiseDeVendas.length - 1 == dataIndex;

      // console.log(dataIndex, analiseDeVendas.length);

      // if (noExpand == true) {
      //   return false;
      // } else {
      //   return true;
      // }

      //   if (loc_array[loc_array.length - 1] === 'index.html') {
      //     // do something
      //  } else {
      //     // something else
      //  }

      // if (dataIndex === 3 || dataIndex === 4) return false;

      // // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      // if (expandedRows.data.length > 4 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) return false;
      return true;
    },

    renderExpandableRow: (rowData, rowMeta) => {
      console.log({ rowData, rowMeta });

      return (
        <React.Fragment>
          {/* <tr>
            <td colSpan={2}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: '650' }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Produtos</TableCell>
                      <TableCell>Preço</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowData[7].map((row) => (
                      <>
                        {console.log(rowData[7])}
                        <TableRow key={row.id}>
                          <TableCell>{row.produtoNome}</TableCell>
                          <TableCell>{row.custo}</TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr> */}

          {rowData[2] && (
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan={7}>
                <Box margin={0}>
                  <Typography style={{ marginLeft: '15px', marginTop: '20px' }} variant="h6">
                    Menu de Adulto(s)
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Produto</TableCell>
                        <TableCell>Quantidade</TableCell>
                        <TableCell align="right">Preço (€)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowData &&
                        rowData[7] &&
                        rowData[7].map((prod) => (
                          <TableRow key={prod.produtoNome}>
                            {/* {console.log(prod)} */}
                            <TableCell component="th" scope="venda">
                              {prod.produtoNome}
                            </TableCell>
                            <TableCell component="th" scope="venda" style={{ width: '40%' }}>
                              {prod.qtd}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="venda"
                              align="right"
                              style={{ width: '9%' }}
                            >
                              {prod.total} €
                            </TableCell>
                          </TableRow>
                        ))}
                      <TableRow key={'total'}>
                        <TableCell component="th" scope="venda"></TableCell>
                        <TableCell component="th" scope="venda" align="left">
                          <Box
                            // style={{ marginRight: '-128px', marginleftt: '218px' }}
                            style={{ display: 'flex' }}
                          >
                            <Box style={{ width: '51.5%' }}></Box>
                            <Box style={{ width: '48.5%' }}>
                              <p style={{ marginLeft: '17px' }}>Sub-total</p>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell component="th" scope="venda" align="right">
                          {rowData[7].reduce((prev, next) => prev + next.total, 0).toFixed(2)} €
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {rowData && rowData[8].length > 0 ? (
                    <>
                      <br />

                      <Typography style={{ marginLeft: '15px' }} variant="h6">
                        Menu de Criança(s)
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>Produto</TableCell>
                            <TableCell>Quantidade</TableCell>
                            <TableCell align="right">Preço (€)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rowData &&
                            rowData[8] &&
                            rowData[8].map((prod) => (
                              <TableRow key={prod.produtoNome}>
                                {/* {console.log(prod)} */}
                                <TableCell component="th" scope="venda">
                                  {prod.produtoNome}
                                </TableCell>
                                <TableCell component="th" scope="venda" style={{ width: '40%' }}>
                                  {prod.qtd}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="venda"
                                  align="right"
                                  style={{ width: '9%' }}
                                >
                                  {prod.total} €
                                </TableCell>
                              </TableRow>
                            ))}
                          <TableRow key={'total'}>
                            <TableCell component="th" scope="venda"></TableCell>
                            <TableCell component="th" scope="venda" align="left">
                              <Box
                                // style={{ marginRight: '-128px', marginleftt: '218px' }}
                                style={{ display: 'flex' }}
                              >
                                <Box style={{ width: '51.5%' }}></Box>
                                <Box style={{ width: '48.5%' }}>
                                  <p style={{ marginLeft: '17px' }}>Sub-total</p>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell component="th" scope="venda" align="right">
                              {rowData[8].reduce((prev, next) => prev + next.total, 0).toFixed(2)} €
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </>
                  ) : null}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      );
    },

    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <>
        <FormSelectors
          analiseDeVendas={analiseDeVendas}
          columns={columns}
          options={options}
          parceiros={parceiros}
          selectedDateInicio={selectedDateInicio}
          setSelectedDateInicio={setSelectedDateInicio}
          selectedDateFim={selectedDateFim}
          setSelectedDateFim={setSelectedDateFim}
          parceiro={parceiro}
          setParceiro={setParceiro}
          parceiroSelecionado={parceiroSelecionado}
          setParceiroSelecionado={setParceiroSelecionado}
          dimensions={dimensions}
        />
      </>
    </ThemeProvider>
  );
};

export default AnaliseDeVendasRelatorios;
