import Instance from '../../../../Axios';

// New Actions for the app

const GetAnaliseDeVendasRelatorios =
  (formatSelectedDateInicio, formatSelectedDateFim, parceiroId) => async (dispatch) => {
    await Instance()
      .get(
        `/backend/relatorios/analisevendas/${formatSelectedDateInicio}/${formatSelectedDateFim}/${parceiroId}`,
      )
      .then(({ data }) => {
        console.log(data);
        dispatch({
          type: 'GET_ANALISE_DE_VENDAS_RELATORIOS',
          payload: data.yData,
        });
        dispatch({
          type: 'GET_ANALISE_DE_VENDAS_RELATORIOS_RESUMO',
          payload: data.grafico,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetAnaliseDeVendasRelatorios;
