/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const SenhaJaAssociada = ({ dispatch }) => {
  const history = useHistory();

  //   useEffect(() => fetch(), []);

  const loginRedirect = (e) => {
    e.preventDefault();
    history.push('/');
    // Limpar aqui a "senha" no Redux
    dispatch({ type: 'CLEAR_DADOS' });
  };

  return (
    <>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '40px',
          }}
        >
          <p style={{ color: 'black' }}>Este link já foi utilizado.</p>
        </div>

        <Button
          variant="contained"
          color="primary"
          className="btn"
          type="submit"
          onClick={loginRedirect}
        >
          Entrar na conta
        </Button>
      </ThemeProvider>
    </>
  );
};

export default SenhaJaAssociada;
