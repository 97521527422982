import Instance from '../../../Axios';

// New Actions for the app

const GetParceiros = () => async (dispatch) => {
  await Instance()
    .get(`/backend/parceiros`)
    .then(({ data }) => {
      //   console.log(data);
      dispatch({ type: 'GET_PARCEIROS', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetParceiros;
