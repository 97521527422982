/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import '../parceiros.css';

//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

//ACTIONS
import EditarProduto from '../../../../utils/redux/actions/produtos/editarProdutoAction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalEdit = ({
  openEdit,
  handleClose,
  produto,
  setProduto,
  validateOnChange = true,
  validationProduto,
  errorsProduto = null,
  fetchProdutos,
  produtoId,
  handleOpenAlert,
  produtoNome,
  produtoInfo,
  categoriaSelecionada,
  setCategoriaSelecionada,
  // LINGUAGENS
  empty,
  setEmpty,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const categorias = useSelector((state) => state.categorias.categorias);

  const selectProduto = () => {
    if (categorias.length > 0 && produto.categoriasId) {
      const ProdutoAssociado = categorias.find((prod) => prod.id === produto.categoriasId);

      setCategoriaSelecionada(
        ProdutoAssociado || {
          id: 0,
          descricao: '',
        },
      );
    } else {
      setCategoriaSelecionada({
        id: 0,
        descricao: '',
      });
    }
  };

  useEffect(() => selectProduto(), [produto, categorias]);

  // const handleChange = (e) => {
  //   e.preventDefault();

  //   setProduto({
  //     ...produto,
  //     [e.target.name]: e.target.value,
  //   });
  //   if (validateOnChange) validationProduto({ [e.target.name]: e.target.value });
  // };

  const handleChange = (e, value) => {
    e.preventDefault();
    const catIndex =
      produto.descricao && produto.descricao.findIndex((c) => c.linguasId === value.linguasId);

    produto.descricao[catIndex].descricao = e.target.value;

    dispatch({ type: 'GET_PRODUTO', payload: produto });

    const allEmpty = produto.descricao.map((l) => l.descricao.length > 0).find((a) => a == true);

    if (allEmpty != true) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
  };

  const handleChangeCategoriasId = (e, value) => {
    e.preventDefault();
    setCategoriaSelecionada(
      value || {
        id: 0,
        descricao: '',
      },
    );
    setProduto({
      ...produto,
      categoriasId: (value && value.id) || 0,
    });
    // console.log(produto.categoriasId.id);
    if (validateOnChange) validationProduto({ categoriasId: value });
  };

  useEffect(() => {
    setProduto({
      ...produtoInfo,
      descricao: produtoInfo.descricao,
      categoriasId: produtoInfo.categorias_id,
    });
  }, [produtoInfo, setProduto]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const allEmpty = produto.descricao.map((l) => l.descricao.length > 0).find((a) => a == true);

    if (empty == true || allEmpty == true) {
      if (validationProduto()) {
        const ydata = [
          {
            descricao: produto.descricao,
            categoriasId: produto.categoriasId,
          },
        ];

        console.log(ydata);

        dispatch(EditarProduto(ydata, produtoId))
          .then(() => handleClose('edit', e))
          .then(() => fetchProdutos())
          .then(() => handleOpenAlert('edit'));
      }
    } else {
      setEmpty(false);
      validationProduto();
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        onClose={(e) => handleClose('edit', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Editar Produto
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode editar o produto <b> {produtoNome}</b>, que irá ser distribuido pelos
                  parceiros.
                </p>
              </div>
              <div>
                {produto &&
                  produto.descricao &&
                  produto.descricao.length > 0 &&
                  produto.descricao.map((prod) => (
                    <div key={prod.linguasId}>
                      <TextField
                        // id={ling.id}
                        name="descricao"
                        label={prod.linguasDescricao}
                        onChange={(e) => handleChange(e, prod)}
                        value={prod.descricao}
                        fullWidth
                        autoComplete="off"
                        className="nome"
                        {...(empty == false && {
                          error: true,
                        })}
                      />
                    </div>
                  ))}
              </div>
              <div>
                <Autocomplete
                  id="categoriasId"
                  name="categoriasId"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={categorias}
                  getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                  value={categoriaSelecionada || ''}
                  getOptionSelected={(option, value) => {
                    return value.id === option.id;
                  }}
                  // eslint-disable-next-line no-unused-vars
                  filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                  onChange={(e, value) => handleChangeCategoriasId(e, value)}
                  onInputChange={(event, value, reason) => {
                    if (reason == 'clear') {
                      setProduto({
                        ...produto,
                        categoriasId: 0,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categoria"
                      placeholder="Categoria"
                      // variant='outlined'
                      {...(errorsProduto.categoriasId && {
                        error: true,
                        helperText: errorsProduto.categoriasId,
                      })}
                    />
                  )}
                />
              </div>

              {empty == false && (
                <div style={{ color: '#f44336', marginTop: '15px', fontSize: '12px' }}>
                  {' '}
                  *Preencher pelo menos um campo
                </div>
              )}

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('edit', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalEdit;
