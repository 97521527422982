import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';

const defaultToolbarStyles = {
  iconButton: {},
};

class CustomToolbar extends React.Component {
  handleClick = (e) => {
    console.log(this.props.handleOpen('add', e));
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={'Adicionar Utilizadores'}>
          <IconButton className={classes.iconButton} onClick={this.handleClick}>
            <AddIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(CustomToolbar);
