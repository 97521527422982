import { apartamentosInitialState } from '../initialState/apartamentos';

const apartamentosReducer = (state = apartamentosInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_APARTAMENTOS':
      return {
        ...state,
        apartamentos: payload,
      };
    case 'PUT_APARTAMENTO':
      return {
        ...state,
        apartamentoEditado: payload,
      };
    case 'POST_APARTAMENTOS':
      return {
        ...state,
        novoApartamento: payload,
      };
    case 'DELETE_APARTAMENTO':
      return {
        ...state,
        apartamentoRemovido: payload,
      };
    case 'GET_APARTAMENTO':
      return {
        ...state,
        apartamento: payload,
      };
    case 'GET_LIMPEZAS_APARTAMENTO':
      return {
        ...state,
        limpezas: payload,
      };

    case 'CLEAR_DATA_APARTAMENTOS':
      return {
        ...state,
        apartamento: [],
      };

    default:
      return state;
  }
};

export default apartamentosReducer;
