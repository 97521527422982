import { unidadesInitialState } from '../initialState/unidades';

const produtosReducer = (state = unidadesInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_UNIDADES':
      return {
        ...state,
        unidades: payload,
      };
    case 'POST_UNIDADES':
      return {
        ...state,
        novaUnidade: payload,
      };
    case 'PUT_UNIDADE':
      return {
        ...state,
        unidadeEditada: payload,
      };
    case 'DELETE_UNIDADE':
      return {
        ...state,
        unidadeRemovida: payload,
      };
    case 'GET_UNIDADE':
      return {
        ...state,
        unidade: payload,
      };

    case 'CLEAR_DATA_UNIDADE':
      return {
        ...state,
        unidade: [],
      };

    default:
      return state;
  }
};

export default produtosReducer;
