/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React from 'react';
//PACKAGES

import { useDispatch } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

//IMAGES
import Logo from '../../../images/logo_valleybeach_black.png';

//ACTIONS

import FormRecuperarSenha from './components/formRecuperarSenha';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const RecuperarSenha = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const hash = location.pathname.split('/')[2];

  const storage = localStorage;

  return (
    <>
      {storage.token ? (
        history.push('/app/reservas')
      ) : (
        <ThemeProvider theme={defaultMaterialTheme}>
          <div className="home">
            <div className="home-left">
              <img src={Logo} alt="" className="logo" />
              <div className="login-form">
                <div className="titulo">
                  <h2>Bem Vindo!</h2>
                </div>
                Preencha o campo abaixo com o email associado á conta que quer recuperar a sua
                palavra passe.
                <br />
                <br />
                <br />
                {/* {dados.length === 0 ? <SenhaJaAssociada /> : null} */}
                <div style={{ width: '100%' }}>
                  <FormRecuperarSenha
                    onSubmit
                    form="log_in_form"
                    dispatch={dispatch}
                    // dados={dados}
                    // fetch={fetch}
                    hash={hash}
                  />
                  <Link to="/" style={{ color: 'black' }}>
                    <p>Voltar para o Login</p>
                  </Link>
                </div>
              </div>
              <div className="bottom"></div>
            </div>
            <div className="home-right"></div>
          </div>
        </ThemeProvider>
      )}
    </>
  );
};

export default RecuperarSenha;
