import React, { useState } from 'react';
//Packages
import { format } from 'date-fns';
import MUIDataTable from 'mui-datatables';
import {
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableHead,
  Box,
  Typography,
} from '@material-ui/core';

const TabelaMenus = ({ reserva }) => {
  const [responsive] = useState('vertical');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');

  console.log(reserva);

  const columns = [
    // 0
    {
      name: 'menu',
      label: 'Menu',
      options: {
        display: true,
      },
    },
    // 1
    {
      name: 'data',
      label: 'Data',
      options: {
        filter: true,
        sort: false,
        empty: true,

        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(tableMeta);
          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <p>{format(new Date(tableMeta.rowData[1]), 'dd-MM-yyyy')}</p>
            </div>
          );
        },
      },
    },
    // 2
    {
      name: 'menusAdultos',
      label: 'Adultos',
      options: {
        display: false,
      },
    },
    // 3
    {
      name: 'menusCriancas',
      label: 'Criancas',
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    search: false,
    responsive: 'standard',
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,
    // customToolbar: () => {
    //   return (
    //     <CustomToolbar
    //       analiseDeVendas={analiseDeVendas}
    //       selectedDateInicio={selectedDateInicio}
    //       selectedDateFim={selectedDateFim}
    //       parceiroSelecionado={parceiroSelecionado}
    //       analiseDeVendasPDF={analiseDeVendasPDF}
    //     />
    //   );
    // },

    expandableRows: true,
    isRowExpandable: (dataIndex) => {
      return true;
    },

    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <React.Fragment>
          {rowData[0] && (
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan={7}>
                <Box margin={0}>
                  <Typography style={{ marginLeft: '15px', marginTop: '20px' }} variant="h6">
                    Menu de Adulto(s)
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Produto</TableCell>
                        <TableCell>Quantidade</TableCell>
                        <TableCell align="right">Preço (€)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowData &&
                        rowData[2] &&
                        rowData[2].map((prod) => (
                          <TableRow key={prod.encomendasId}>
                            <TableCell component="th" scope="venda">
                              {prod.produto}
                            </TableCell>
                            <TableCell component="th" scope="venda" style={{ width: '25%' }}>
                              {prod.qtd}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="venda"
                              align="right"
                              style={{ width: '22%' }}
                            >
                              {prod.valor} €
                            </TableCell>
                          </TableRow>
                        ))}
                      <TableRow key={'total'}>
                        <TableCell component="th" scope="venda"></TableCell>
                        <TableCell component="th" scope="venda" align="left">
                          <Box style={{ display: 'flex' }}>
                            <Box style={{ width: '100%' }}>
                              <p>Sub-total</p>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell component="th" scope="venda" align="right">
                          {rowData[2].reduce((prev, next) => prev + next.valor, 0).toFixed(2)} €
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {rowData && rowData[3].length > 0 ? (
                    <>
                      <br />

                      <Typography style={{ marginLeft: '15px' }} variant="h6">
                        Menu de Criança(s)
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>Produto</TableCell>
                            <TableCell>Quantidade</TableCell>
                            <TableCell align="right">Preço (€)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rowData &&
                            rowData[3] &&
                            rowData[3].map((prod) => (
                              <TableRow key={prod.encomendasId}>
                                <TableCell component="th" scope="venda">
                                  {prod.produto}
                                </TableCell>
                                <TableCell component="th" scope="venda" style={{ width: '25%' }}>
                                  {prod.qtd}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="venda"
                                  align="right"
                                  style={{ width: '22%' }}
                                >
                                  {prod.valor} €
                                </TableCell>
                              </TableRow>
                            ))}
                          <TableRow key={'total'}>
                            <TableCell component="th" scope="venda"></TableCell>
                            <TableCell component="th" scope="venda" align="left">
                              <Box style={{ display: 'flex' }}>
                                <Box style={{ width: '100%' }}>
                                  <p>Sub-total</p>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell component="th" scope="venda" align="right">
                              {rowData[3].reduce((prev, next) => prev + next.valor, 0).toFixed(2)} €
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </>
                  ) : null}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      );
    },

    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  return (
    <>
      <MUIDataTable
        title="Histórico"
        description="Nada para mostrar"
        data={reserva && reserva.encomendas}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default TabelaMenus;
