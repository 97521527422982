/* eslint-disable no-unused-vars */
import React from 'react';
//PACKAGES
import { Route, Switch, Redirect } from 'react-router-dom';

//COMPONENTS
import PrivateRoute from './PrivateRoute';
import Dashboard from '../pages/App/Dashboard/dashboard';
import Admin from '../pages/Admin';
//app
import Reservas from '../pages/App/Reservas/reservas';
import Reserva from '../pages/App/Reservas/reserva/reserva';
// import Fechaduras from '../pages/App/Fechaduras/fechaduras';
import PequenosAlmocos from '../pages/App/PequenosAlmocos/pequenosAlmocos';
import HistoricoReservas from '../pages/App/Reservas/historico/historico';
//admin
import Parceiros from '../pages/Admin/Parceiros/parceiros';
import Utilizadores from '../pages/Admin/Utilizadores/utilizadores';
import Menus from '../pages/Admin/Menus/menus';
import Categorias from '../pages/Admin/Categorias/categorias';
import Produtos from '../pages/Admin/Produtos/produtos';
import Apartamentos from '../pages/Admin/Apartamentos/apartamentos';
import Unidades from '../pages/Admin/Unidades/unidades';
// import CategoriasLimpeza from '../pages/Admin/Limpezas/Categorias/CategoriasLimpeza';
import ItensLimpeza from '../pages/Admin/Limpezas/Items/ItensLimpeza';
import TooltipsLimpeza from '../pages/Admin/Limpezas/Tooltips/tooltips';
import AnaliseDeVendasRelatorios from '../pages/Admin/Relatorios/AnalisesDeVendas/analiseDeVendasRelatorios';

const Router = ({ windowDimensions }) => {
  return (
    <>
      <Switch>
        {/* app */}
        <PrivateRoute exact path="/app" component={Dashboard} />
        <PrivateRoute exact path="/app/reservas" component={Reservas} />
        <PrivateRoute exact path="/app/reservas/historico" component={HistoricoReservas} />
        <PrivateRoute exact path="/app/reservas/:id" component={Reserva} />
        {/* <PrivateRoute exact path='/app/fechaduras' component={Fechaduras} /> */}
        <PrivateRoute exact path="/app/pequenos-almocos" component={PequenosAlmocos} />

        {/* admin */}
        <PrivateRoute exact path="/admin" component={Admin} />
        <PrivateRoute exact path="/admin/parceiros" component={Parceiros} />
        <PrivateRoute exact path="/admin/utilizadores" component={Utilizadores} />
        <PrivateRoute exact path="/admin/menus">
          <Menus />
        </PrivateRoute>
        <PrivateRoute exact path="/admin/categorias" component={Categorias} />
        <PrivateRoute exact path="/admin/produtos" component={Produtos} />
        <PrivateRoute exact path="/admin/apartamentos" component={Apartamentos} />
        <PrivateRoute exact path="/admin/unidades" component={Unidades} />
        {/* <PrivateRoute
          exact
          path='/admin/limpeza/categorias'
          component={CategoriasLimpeza}
        /> */}
        <PrivateRoute exact path="/admin/limpeza/itens" component={ItensLimpeza} />
        <PrivateRoute exact path="/admin/limpeza/tooltips" component={TooltipsLimpeza} />
        <PrivateRoute
          exact
          path="/admin/relatorios/analise-de-vendas"
          component={AnaliseDeVendasRelatorios}
        />

        {/* geral */}
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </>
  );
};

export default Router;
