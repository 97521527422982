import React from 'react';
// ACTIONS
import AdicionarProduto from '../../../../utils/redux/actions/produtos/adicionarProdutoAction';

// PACKAGES

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalAdicionar = ({
  openAdicionar,
  handleClose,
  produto,
  setProduto,
  validateOnChange = true,
  validationProduto,
  errorsProduto = null,
  fetchProdutos,
  handleOpenAlert,
  // LINGUAGENS
  linguagens,
  lingState,
  setLingState,
  empty,
  setEmpty,
}) => {
  const classes = useStyles();

  const categorias = useSelector((state) => state.categorias.categorias);

  const dispatch = useDispatch();

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   setProduto({
  //     ...produto,
  //     [e.target.name]: e.target.value,
  //   });
  //   if (validateOnChange) validationProduto({ [e.target.name]: e.target.value });
  // };

  const handleChange = (e, value) => {
    // e.preventDefault();

    const lingIndex = lingState.findIndex((l) => l.linguasId === value.id);
    lingState[lingIndex].descricao = e.target.value;
    setLingState(lingState);

    const allEmpty = lingState.map((l) => l.descricao.length > 0).find((a) => a == true);

    console.log(allEmpty, empty);

    if (allEmpty != true) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
  };

  const handleChangeCategoriasId = (e, value) => {
    e.preventDefault();
    setProduto({
      ...produto,
      categoriasId: value && value.id,
    });
    // console.log(produto.categoriasId.id);
    if (validateOnChange) validationProduto({ categoriasId: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let prodDescricao = lingState.filter((l) => l.linguasId > 0);
    // prodDescricao = [{ descricao: prodDescricao }];

    console.log(empty);
    if (empty == true) {
      if (validationProduto()) {
        const ydata = [
          {
            descricao: prodDescricao,
            categoriasId: produto.categoriasId,
          },
        ];

        console.log(ydata);

        dispatch(AdicionarProduto(ydata))
          .then(() => handleClose('adicionar', e))
          .then(() => fetchProdutos())
          .then(() => handleOpenAlert('add'));
      }
    } else {
      setEmpty(false);
      validationProduto();
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdicionar}
        onClose={(e) => handleClose('adicionar', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdicionar}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Adicionar Produto
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode adicionar aqui um produto onde irão pertencer as suas{' '}
                  <Link to="/admin/categorias" style={{ color: 'black' }}>
                    <b>categorias</b>
                  </Link>{' '}
                  relacionadas.
                </p>
              </div>

              {/* <div>
                <TextField
                  id="Descricao"
                  name="descricao"
                  label="Descrição"
                  // variant='outlined'
                  // color='primary'
                  // value={categoria.descricao}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="nome"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsProduto.descricao && {
                    error: true,
                    helperText: errorsProduto.descricao,
                  })}
                />
              </div> */}
              {linguagens &&
                linguagens.length > 0 &&
                linguagens.map((ling) => (
                  <div key={ling.id}>
                    {ling.id > 0 && (
                      <TextField
                        // id={ling.id}
                        name="descricao"
                        label={ling.descricao}
                        onChange={(e) => handleChange(e, ling)}
                        fullWidth
                        autoComplete="off"
                        className="nome"
                        {...(empty == false && {
                          error: true,
                        })}
                      />
                    )}
                  </div>
                ))}
              <div>
                <Autocomplete
                  id="categoriasId"
                  name="categoriasId"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={categorias}
                  getOptionLabel={(option) => `${option.descricao}` || ''}
                  // value={enc}
                  getOptionSelected={(option, value) => {
                    if (!value.id) return true;

                    return value.id === option.id;
                  }}
                  // eslint-disable-next-line no-unused-vars
                  filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                  onChange={(e, value) => handleChangeCategoriasId(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categoria"
                      placeholder="Categoria"
                      // variant='outlined'
                      {...(errorsProduto.categoriasId && {
                        error: true,
                        helperText: errorsProduto.categoriasId,
                      })}
                    />
                  )}
                />
              </div>
              {empty == false && (
                <div style={{ color: '#f44336', marginTop: '15px', fontSize: '12px' }}>
                  {' '}
                  *Preencher pelo menos um campo
                </div>
              )}
              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('add', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
