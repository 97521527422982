import { produtosInitialState } from '../initialState/produtos';

const produtosReducer = (state = produtosInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_PRODUTOS':
      return {
        ...state,
        produtos: payload,
      };
    case 'POST_PRODUTOS':
      return {
        ...state,
        novoProduto: payload,
      };
    case 'PUT_PRODUTO':
      return {
        ...state,
        produtoEditado: payload,
      };
    case 'DELETE_PRODUTO':
      return {
        ...state,
        produtoRemovido: payload,
      };
    case 'GET_PRODUTO':
      return {
        ...state,
        produto: payload,
      };
    case 'CLEAR_DATA_PRODUTO':
      return {
        ...state,
        produto: [],
      };

    default:
      return state;
  }
};

export default produtosReducer;
