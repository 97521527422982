export const menusInitialState = {
  menus: [],
  novoMenu: [],
  menuRemovido: [],
  menuEditado: [],
  menu: [],

  menuCategorias: { yData: [], dropdown: [] },
  menuCategoriaAdicionada: [],

  menuProdutos: { yData: [], dropdown: [] },
  menuProdutosAdicionados: [],
};
