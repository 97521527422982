/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import './categorias.css';
//PACKAGES
import { useDispatch, useSelector } from 'react-redux';

import { Button, Snackbar } from '@material-ui/core';
import { ThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';

//COMPONENTS
import CustomToolbar from './components/customToolbar';
import ModalEdit from './components/modalEdit';
import ModalDelete from './components/modalDelete';
import ModalAdicionar from './components/modalAdicionar';

//ACTIONS
import GetCategorias from '../../../utils/redux/actions/categorias/categoriasAction';
import GetCategoria from '../../../utils/redux/actions/categorias/getCategoriaAction';
import GetLinguagens from '../../../utils/redux/actions/getLinguagensAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    color: theme.palette.getContrastText(yellow[600]),
    // backgroundColor: yellow[600],
    border: `1px solid #fdd835`,
    '&:hover': {
      // backgroundColor: yellow[700],
      border: '1px solid yellow[700]',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnAdicionar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
}));
const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Categorias = () => {
  const classes = useStyles();
  const [responsive] = useState('vertical');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [categoria, setCategoria] = useState([]);
  const [categoriaId, setCategoriaId] = useState();
  const [categoriaNome, setCategoriaNome] = useState();

  const dispatch = useDispatch();

  const resetCategoria = () => {
    setCategoria([]);
  };

  const categorias = useSelector((state) => state.categorias.categorias);

  const fetchCategorias = () => {
    dispatch(GetCategorias());
    dispatch({ type: 'CLEAR_DATA_CATEGORIA' });
  };

  const fetchCategoria = (id, e) => {
    e.preventDefault();
    return dispatch(GetCategoria(id));
  };

  const fetchLinguagens = () => {
    dispatch(GetLinguagens()).then(() =>
      setLingState(linguagens.map((ling) => ({ linguasId: ling.id, descricao: '' }))),
    );
  };

  const categoriaInfo = useSelector((state) => state.categorias.categoria);
  const linguagens = useSelector((state) => state.global.linguagens);
  const [lingState, setLingState] = useState({ id: 0, descricao: '' });
  const [empty, setEmpty] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchCategorias(), []);
  useEffect(() => fetchLinguagens(), []);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    // setErrorsLingState({});
    if (btn == 'edit') {
      setCategoriaId(id);
      setCategoriaNome(tableMeta.rowData[0]);
      fetchCategoria(id, e).then(() => setOpenEdit(true));
      setEmpty(null);
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setCategoriaId(id);
      setCategoriaNome(tableMeta.rowData[0]);
    } else {
      setOpenAdicionar(true);
      resetCategoria();
      setEmpty(null);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    // setErrorsLingState({});
    if (btn == 'edit') {
      setOpenEdit(false);
      // dispatch({ type: 'CLEAR_DATA_CATEGORIA' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else {
      setOpenAdicionar(false);
      // setErrorsLingState({});
      // setEmpty(null);
    }
  };

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackAdd, setOpenSnackAdd] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackDelete, setOpenSnackDelete] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'edit') {
      setOpenSnack(true);
    } else if (btn == 'delete') {
      setOpenSnackDelete(true);
    } else if (btn == 'add') {
      setOpenSnackAdd(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenSnackDelete(false);
    setOpenSnackAdd(false);
  };

  //------------------------------------------------------------------------//

  const columns = [
    {
      name: 'descricao',
      label: 'Descrição',
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-categorias">
              <Button
                onClick={(e) =>
                  // window.alert(`Clicked "Edit" for row ${tableMeta.rowIndex}`)
                  handleOpen('edit', e, value, tableMeta)
                }
                className={classes.btnEditar}
                style={{ marginRight: '20px' }}
              >
                <EditIcon style={{ color: 'white' }} />
              </Button>

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
                className={classes.btnApagar}
              >
                <DeleteIcon style={{ color: 'white' }} />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    customToolbar: () => {
      return <CustomToolbar handleOpen={handleOpen} />;
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  // -------------------------- Validacao categorias --------------------//
  // const [errorsLingState, setErrorsLingState] = useState({});

  // const validationCategoria = (fieldValues = lingState) => {
  //   const temp = { ...errorsLingState };

  //   const allEmpty = fieldValues.map((l) => l.descricao.length > 0).find((a) => a == true);

  //   if (allEmpty == undefined) {
  //     temp.empty = 'Campo Obrigatório';
  //   } else {
  //     temp.empty = '';
  //   }

  //   setErrorsLingState({
  //     ...temp,
  //   });
  //   if (fieldValues == lingState) {
  //     return Object.values(temp).every((x) => x == '');
  //   }
  //   return false;
  // };

  // -----------------------------------------------------------------------//

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <>
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Categoria editada com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackDelete} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Categoria removida com sucesso!
          </Alert>
        </Snackbar>

        <Snackbar open={openSnackAdd} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Categoria adicionada com sucesso!
          </Alert>
        </Snackbar>

        <ModalAdicionar
          openAdicionar={openAdicionar}
          handleClose={handleClose}
          categoria={categoria}
          setCategoria={setCategoria}
          // validationCategoria={validationCategoria}
          // errorsLingState={errorsLingState}
          fetchCategorias={fetchCategorias}
          handleOpenAlert={handleOpenAlert}
          //Linguagens
          linguagens={linguagens}
          lingState={lingState}
          setLingState={setLingState}
          empty={empty}
          setEmpty={setEmpty}
        />
        {/* <MuiThemeProvider theme={myTheme}> */}
        <ModalEdit
          openEdit={openEdit}
          handleClose={handleClose}
          categoria={categoria}
          setCategoria={setCategoria}
          // validationCategoria={validationCategoria}
          // errorsLingState={errorsLingState}
          fetchCategorias={fetchCategorias}
          categoriaInfo={categoriaInfo}
          categoriaId={categoriaId}
          handleOpenAlert={handleOpenAlert}
          categoriaNome={categoriaNome}
          //Linguagens
          linguagens={linguagens}
          lingState={lingState}
          setLingState={setLingState}
          empty={empty}
          setEmpty={setEmpty}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          categoriaId={categoriaId}
          fetchCategorias={fetchCategorias}
          categoria={categoria}
          categoriaNome={categoriaNome}
          handleOpenAlert={handleOpenAlert}
        />
        <MUIDataTable
          title="Categorias"
          description="Nada para mostrar"
          data={categorias}
          columns={columns}
          options={options}
        />
        {/* </MuiThemeProvider> */}
      </>
    </ThemeProvider>
  );
};

export default Categorias;
