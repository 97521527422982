/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, Fragment } from 'react';
import './reservas.css';
//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { TextField, makeStyles, Tooltip, IconButton, Snackbar } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { FaBed, FaUserAlt } from 'react-icons/fa';
import { BiBuildingHouse } from 'react-icons/bi';
import { RiHistoryLine } from 'react-icons/ri';

import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiAlert from '@material-ui/lab/Alert';

//COMPONENTS
import ModalAdicionar from './components/modalAdicionar';

//ACTIONS
import GetReservas from '../../../utils/redux/actions/reservas/reservasAction';
import GetApartamentosReserva from '../../../utils/redux/actions/reservas/reserva/getApartamentosReservaAction';
import GetLinguagens from '../../../utils/redux/actions/getLinguagensAction';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },

  searchIcon: {
    marginTop: '10%',
    marginBottom: '5px',
  },
  searchInput: {
    width: '200px',
    margin: '5px',
  },
  searchInput1: {
    width: '200px',
    // margin: '5px',
    marginRight: '10px',
  },

  reservaFotoApartamento: {
    backgroundSize: 'cover',
  },
  alignItems: {
    display: 'flex',
    alignItems: 'center',
  },
  semReservas: {
    width: '100%',
    textAlign: 'center',
    marginTop: '100px',
  },

  container: {
    // display: 'flex',
    // marginBottom: '5px',
  },
  paper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 50,
    height: 50,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Reservas = () => {
  const classes = useStyles();
  const [filter, setFilter] = useState('');
  // const [openEdit, setOpenEdit] = useState(false);
  // const [openDelete, setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [reserva, setReserva] = useState({
    apartamentosId: '',
    numAdultos: '',
    numCriancas: '',
    checkIn: '',
    checkOut: '',
    reservaBookingId: '',
    reservaSiteId: '',
    clienteNome: '',
    clienteEmail: '',
    clienteContacto: '',
    clienteMorada: '',
    clienteCodPostal: '',
    clienteLocalidade: '',
    linguagem: null,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const link = history.location.pathname;

  const reservas = useSelector((state) => state.reservas.reservas);
  const linguagens = useSelector((state) => state.global.linguagens);

  const resetReserva = () => {
    setReserva({
      apartamentosId: '',
      numAdultos: '',
      numCriancas: '',
      checkIn: '',
      checkOut: '',
      reservaBookingId: '',
      reservaSiteId: '',
      clienteNome: '',
      clienteEmail: '',
      clienteContacto: '',
      clienteMorada: '',
      clienteCodPostal: '',
      clienteLocalidade: '',
      linguagem: null,
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    setFilter(e.target.value);
  };

  const fetchReservas = () => {
    dispatch(GetReservas());
    dispatch({ type: 'CLEAR_DATA_RESERVA' });
  };

  const fetchApartamentos = () => {
    dispatch(GetApartamentosReserva());
    dispatch({ type: 'CLEAR_DATA_APARTAMENTOS' });
  };

  const fetchLinguagens = () => {
    dispatch(GetLinguagens());
  };

  useEffect(() => fetchReservas(), []);
  useEffect(() => fetchApartamentos(), []);
  useEffect(() => fetchLinguagens(), []);

  const checkFilter = (reserva) => {
    if (Object.keys(reserva).length > 0) {
      return (
        reserva.clienteNome.toLowerCase().includes(filter.toLowerCase()) ||
        reserva.apartamentoNome.toLowerCase().includes(filter.toLowerCase())
      );
    }
    return null;
  };

  const handleOpenReserva = (e, reserva) => {
    e.preventDefault();
    history.push(`${link}/${reserva.id}`);
  };

  const [checked, setChecked] = React.useState(false);

  // eslint-disable-next-line no-unused-vars
  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setErrorsReserva({});

    if (btn == 'edit') {
      // setOpenEdit(true);
      // setUnidadeId(id);
      // setUnidadeNome(tableMeta.rowData[0]);
      // fetchUnidade(id, e);
    } else if (btn == 'delete') {
      // setOpenDelete(true);
      // setUnidadeId(id);
      // setUnidadeNome(tableMeta.rowData[0]);
    } else {
      setOpenAdicionar(true);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();

    if (btn == 'edit') {
      // setOpenEdit(false);
      // setParceiroSelecionado({
      //   parceirosId: '',
      //   descricao: '',
      // });
      // setErrorsUnidade({});
      // dispatch({ type: 'CLEAR_DATA_UNIDADE' });
    } else if (btn == 'delete') {
      // setOpenDelete(false);
    } else {
      setOpenAdicionar(false);
      resetReserva();
      setErrorsReserva({});
    }
    // setUnidadeNome();
    resetReserva();
  };
  const handleChangeChecked = () => {
    setChecked((prev) => !prev);
  };

  // -------------------------- Validacao Reserva --------------------//
  const [errorsReserva, setErrorsReserva] = useState({});

  const validationReserva = (fieldValues = reserva) => {
    const temp = { ...errorsReserva };
    if ('apartamentosId' in fieldValues)
      temp.apartamentosId = fieldValues.apartamentosId ? '' : 'Campo Obrigatório';

    if ('numAdultos' in fieldValues) {
      if (fieldValues.numAdultos > 6) {
        temp.numAdultos = 'Até 6 Adultos';
      } else if (fieldValues.numAdultos <= 0) {
        temp.numAdultos = 'No mínimo 1 adulto';
      } else {
        temp.numAdultos = fieldValues.numAdultos ? '' : 'Campo Obrigatório';
      }
    }
    if ('numCriancas' in fieldValues) {
      if (fieldValues.numCriancas > 6) {
        temp.numCriancas = 'Até 6 Crianças';
      }
    }

    if ('checkIn' in fieldValues) {
      if (fieldValues.apartamentosId == 0) {
        temp.checkIn = 'Escolha um apartamento';
      } else {
        temp.checkIn = fieldValues.checkIn ? '' : 'Campo Obrigatório';
      }
    }

    if ('reservaBookingId' in fieldValues && 'reservaSiteId' in fieldValues) {
      if (
        fieldValues.reservaBookingId == '' &&
        (isNaN(fieldValues.reservaSiteId) || fieldValues.reservaSiteId <= 0)
      ) {
        temp.reservaBookingId = ' ';
        temp.reservaSiteId = 'Tem  de preencher pelo menos Booking Id ou Site Id';
      } else {
        temp.reservaBookingId = '';
        temp.reservaSiteId = '';
      }
    }

    if ('clienteNome' in fieldValues)
      temp.clienteNome = fieldValues.clienteNome ? '' : 'Campo Obrigatório';

    if ('clienteEmail' in fieldValues)
      // prettier-ignore
      temp.clienteEmail = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.clienteEmail) ? '' : 'Campo Obrigatório';

    if ('clienteContacto' in fieldValues)
      temp.clienteContacto = fieldValues.clienteContacto ? '' : 'Campo Obrigatório';
    // if ('clienteMorada' in fieldValues)
    //   temp.clienteMorada = fieldValues.clienteMorada ? '' : 'Campo Obrigatório';
    // if ('clienteCodPostal' in fieldValues)
    //   temp.clienteCodPostal = fieldValues.clienteCodPostal
    //     ? ''
    //     : 'Campo Obrigatório';
    // if ('clienteLocalidade' in fieldValues)
    //   temp.clienteLocalidade = fieldValues.clienteLocalidade
    //     ? ''
    //     : 'Campo Obrigatório';

    if ('linguagem' in fieldValues)
      temp.linguagem = fieldValues.linguagem ? '' : 'Campo Obrigatório';

    setErrorsReserva({
      ...temp,
    });
    if (fieldValues == reserva) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//
  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackAdd, setOpenSnackAdd] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'add') {
      setOpenSnackAdd(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackAdd(false);
  };

  //------------------------------------------------------------------------//

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <Snackbar open={openSnackAdd} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success">
          Reserva adicionada com sucesso!
        </Alert>
      </Snackbar>
      <ModalAdicionar
        openAdicionar={openAdicionar}
        handleClose={handleClose}
        reserva={reserva}
        setReserva={setReserva}
        validationReserva={validationReserva}
        errorsReserva={errorsReserva}
        fetchReservas={fetchReservas}
        fetchApartamentos={fetchReservas}
        resetReserva={resetReserva}
        handleOpenAlert={handleOpenAlert}
        linguagens={linguagens}
      />

      <div className="searchContainer">
        <div>
          <p className="tituloPagina">Reservas</p>
        </div>
        <div style={{ display: 'flex', height: '50px' }}>
          {/* {reservas.length > 0 ? (
            <div style={{ display: 'flex' }}>
              <SearchIcon className={classes.searchIcon} />
              <TextField
                onChange={handleChange}
                label='Pesquisar'
                variant='standard'
                className={classes.searchInput}
              />
            </div>
          ) : (
            <div className={classes.semReservas}>
              <p>De momento não existem reservas.</p>
            </div>
          )} */}
          {/* ----------------- */}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <FormControlLabel
              control={
                <>
                  <Tooltip
                    title={'Adicionar Reserva'}
                    style={{ height: '48px', marginRight: '-14px' }}
                  >
                    <IconButton className={classes.iconButton} onClick={handleChangeChecked}>
                      <SearchIcon className={classes.searchIcon} checked={checked} />
                    </IconButton>
                  </Tooltip>
                </>
              }
              // label='Show'
            />
            {checked == true ? (
              <div className={classes.container}>
                <Fade in={checked}>
                  <TextField
                    onChange={handleChange}
                    label="Pesquisar"
                    variant="standard"
                    className={classes.searchInput1}
                  />
                </Fade>
              </div>
            ) : null}
          </div>

          {/* --------------- */}
          {/* <Button className={classes.btnConfirmar}>+</Button> */}
          <div>
            <Tooltip title={'Adicionar Reserva'}>
              <IconButton className={classes.iconButton} onClick={(e) => handleOpen('add', e)}>
                <AddIcon className={classes.deleteIcon} />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            {' '}
            <Tooltip title={'Histórico'}>
              <IconButton
                className={classes.iconButton}
                onClick={() => history.push('/app/reservas/historico')}
              >
                <RiHistoryLine className={classes.deleteIcon} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      {/* <hr
        style={{ borderWidth: '1px', borderColor: 'rgba(204, 204, 204, 0.3)' }}
      /> */}

      <div className="listaReservas">
        {reservas ? (
          <>
            {reservas.map(
              (reserva) =>
                checkFilter(reserva) && (
                  <Fragment key={reserva.id}>
                    <div
                      className="reservaApartamentoCard"
                      onClick={(e) => handleOpenReserva(e, reserva)}
                    >
                      <div className="reservaFotoApartamento">
                        {reserva.apartamentoFoto == null ? (
                          <div
                            style={{
                              height: '200px',
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderBottom: '0.5px solid rgba(200, 200, 200, .6)',
                            }}
                          >
                            <BiBuildingHouse size="5em" />
                          </div>
                        ) : (
                          <div
                            style={{
                              // eslint-disable-next-line no-template-curly-in-string
                              backgroundImage: `url(${reserva.apartamentoFoto})`,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              // eslint-disable-next-line no-dupe-keys
                              // backgroundSize: '50%',
                              height: '200px',
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              borderRadius: '5px 5px 0px 0px',
                              borderBottom: '0.5px solid rgba(200, 200, 200, .6)',
                            }}
                          />
                        )}
                      </div>

                      <div className="reservaDescricao">
                        <div className={classes.alignItems} style={{ marginBottom: '10px' }}>
                          <div style={{}}>
                            <FaBed />
                          </div>
                          <div>
                            <p
                              style={{
                                marginLeft: '5px',
                              }}
                            >
                              {reserva.apartamentoNome}
                            </p>
                          </div>
                        </div>
                        <div className={classes.alignItems}>
                          <FaUserAlt />
                          <p style={{ marginLeft: '5px' }}>{reserva.clienteNome}</p>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ),
            )}
          </>
        ) : null}
      </div>
    </ThemeProvider>
  );
};

export default Reservas;
