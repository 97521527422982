/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { Button, Snackbar } from '@material-ui/core';
import { ThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';

//COMPONENTS
import CustomToolbar from './components/customToolbar';
import ModalEdit from './components/modalEdit';
import ModalDelete from './components/modalDelete';
import ModalAdicionar from './components/modalAdicionar';

//ACTIONS
import GetUtilizadores from '../../../utils/redux/actions/utilizadores/utilizadoresAction';
import GetUtilizador from '../../../utils/redux/actions/utilizadores/getUtilizadorAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    color: theme.palette.getContrastText(yellow[600]),
    // backgroundColor: yellow[600],
    border: `1px solid #fdd835`,
    '&:hover': {
      // backgroundColor: yellow[700],
      border: '1px solid yellow[700]',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnAdicionar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
}));

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Utilizadores = () => {
  const classes = useStyles();
  const [responsive] = useState('vertical');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [utilizadorId, setUtilizadorId] = useState();
  const [utilizadorNome, setUtilizadorNome] = useState();
  const [utilizador, setUtilizador] = useState({
    nome: '',
    email: '',
    perfisId: '',
  });
  const [utilizadorSelecionado, setUtilizadorSelecionado] = useState({
    id: 0,
    descricao: '',
  });

  const dispatch = useDispatch();

  const utilizadores = useSelector((state) => state.utilizadores.utilizadores);

  const utilizadorInfo = useSelector((state) => state.utilizadores.utilizador);

  const perfis = useSelector((state) => state.utilizadores.perfis);

  const fetchUtilizadores = () => {
    dispatch(GetUtilizadores());
    dispatch({ type: 'CLEAR_DATA_UTILIZADOR' });
  };

  const fetchUtilizador = (id) => {
    return dispatch(GetUtilizador(id));
  };

  const resetUtilizador = () => {
    setUtilizador({
      nome: '',
      email: '',
      perfisId: '',
    });
  };

  useEffect(() => fetchUtilizadores(), []);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setErrorsUtilizador({});
    if (btn == 'edit') {
      setUtilizadorId(id);
      setUtilizadorNome(tableMeta.rowData[0]);
      fetchUtilizador(id, e).then(() => setOpenEdit(true));
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setUtilizadorId(id);
      setUtilizadorNome(tableMeta.rowData[0]);
    } else {
      setOpenAdicionar(true);
      resetUtilizador();
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    setErrorsUtilizador({});
    if (btn == 'edit') {
      setOpenEdit(false);
      // setUtilizadorSelecionado({
      //   nome: '',
      //   email: '',
      //   perfisId: '',
      // });

      // dispatch({ type: 'CLEAR_DATA_UTILIZADOR' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else {
      setOpenAdicionar(false);
    }

    // resetUtilizador();
  };

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackAdd, setOpenSnackAdd] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackDelete, setOpenSnackDelete] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'edit') {
      setOpenSnack(true);
    } else if (btn == 'delete') {
      setOpenSnackDelete(true);
    } else if (btn == 'add') {
      setOpenSnackAdd(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenSnackDelete(false);
    setOpenSnackAdd(false);
  };

  //------------------------------------------------------------------------//

  // -------------------------- Validacao unidades --------------------//
  const [errorsUtilizador, setErrorsUtilizador] = useState({});

  const validationUtilizador = (fieldValues = utilizador) => {
    const temp = { ...errorsUtilizador };

    if ('email' in fieldValues) {
      // prettier-ignore
      temp.email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.email) ? '' : 'Coloque um email válido';
    }
    if ('nome' in fieldValues) temp.nome = fieldValues.nome ? '' : 'Campo Obrigatório';
    if ('perfisId' in fieldValues) temp.perfisId = fieldValues.perfisId ? '' : 'Campo Obrigatório';

    setErrorsUtilizador({
      ...temp,
    });
    if (fieldValues == utilizador) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  const columns = [
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'descricaoPerfil',
      label: 'Perfil',
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="table-btns-categorias">
              <Button
                onClick={(e) => handleOpen('edit', e, value, tableMeta)}
                className={classes.btnEditar}
                style={{ marginRight: '20px' }}
              >
                <EditIcon style={{ color: 'white' }} />
              </Button>

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
                className={classes.btnApagar}
              >
                <DeleteIcon style={{ color: 'white' }} />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    customToolbar: () => {
      return <CustomToolbar handleOpen={handleOpen} />;
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <>
        <Snackbar open={openSnackAdd} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Utilizador adicionado com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Utilizador editado com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackDelete} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Utilizador removido com sucesso!
          </Alert>
        </Snackbar>

        <ModalAdicionar
          openAdicionar={openAdicionar}
          handleClose={handleClose}
          utilizador={utilizador}
          setUtilizador={setUtilizador}
          validationUtilizador={validationUtilizador}
          errorsUtilizador={errorsUtilizador}
          fetchUtilizadores={fetchUtilizadores}
          handleOpenAlert={handleOpenAlert}
        />
        <ModalEdit
          openEdit={openEdit}
          handleClose={handleClose}
          utilizador={utilizador}
          setUtilizador={setUtilizador}
          validationUtilizador={validationUtilizador}
          errorsUtilizador={errorsUtilizador}
          fetchUtilizadores={fetchUtilizadores}
          utilizadorId={utilizadorId}
          handleOpenAlert={handleOpenAlert}
          utilizadorInfo={utilizadorInfo}
          utilizadorNome={utilizadorNome}
          utilizadorSelecionado={utilizadorSelecionado}
          setUtilizadorSelecionado={setUtilizadorSelecionado}
          perfis={perfis}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          utilizadorId={utilizadorId}
          fetchUtilizadores={fetchUtilizadores}
          utilizadorNome={utilizadorNome}
          handleOpenAlert={handleOpenAlert}
        />
        <MUIDataTable
          title="Utilizadores"
          description="Nada para mostrar"
          data={utilizadores}
          columns={columns}
          options={options}
        />
        {/* </MuiThemeProvider> */}
      </>
    </ThemeProvider>
  );
};

export default Utilizadores;
