import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { FaFilePdf } from 'react-icons/fa';
// import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
//PDF
import PDFResumo from './pdfResumo';

const defaultToolbarStyles = {
  iconButton: {},
};

class CustomToolbar extends React.Component {
  handleClick = (e) => {
    // console.log(this.props.handleOpen('add', e));

    const selectedDateInicio = this.props.selectedDateInicio;
    const selectedDateFim = this.props.selectedDateFim;
    const parceiro = this.props.parceiroSelecionado;
    const analiseDeVendasPDF = this.props.analiseDeVendasPDF;

    const dataInicio = format(new Date(selectedDateInicio), 'dd/MM/yyyy');
    const dataFim = format(new Date(selectedDateFim), 'dd/MM/yyyy');
    const dataImpressao = format(new Date(), 'dd/MM/yyyy');

    const ydata = this.props.analiseDeVendas;
    PDFResumo(ydata, dataInicio, dataFim, dataImpressao, parceiro, analiseDeVendasPDF);
  };

  render() {
    return (
      <React.Fragment>
        <Tooltip title={'Exportar PDF'}>
          <span>
            <IconButton
              // disabled={this.props.analiseDeVendas.length > 0 ? false : true}
              onClick={this.handleClick}
            >
              <FaFilePdf />
              {/* <PictureAsPdfIcon/> */}
            </IconButton>
          </span>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(CustomToolbar);
