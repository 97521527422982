/* eslint-disable eqeqeq */
import React, { useState, useContext } from 'react';
import { DatePicker, Day } from '@material-ui/pickers';
import { MuiPickersContext } from '@material-ui/pickers';
import withStyles from '@material-ui/core/styles/withStyles';
import { format } from 'date-fns';
import { InputAdornment, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import clsx from 'clsx';

import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import * as twix from 'twix';

function DateRangePicker({
  classes,
  value,
  onChange,
  labelFunc,
  formatString,
  emptyLabel,
  autoOk,
  onClose,
  setSelectedDateCheckIn,
  setSelectedDateCheckOut,
  setReserva,
  reserva,
  apartamentoReservas,
  ...props
}) {
  const [begin, setBegin] = useState(value && value[0]);
  const [end, setEnd] = useState(value && value[1]);
  const [hover, setHover] = useState(undefined);
  //   const picker = useRef();
  const utils = useContext(MuiPickersContext);

  const min = Math.min(begin, end || hover);
  const max = Math.max(begin, end || hover);

  const findDisabledDay = (day) => {
    let disabledDay = false;

    const formatDay = day && format(new Date(day), 'dd-MM-yyyy');
    for (let index = 0; index < apartamentoReservas.length; index++) {
      const dia = apartamentoReservas[index];
      const formatDia = format(new Date(dia), 'dd-MM-yyyy');

      if (formatDay === formatDia) {
        disabledDay = true;
        break;
      }
    }

    return disabledDay;
  };

  function renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
    return React.cloneElement(dayComponent, {
      onClick: (e) => {
        e.stopPropagation();
        if (dayComponent.props.disabled == false) {
          if (!begin) setBegin(day);
          else if (!end) {
            setEnd(day);
            // if (autoOk) {
            // onChange([begin, day].sort());
            // picker.current.close();
            // }
            if (day < begin) {
              setEnd(begin);
              setBegin(day);
            } else {
              setEnd(day);
              setBegin(begin);
            }

            let inicio = null,
              fim = null;
            if (day < begin) {
              inicio = day;
              fim = begin;
            } else {
              inicio = begin;
              fim = day;
            }

            const itr = moment(new Date(inicio)).twix(new Date(fim)).iterate('days');
            // const itr = moment(inicio._d).twix(fim._i).iterate('days');
            var range = [];
            while (itr.hasNext()) {
              range.push(itr.next().format('YYYY-MM-DD'));
            }

            range = range.map((date, index) => {
              if (index === 0) {
                return moment(new Date(date)).format('YYYY-MM-DD 14:00:00');
              }
              if (index === range.length - 1) {
                return moment(new Date(date)).format('YYYY-MM-DD 11:00:00');
              }

              const exists = apartamentoReservas.find((dat) => {
                if (
                  moment(new Date(dat)).format('YYYY-MM-DD') ===
                  moment(new Date(date)).format('YYYY-MM-DD')
                ) {
                  return dat;
                }
              });

              if (exists) {
                return moment(new Date(exists)).format('YYYY-MM-DD HH:mm:ss');
              }

              return moment(new Date(date)).format('YYYY-MM-DD 00:00:00');
            });

            range.some((dia) => {
              if (
                apartamentoReservas
                  .map((date) => moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss'))
                  .includes(dia)
              ) {
                setBegin(undefined);
                setEnd(undefined);

                return true;
              }

              return null;
            });
          } else {
            setBegin(day);
            setEnd(undefined);
          }
        }
      },

      onMouseEnter: (e) => setHover(day),
      className: clsx(classes.day, {
        [classes.hidden]: dayComponent.props.hidden,
        [classes.current]: dayComponent.props.current,
        // [classes.isDisabled]: dayComponent.props.disabled,
        [classes.isDisabled]: dayComponent.props.disabled || findDisabledDay(day),
        [classes.isSelected]: day >= min && day <= max,
        [classes.beginCap]: utils.isSameDay(day, min),
        [classes.endCap]: utils.isSameDay(day, max),
      }),
    });
  }

  const formatDate = (date) => utils.format(date, formatString || utils.dateFormat);

  return (
    <DatePicker
      {...props}
      value={begin}
      renderDay={renderDay}
      onClose={() => {
        onChange([begin, end].sort());
        setSelectedDateCheckIn(begin);
        setSelectedDateCheckOut(end);

        setReserva({
          ...reserva,
          checkIn: begin,
          checkOut: end,
        });

        if (onClose) onClose();
      }}
      // onChange={() => {}}
      onClear={() => {
        setBegin(undefined);
        setEnd(undefined);
        setHover(undefined);
        onChange([]);
      }}
      //   ref={picker}
      labelFunc={(date, invalid) =>
        labelFunc
          ? labelFunc([begin, end].sort(), invalid)
          : date && begin && end
          ? `${formatDate(begin)} - ${formatDate(end)}`
          : emptyLabel || ''
      }
      // InputProps={{
      //   endAdornment: (
      //     <InputAdornment position="end">
      //       <IconButton onClick={console.log('click para limpar')} edge="end">
      //         <ClearIcon />
      //       </IconButton>
      //     </InputAdornment>
      //   ),
      // }}
      // OpenPickerButtonProps={{
      //   onClick: () => console.log('click open picker button'),
      // }}
    />
  );
}

export const styles = (theme) => {
  //   const base = dayStyles(theme);
  return {
    // ...base,
    day: {
      //   ...base.day,
      margin: 0,
      width: '40px',
      height: '40px',
      borderRadius: '0',
      //   color: 'red',
      //   background: 'green',
    },
    beginCap: {
      // borderRadius: '50% 0 0 50%',
      // background: 'linear-gradient(135deg, pink 0%, pink 50%, rgba(255,255,255,1) 50%);',
      background: 'linear-gradient(135deg, rgba(230, 230, 230,1) 50%, pink 0%, pink 50%);',
    },
    endCap: {
      // borderRadius: '0 50% 50% 0',
      // clipPath: 'polygon(0% 100%, 100% 100%, 100% 45px, calc(100% - 40px) 0%, 0% 0%);',
      // background: 'rgb(2,0,36)',
      background: 'linear-gradient(135deg, pink 0%, pink 50%, rgba(230, 230, 230,1) 50%);',
    },
    isDisabled: {
      color: 'lightpink',
    },
    // hidden: {
    //   color: 'blue',
    // },
    isSelected: {
      backgroundColor: 'pink',

      // color: 'white',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  };
};

export default withStyles(styles, { name: 'DateRangePicker' })(DateRangePicker);
