/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useState } from 'react';

//PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { TiStarOutline, TiStarFullOutline } from 'react-icons/ti';
import { IoMdTrash } from 'react-icons/io';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

//ACTIONS
import AdicionarMenuProdutos from '../../../../utils/redux/actions/menus/adicionarProdutosMenuAction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 3, 5, 6),
    maxWidth: '750px',
    width: '750px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '80vh',
    margin: 15,
    // height: '100%',
    // overflowX: 'auto',
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  btnAdicionar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnAdicionarDisabled: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[200],
    '&:hover': {
      backgroundColor: green[300],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[200],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  warning: {
    fontSize: '12px',
    color: 'red',
  },
}));

const ModalProdutos = ({
  openProdutos,
  handleClose,
  menuId,
  fetchMenus,
  menuNome,
  handleOpenAlert,
  produtos,
  defaultSelectorProdutos,
  setDefaultSelectorProdutos,
  validationCategorias,
  // validateOnChange = true,

  //Mensagem de erro
  validationMessage,
  setValidationMessage,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState({});

  // console.log(produtos.yData);

  // console.log(produtos);

  // -------------------------- Validacao Custo --------------------//

  const [errorsMenu, setErrorsMenu] = useState({});
  const validationQtdProduto = (fieldValues = produtos.yData) => {
    const temp = { ...errorsMenu };

    if (Array.isArray(fieldValues)) {
      let temErro = false;
      fieldValues.map((value) => {
        value.prodQtd = '';
        if (value.qtd > value.catQtd) {
          // setValidationMessage(true);
          temErro = true;
          temp.prodQtd = '  ';
          value.prodQtd = '  ';
        } else {
          // setValidationMessage(false);
          temp.prodQtd = '';
          value.prodQtd = '';
        }

        return value;
      });
      if (temErro) {
        setValidationMessage(true);
      } else {
        setValidationMessage(false);
      }
    } else {
      if (fieldValues.qtd > fieldValues.catQtd) {
        setValidationMessage(true);
        temp.prodQtd = '  ';
        fieldValues.prodQtd = '  ';
      } else {
        setValidationMessage(false);
        temp.prodQtd = '';
        fieldValues.prodQtd = '';
      }
    }

    setErrorsMenu({
      ...temp,
    });
    console.log(
      fieldValues,
      Object.values(fieldValues.map((a) => a.prodQtd)).every((x) => x == ''),
    );
    if (fieldValues == produtos.yData) {
      if (Array.isArray(fieldValues)) {
        return Object.values(fieldValues.map((a) => a.prodQtd)).every((x) => x == '');
      }
    }
    return false;
  };

  // ------------------------------------------------------------//

  const handleExtra = (e, prod) => {
    e.preventDefault();

    const prodIndex = produtos.yData.findIndex((produto) => produto.id == prod.id);

    // console.log(e.target.value, c, menuCategorias);
    produtos.yData[prodIndex].extra = !prod.extra;

    dispatch({
      type: 'GET_MENU_PRODUTOS',
      // payload: { yData: produtos.yData, dropdown: produtos.dropdown },
      payload: { ...produtos },
    });
  };

  const handleChangeCusto = (e, prod, value) => {
    e.preventDefault();
    const prodIndex = produtos.yData.findIndex((produto) => produto.id == prod.id);

    let texto = value;
    console.log(prod);

    produtos.yData[prodIndex].custo = texto;
    dispatch({
      type: 'GET_MENU_CATEGORIAS',
      payload: { ...produtos },
    });

    validationCategorias(prod);
  };

  const handleChangeProdutos = (e, value) => {
    e.preventDefault();

    setSelectedValue(value);
    setDefaultSelectorProdutos(value);
  };

  const handleChangeQtdProdutoAdulto = (e, p) => {
    e.preventDefault();
    const prodIndex = produtos.yData.findIndex((prod) => prod.id == p.id);

    // console.log(produtos.yData.findIndex((prod) => prod.id == p.id));
    // console.log('p', p);

    // console.log(e.target.value, c, menuCategorias);
    produtos.yData[prodIndex].qtdAdulto = parseInt(e.target.value);

    // // console.log(produtos);
    dispatch({
      type: 'GET_MENU_PRODUTOS',
      payload: { ...produtos },
    });

    validationQtdProduto(p);
  };
  const handleChangeQtdProdutoCrianca = (e, p) => {
    e.preventDefault();
    const prodIndex = produtos.yData.findIndex((prod) => prod.id == p.id);

    // console.log(produtos.yData.findIndex((prod) => prod.id == p.id));
    // console.log('p', p);

    // console.log(e.target.value, c, menuCategorias);
    produtos.yData[prodIndex].qtdCrianca = parseInt(e.target.value);

    // // console.log(produtos);
    dispatch({
      type: 'GET_MENU_PRODUTOS',
      payload: { ...produtos },
    });

    validationQtdProduto(p);
  };

  const handleAdicionar = (e, value) => {
    e.preventDefault();

    produtos.yData.push(selectedValue);

    produtos.dropdown = produtos.dropdown
      .map((rProd) => {
        if (rProd.id != selectedValue.id) {
          return rProd;
        }
      })
      .filter((rProd) => rProd !== undefined);

    dispatch({
      type: 'GET_MENU_PRODUTOS',
      // payload: { yData: produtos.yData, dropdown: produtos.dropdown },
      payload: {
        yData: [...produtos.yData],
        dropdown: produtos.dropdown,
      },
    });

    setDefaultSelectorProdutos({
      id: 0,
      descricao: '',
      extra: 0,
    });
  };

  const handleRemover = (e, prod) => {
    e.preventDefault();

    produtos.yData = produtos.yData
      .map((rProd) => {
        if (rProd.id != prod.id) {
          return rProd;
        }
      })
      .filter((rProd) => rProd !== undefined);

    produtos.dropdown.push(prod);
    produtos.dropdown.sort(function (a, b) {
      if (a.descricao > b.descricao) {
        return 1;
      }
      if (a.descricao < b.descricao) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    dispatch({
      type: 'GET_MENU_PRODUTOS',
      // payload: { yData: produtos.yData, dropdown: produtos.dropdown },
      payload: {
        yData: [...produtos.yData],
        dropdown: produtos.dropdown,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(validationCategorias(), validationQtdProduto());

    if (validationCategorias() && validationQtdProduto()) {
      const ydata = produtos.yData.map(({ id, extra, custo, qtdAdulto, qtdCrianca }) => ({
        produtoId: id,
        extra,
        custo,
        qtdAdulto,
        qtdCrianca,
      }));

      dispatch(AdicionarMenuProdutos(ydata, menuId))
        .then(() => handleClose('produtos', e))
        .then(() => fetchMenus())
        .then(() => handleOpenAlert('produtos'));
    } else {
      // console.log(produtos.yData);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openProdutos}
        onClose={(e) => handleClose('produtos', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openProdutos}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Adicionar Produtos
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode aqui associar os produtos ao menu <b> {menuNome} </b>
                </p>
              </div>

              <br />

              <div className="add-prod-form">
                <div className="add-prod-selector">
                  <Autocomplete
                    id="combo-box-demo"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    name="produto"
                    options={[...produtos.dropdown] || []}
                    includeInputInList
                    getOptionLabel={(option) => `${option.descricao}`}
                    value={defaultSelectorProdutos}
                    onInputChange={(event, value, reason) => {
                      if (reason == 'clear') {
                        setDefaultSelectorProdutos({
                          id: 0,
                          descricao: '',
                          extra: 0,
                        });
                      }
                    }}
                    getOptionSelected={(option, value) => {
                      return value.id === option.id;
                    }}
                    filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                    onChange={(e, value) => handleChangeProdutos(e, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Escolher produto"
                        variant="outlined"
                        placeholder="Escolher produto"
                        // required
                        // {...(errors.encarregado && {
                        //   error: true,
                        //   helperText: errors.encarregado,
                        // })}
                        inputProps={{
                          ...params.inputProps,
                          style: { paddingLeft: '20px' },
                        }}
                      />
                    )}
                  />
                </div>
                <div className="add-prod-btn">
                  <Button
                    className={
                      defaultSelectorProdutos && defaultSelectorProdutos.id !== 0
                        ? classes.btnAdicionar
                        : classes.btnAdicionarDisabled
                    }
                    onClick={(e, value) => handleAdicionar(e, value)}
                    disabled={
                      (defaultSelectorProdutos && defaultSelectorProdutos.id === 0) ||
                      !defaultSelectorProdutos
                    }
                  >
                    +
                  </Button>
                </div>
              </div>

              <br />

              <div className={classes.warning}>
                {validationMessage && (
                  <p>* Tem produtos com quantidade superior á sua categoria.</p>
                )}
              </div>

              <br />

              <div
                style={{
                  maxHeight: 'calc(100% - 26vh)',

                  minHeight: '50%',
                  width: '100%',
                }}
              >
                {produtos &&
                  produtos.yData.map((prod) => (
                    <div key={prod.id}>
                      <div className="menu-list">
                        <div className="menu-prods">
                          <p>{prod.descricao}</p>
                        </div>

                        <div>
                          <CurrencyTextField
                            label="Custo"
                            variant="standard"
                            value={prod.custo || '0'}
                            currencySymbol="€"
                            minimumValue="0"
                            // maximumValue='10'
                            outputFormat="string"
                            decimalCharacter="."
                            digitGroupSeparator=","
                            error={prod.errorCusto !== ''}
                            helperText={prod.errorCusto}
                            style={{ width: '80px', marginRight: '20px' }}
                            //autoFocus
                            //className=classes.textField
                            //readonly
                            //disabled
                            //placeholder="Currency"
                            //preDefined={predefinedOptions.percentageEU2dec}
                            onChange={(e, value) => handleChangeCusto(e, prod, value)}
                            // {...(prod.errorCusto && {
                            //   error: true,
                            //   helperText: prod.errorCusto,
                            // })}
                          />
                        </div>
                        <div>
                          <TextField
                            // id='quantidade'
                            name={prod.descricao}
                            label="Max. Adulto"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">X</InputAdornment>,
                            }}
                            // variant='outlined'
                            // color='primary'
                            value={prod.qtdAdulto || 0}
                            onChange={(e) => handleChangeQtdProdutoAdulto(e, prod)}
                            fullWidth
                            autoComplete="off"
                            className="ass-produto-quantidade"
                            style={{ width: '60px', marginRight: '20px' }}
                            error={prod.qtd > prod.catQtd ? '  ' : ''}
                            helperText={errorsMenu.prodQtd}
                          />
                        </div>
                        <div>
                          <TextField
                            // id='quantidade'
                            name={prod.descricao}
                            label="Max. Criança"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">X</InputAdornment>,
                            }}
                            // variant='outlined'
                            // color='primary'
                            value={prod.qtdCrianca || 0}
                            onChange={(e) => handleChangeQtdProdutoCrianca(e, prod)}
                            fullWidth
                            autoComplete="off"
                            className="ass-produto-quantidade"
                            style={{ width: '60px' }}
                            error={prod.qtd > prod.catQtd ? '  ' : ''}
                            helperText={errorsMenu.prodQtd}
                          />
                        </div>

                        <div className="menu-prods-extra">
                          {prod.extra == 0 ? (
                            <TiStarOutline
                              size="2em"
                              color="#ffea00"
                              onClick={(e) => handleExtra(e, prod)}
                              className="clickable"
                            />
                          ) : (
                            <TiStarFullOutline
                              size="2em"
                              color="#ffea00"
                              onClick={(e) => handleExtra(e, prod)}
                              className="clickable"
                            />
                          )}
                        </div>

                        <div className="menu-prods-remover">
                          {/* <DeleteIcon style={{ height: '200px !important' }} /> */}
                          <IoMdTrash
                            size="2em"
                            onClick={(e) => handleRemover(e, prod)}
                            className="clickable"
                          />
                        </div>
                      </div>{' '}
                    </div>
                  ))}
              </div>

              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('produtos', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalProdutos;
