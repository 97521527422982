import React from 'react';

// PACKAGES
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

// ACTIONS
import AdicionarItemLimpeza from '../../../../../utils/redux/actions/limpeza/Items/adicionarItemLimpezaAction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalAdicionar = ({
  openAdicionar,
  handleClose,
  item,
  setItem,
  validateOnChange = true,
  validationItensLimpeza,
  errorsItensLimpeza = null,
  fetchItensLimpeza,
  handleOpenAlert,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    setItem({
      ...item,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validationItensLimpeza({ [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validationItensLimpeza()) {
      const ydata = [
        {
          descricao: item.descricao,
          categoriasId: 1,
        },
      ];

      dispatch(AdicionarItemLimpeza(ydata))
        .then(() => handleClose('adicionar', e))
        .then(() => fetchItensLimpeza())
        .then(() => handleOpenAlert('add'));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdicionar}
        onClose={(e) => handleClose('adicionar', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdicionar}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Adicionar Item
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode adicionar aqui um item onde irão pertencer as suas{' '}
                  <Link to="/admin/limpeza/categorias" style={{ color: 'black' }}>
                    <b>categorias</b>
                  </Link>{' '}
                  relacionadas.
                </p>
              </div>

              <div>
                <TextField
                  id="Descricao"
                  name="descricao"
                  label="Descrição"
                  // variant='outlined'
                  // color='primary'
                  // value={categoria.descricao}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="nome"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsItensLimpeza.descricao && {
                    error: true,
                    helperText: errorsItensLimpeza.descricao,
                  })}
                />
              </div>

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('add', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
