import Instance from '../../Axios';

// New Actions for the app

const GetLinguagens = () => async (dispatch) => {
  await Instance()
    .get(`/backend/linguas`)
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'GET_LINGUAGENS', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetLinguagens;
