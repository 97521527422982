import Instance from '../../../Axios';

const AdicionarMenuProdutos = (ydata, id) => async (dispatch) => {
  console.log(id, ydata);

  await Instance()
    .post(`/backend/menus/${id}/produtos`, { ydata })
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'POST_MENU_PRODUTOS', payload: data });
      //   show('success', 'Foram associados novos funcionários á obra');
    })
    .catch((err) => {
      // eslint-disable-next-line eqeqeq
      if (err.response.status != 200) {
        // show('warning', 'Preencha todos os campos');
        console.log(err);
      }
    });
};

export default AdicionarMenuProdutos;
