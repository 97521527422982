/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, Fragment } from 'react';

// PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { Button, Snackbar } from '@material-ui/core';
import { ThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
// import { useHistory } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { green } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import MuiAlert from '@material-ui/lab/Alert';
import { addDays, format } from 'date-fns';
// ACTIONS
import GetReserva from '../../../../utils/redux/actions/reservas/getReservaAction';
import DetalhesReserva from './components/info/detalhesReserva';
import EncomendasReserva from './components/peqAlmocos/encomendasReserva';
import ModalAdicionar from './components/peqAlmocos/modalAdicionar';

import GetMenusReserva from '../../../../utils/redux/actions/reservas/reserva/getMenusReservaAction';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '85%',
    marginBottom: '20px',
    margin: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  descricao: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  typo: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    height: '40px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  btnAdicionar: {
    minWidth: '100px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnAdicionarDisabled: {
    minWidth: '100px',
    color: theme.palette.getContrastText(green[100]),
    backgroundColor: green[100],
    '&:hover': {
      backgroundColor: green[100],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[100],
      },
    },
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Reserva = () => {
  const classes = useStyles();
  const history = useHistory();
  const link = history.location.pathname;
  const dispatch = useDispatch();
  const reservaId = link.split('/')[3];
  const [setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [reserva, setReserva] = useState({
    apartamentosId: '',
    numAdultos: '',
    numCriancas: '',
    checkIn: '',
    checkOut: '',
    reservaBookingId: '',
    reservaSiteId: '',
    clienteNome: '',
    clienteEmail: '',
    clienteContacto: '',
    clienteMorada: '',
    clienteCodPostal: '',
    clienteLocalidade: '',
    linguagem: null,
  });
  const [apartamentoSelecionado, setApartamentoSelecionado] = useState({
    descricao: '',
    id: 0,
  });
  const [encomendaMenu, setEncomendaMenu] = useState({
    menusId: 1,
  });

  const [menuSelecionado, setMenuSelecionado] = useState({
    id: 1,
    descricao: '',
  });
  const [linguagemSelecionada, setLinguagemSelecionada] = useState({
    id: 0,
    descricao: '',
    code: '',
  });

  const reservaInfo = useSelector((state) => state.reservas.reserva);

  const resetReserva = () => {
    setReserva({
      apartamentosId: '',
      numAdultos: '',
      numCriancas: '',
      checkIn: '',
      checkOut: '',
      reservaBookingId: '',
      reservaSiteId: '',
      clienteNome: '',
      clienteEmail: '',
      clienteContacto: '',
      clienteMorada: '',
      clienteCodPostal: '',
      clienteLocalidade: '',
      linguagem: null,
    });
  };

  const fetchReserva = () => {
    dispatch(GetReserva(reservaId, history));
  };

  const fetchMenus = () => {
    dispatch(GetMenusReserva(reservaInfo));
  };

  useEffect(() => fetchReserva(), []);
  useEffect(() => fetchMenus(), [reservaInfo]);

  // const menus = useSelector((state) => state.menus.menus);
  const menusReserva = useSelector((state) => state.reservas.menus);
  // console.log(menusReserva);

  // eslint-disable-next-line no-unused-vars
  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();

    if (btn == 'delete') {
      setOpenDelete(true);
    } else {
      setOpenAdicionar(true);
      setEncomendaMenu({
        menusId: 1,
      });
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();

    if (btn == 'add') {
      setOpenAdicionar(false);
    }
  };
  // --------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackAdd, setOpenSnackAdd] = useState(false);
  const [openSnackEdit, setOpenSnackEdit] = useState(false);
  const [openSnackDelete, setOpenSnackDelete] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'edit') {
      setOpenSnackEdit(true);
    } else if (btn == 'delete') {
      setOpenSnackDelete(true);
    } else if (btn == 'add') {
      setOpenSnackAdd(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackEdit(false);
    setOpenSnackDelete(false);
    setOpenSnackAdd(false);
  };

  // ------------------------------------------------------------------------//

  // -------------------------- Validacao Reserva --------------------//
  const [errorsReserva, setErrorsReserva] = useState({});

  const validationReserva = (fieldValues = reserva) => {
    const temp = { ...errorsReserva };
    if ('apartamentosId' in fieldValues)
      temp.apartamentosId = fieldValues.apartamentosId ? '' : 'Campo Obrigatório';

    if ('numAdultos' in fieldValues) {
      if (fieldValues.numAdultos > 6) {
        temp.numAdultos = 'Até 6 Adultos';
      } else if (fieldValues.numAdultos <= 0) {
        temp.numAdultos = 'No mínimo 1 adulto';
      } else {
        temp.numAdultos = fieldValues.numAdultos ? '' : 'Campo Obrigatório';
      }
    }
    if ('numCriancas' in fieldValues) {
      if (fieldValues.numCriancas > 6) {
        temp.numCriancas = 'Até 6 Crianças';
      }
    }

    if ('checkIn' in fieldValues) {
      if (fieldValues.apartamentosId == 0) {
        temp.checkIn = 'Escolha um apartamento';
      } else {
        temp.checkIn = fieldValues.checkIn ? '' : 'Campo Obrigatório';
      }
    }

    if ('reservaBookingId' in fieldValues && 'reservaSiteId' in fieldValues) {
      if (
        fieldValues.reservaBookingId == '' &&
        (isNaN(fieldValues.reservaSiteId) || fieldValues.reservaSiteId <= 0)
      ) {
        temp.reservaBookingId = ' ';
        temp.reservaSiteId = 'Tem  de preencher pelo menos Booking Id ou Site Id';
      } else {
        temp.reservaBookingId = '';
        temp.reservaSiteId = '';
      }
    }

    if ('clienteNome' in fieldValues)
      temp.clienteNome = fieldValues.clienteNome ? '' : 'Campo Obrigatório';

    if ('clienteEmail' in fieldValues)
      // prettier-ignore
      temp.clienteEmail = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.clienteEmail) ? '' : 'Campo Obrigatório';

    if ('clienteContacto' in fieldValues)
      temp.clienteContacto = fieldValues.clienteContacto ? '' : 'Campo Obrigatório';
    // if ('clienteMorada' in fieldValues)
    //   temp.clienteMorada = fieldValues.clienteMorada ? '' : 'Campo Obrigatório';
    // if ('clienteCodPostal' in fieldValues)
    //   temp.clienteCodPostal = fieldValues.clienteCodPostal
    //     ? ''
    //     : 'Campo Obrigatório';
    // if ('clienteLocalidade' in fieldValues)
    //   temp.clienteLocalidade = fieldValues.clienteLocalidade
    //     ? ''
    //     : 'Campo Obrigatório';

    setErrorsReserva({
      ...temp,
    });
    if (fieldValues == reserva) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  const diaSeguinte = format(addDays(new Date(), 1), 'dd-MM-yyyy');

  const disableAdicionar = !!(
    reservaInfo &&
    reservaInfo.encomendas &&
    reservaInfo.encomendas.find(
      (encomenda) => format(new Date(encomenda.dataCriacao), 'dd-MM-yyyy') == diaSeguinte,
    )
  );

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <Snackbar open={openSnackEdit} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success">
          Reserva editada com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar open={openSnackAdd} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success">
          Pequeno Almoço adicionado com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar open={openSnackDelete} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success">
          Pequeno Almoço removido com sucesso!
        </Alert>
      </Snackbar>
      <ModalAdicionar
        openAdicionar={openAdicionar}
        handleClose={handleClose}
        menus={menusReserva}
        // menusReserva={menusReserva}
        handleOpenAlert={handleOpenAlert}
        menuSelecionado={menuSelecionado}
        setMenuSelecionado={setMenuSelecionado}
        reservaId={reservaId}
        fetchReserva={fetchReserva}
        encomendaMenu={encomendaMenu}
        setEncomendaMenu={setEncomendaMenu}
        // encomenda={encomenda}
        // -------------------------------------------
        reserva={reserva}
        setReserva={setReserva}
        reservaInfo={reservaInfo && reservaInfo.reserva}
        validationReserva={validationReserva}
        errorsReserva={errorsReserva}
        // fetchReservas={fetchReservas}
        // fetchApartamentos={fetchReservas}
        resetReserva={resetReserva}
      />
      <div className="topReserva">
        <div className="backBtnPosition">
          <Link to="/app/reservas" className="link-btn">
            <Button variant="outlined" className="back-btn">
              <ChevronLeftIcon />
            </Button>
          </Link>
        </div>
        <div className="reservaTitulo">
          <h2>
            Detalhes da Reserva{' '}
            {/* {reservaInfo && reservaInfo.reserva && reservaInfo.reserva.id} */}
          </h2>
        </div>
      </div>
      <div className="reservaCards">
        <div className="infoReservaCard">
          {/* <div className='float-edit-reserva'>
            <Tooltip
              title={'Adicionar Reserva'}
              style={{ height: '48px', marginRight: '2%', marginTop: '4%' }}
            >
              <IconButton
                className={classes.iconButton}
                // onClick={handleChangeChecked}
              >
                <EditIcon className={classes.searchIcon} checked={checked} />
              </IconButton>
            </Tooltip>
          </div> */}

          <div
            style={{
              textAlign: 'center',
              marginBottom: '30px',
              marginTop: '40px',
            }}
          >
            <h2>Informação</h2>
          </div>

          {reservaInfo && reservaInfo.reserva ? (
            <DetalhesReserva
              reservaInfo={reservaInfo.reserva}
              fetchReserva={fetchReserva}
              reserva={reserva}
              setReserva={setReserva}
              resetReserva={resetReserva}
              validationReserva={validationReserva}
              errorsReserva={errorsReserva}
              setErrorsReserva={setErrorsReserva}
              setApartamentoSelecionado={setApartamentoSelecionado}
              apartamentoSelecionado={apartamentoSelecionado}
              openSnackEdit={openSnackEdit}
              openSnackAdd={openSnackAdd}
              openSnackDelete={openSnackDelete}
              handleCloseAlert={handleCloseAlert}
              handleOpenAlert={handleOpenAlert}
              linguagemSelecionada={linguagemSelecionada}
              setLinguagemSelecionada={setLinguagemSelecionada}
            />
          ) : (
            <div
              style={{
                textAlign: 'center',
                marginBottom: '40px',
                marginTop: '40px',
              }}
            >
              <p>Não existe informação sobre a Reserva.</p>
            </div>
          )}
        </div>
        <div className="detalhesReservaCard">
          <div className="detalhesReservaDrops">
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
              <h2> Pequenos Almoços</h2>
            </div>
            {reservaInfo && reservaInfo.encomendas && reservaInfo.encomendas.length > 0 ? (
              reservaInfo.encomendas.map((encomenda) => (
                <Fragment key={encomenda.id}>
                  <EncomendasReserva
                    encomenda={encomenda}
                    reservaId={reservaId}
                    fetchReserva={fetchReserva}
                    handleOpenAlert={handleOpenAlert}
                  />
                </Fragment>
              ))
            ) : (
              <div style={{ textAlign: 'center', marginBottom: '40px' }}>
                <p>Não existem pequenos Almoços a encomendar.</p>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '25px',
                marginBottom: '30px ',
              }}
            >
              <Button
                onClick={(e) => {
                  handleOpen('add', e);
                }}
                className={!disableAdicionar ? classes.btnAdicionar : classes.btnAdicionarDisabled}
                disabled={disableAdicionar}
              >
                <AddIcon style={{ color: 'white' }} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Reserva;
