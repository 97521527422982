import Instance from '../../../Axios';

const EditarApartamento = (ydata, id, fotoSelected) => async (dispatch) => {
  console.log(fotoSelected);
  const formdata = new FormData();

  formdata.append('ydata', JSON.stringify(ydata));
  formdata.append('files', fotoSelected);

  console.log([...formdata]);

  await Instance()
    .put(`/backend/apartamentos/${id}`, formdata)
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'PUT_APARTAMENTO', payload: data });
      //   show('success', 'Foram associados novos funcionários á obra');
    })
    .catch((err) => {
      // eslint-disable-next-line eqeqeq
      if (err.response.status != 200) {
        // show('warning', 'Preencha todos os campos');
      }
    });
};

export default EditarApartamento;
