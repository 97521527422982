/* eslint-disable eqeqeq */
import React from 'react';
//PACKAGES

import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Admin = () => {
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <div>Hello from Admin!</div>
    </ThemeProvider>
  );
};

export default Admin;
