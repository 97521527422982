import React, { useState, useEffect } from 'react';
//PACKAGES
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../images/logo_valleybeach_black.png';
import { TiUser } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { persistor } from '../utils/store';

//COMPONENTS
import SwipableDrawer from './SwipableDrawer';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const LeftDrawer = ({ children }) => {
  const classes = useStyles();
  // const theme = useTheme();
  const [open] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [item, setItem] = useState({
    selected: null,
  });

  const user = useSelector((state) => state.user.nome);

  const updateSelected = (selectedIndex) => {
    setItem({ selected: selectedIndex });
  };

  const history = useHistory();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const menuLink = history.location.pathname.split('/')[2];
  const subMenuLink = history.location.pathname.split('/')[3];

  useEffect(() => {
    if (menuLink === 'reservas') {
      updateSelected(0);
    } else if (menuLink === 'pequenos-almocos') {
      updateSelected(1);
    }
    //  else if (menuLink === 'fechaduras') {
    //   updateSelected(2);
    // }
    else if (menuLink === 'parceiros') {
      updateSelected(3);
    } else if (menuLink === 'utilizadores') {
      updateSelected(4);
    } else if (menuLink === 'menus') {
      updateSelected(5);
    } else if (menuLink === 'categorias') {
      updateSelected(6);
    } else if (menuLink === 'produtos') {
      updateSelected(7);
    } else if (menuLink === 'apartamentos') {
      updateSelected(8);
    } else if (menuLink === 'unidades') {
      updateSelected(9);
    } else if (menuLink === 'categoriasLimpeza') {
      updateSelected(10);
    } else if (subMenuLink === 'itens') {
      updateSelected(11);
    } else if (subMenuLink === 'tooltips') {
      updateSelected(12);
    } else if (subMenuLink === 'analise-de-vendas') {
      updateSelected(13);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSair = () => {
    // setAnchorEl(null);

    localStorage.removeItem('token');
    localStorage.removeItem('persist:root');
    history.push('/');
    persistor.purge();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        elevation={0}
        style={{
          backgroundColor: 'white',
        }}
      >
        <Toolbar
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            height: '50px',
            color: 'black',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '30%',
              textAlign: 'start',
              alignItems: 'center',
            }}
          >
            <SwipableDrawer
              windowDimensions={windowDimensions}
              updateSelected={updateSelected}
              item={item}
            />
            {windowDimensions.width < 1220 ? (
              <></>
            ) : (
              <>
                <Link
                  to="/app/reservas"
                  style={{
                    color: 'black',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  onClick={() => updateSelected(0)}
                >
                  <div
                  // style={{
                  //   borderBottom: '2px solid black',
                  // }}
                  >
                    <p
                      style={{
                        marginRight: '20px',
                        marginLeft: '15px',
                        borderBottom: item.selected === 0 ? '2px solid black' : null,
                      }}
                    >
                      Reservas
                    </p>
                  </div>
                </Link>
                <Link
                  to="/app/pequenos-almocos"
                  onClick={() => updateSelected(1)}
                  style={{ color: 'black' }}
                >
                  <p
                    style={{
                      marginRight: '20px',
                      marginLeft: '15px',
                      borderBottom: item.selected === 1 ? '2px solid black' : null,
                    }}
                  >
                    Pequenos Almoços
                  </p>
                </Link>
                {/* <Link
                  to='/app/fechaduras'
                  onClick={() => updateSelected(2)}
                  style={{ color: 'black' }}
                >
                  <p
                    style={{
                      borderBottom:
                        item.selected === 2 ? '2px solid black' : null,
                    }}
                  >
                    Fechaduras
                  </p>
                </Link> */}
              </>
            )}
          </div>
          <div
            style={
              windowDimensions.width > 1220
                ? { display: 'flex', width: '30%', justifyContent: 'center' }
                : { display: 'flex' }
            }
          >
            <Link to="/app/reservas">
              <img
                src={Logo}
                alt=""
                style={{
                  height: '40px',
                }}
                onClick={() => updateSelected(0)}
              />
            </Link>
          </div>
          <div
            style={
              windowDimensions.width > 1219.99
                ? {
                    width: '30%',
                    textAlign: 'end',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }
                : {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '30%',
                  }
            }
          >
            {windowDimensions.width > 800 ? (
              <div>
                <p>{user}</p>
              </div>
            ) : null}

            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <TiUser color="#757575" />
              </IconButton>
            </div>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open1}
              onClose={handleClose}
            >
              <div>
                <MenuItem onClick={handleSair}>Sair</MenuItem>
              </div>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <main>
        <div className={classes.drawerHeader} style={{ marginTop: '50px' }} />
        {children}
      </main>
    </div>
  );
};

export default LeftDrawer;
