/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//PACKAGES
import { Button, TextField, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

//ACTIONS
import RecuperarSenha from '../../../../utils/redux/actions/senha/recuperarSenhaAction';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const FormRecuperarSenha = ({ validateOnChange = true, dispatch }) => {
  const [user, setUser] = useState({
    email: '',
  });

  const history = useHistory();

  //   useEffect(() => fetch(), []);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

    if (validateOnChange) validationUser({ [e.target.name]: e.target.value });
  };

  //--------------------------------- validation user--------------------------/
  const [errorsUser, setErrorsUser] = useState({});
  const validationUser = (fieldValues = user) => {
    const temp = { ...errorsUser };

    if ('email' in fieldValues)
      // prettier-ignore
      temp.email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.email) ? '' : 'Campo Obrigatório';

    setErrorsUser({
      ...temp,
    });
    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };
  //--------------------------------------------------------------------------/
  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackGreen, setOpenSnackGreen] = useState(false);
  const [openSnackYellow, setOpenSnackYellow] = useState(false);
  const [openSnackRed, setOpenSnackRed] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'green') {
      setOpenSnackGreen(true);
    } else if (btn == 'yellow') {
      setOpenSnackYellow(true);
    } else if (btn == 'red') {
      setOpenSnackRed(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackGreen(false);
    setOpenSnackYellow(false);
    setOpenSnackRed(false);
  };

  //------------------------------------------------------------------------//

  const [counter, setCounter] = useState({
    time: 5,
    do: false,
    complete: false,
    running: false,
  });

  const resetCounter = () => {
    setCounter({
      time: 5,
      do: false,
      complete: false,
      running: false,
    });
  };

  useEffect(() => {
    if (counter.running == true) {
      let finished = false;
      if (counter.time === 0) {
        finished = true;
        history.push('/');
        return resetCounter();
      }
      counter.time > 0 &&
        setTimeout(
          () =>
            setCounter({
              ...counter,
              time: counter.time - 1,
              complete: finished,
            }),
          1000,
        );
    }
  }, [counter.time, counter.running]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationUser()) {
      const ydata = [
        {
          email: user.email,
        },
      ];

      dispatch(RecuperarSenha(ydata, handleOpenAlert, history, counter, setCounter));
      // .then(() =>
      //   setCounter({
      //     ...counter,
      //     running: true,
      //   })
      // );
    }
  };

  return (
    <>
      <Snackbar open={openSnackGreen} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success">
          Senha recuperada! Verifique o seu email. <br />
          Redirencionando ({counter.time}s)
        </Alert>
      </Snackbar>

      <Snackbar open={openSnackYellow} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="warning">
          Verifique se o mail está correto.
        </Alert>
      </Snackbar>
      <Snackbar open={openSnackRed} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="danger">
          {/* As passwords nao são iguais! */}
        </Alert>
      </Snackbar>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '40px',
          }}
        >
          <form
            className="loginForm"
            noValidate
            autoComplete="off"
            // onSubmit={handleSubmit}
          >
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              className="emailInput"
              value={user.email}
              onChange={handleChange}
              {...(errorsUser.email && {
                error: true,
                helperText: errorsUser.email,
              })}
            />
          </form>
        </div>

        <Button
          variant="contained"
          color="primary"
          className="btn"
          type="submit"
          onClick={handleSubmit}
        >
          Recuperar Senha
        </Button>
      </ThemeProvider>
    </>
  );
};

export default FormRecuperarSenha;
