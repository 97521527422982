/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';

import { CardContent, CardActions, Collapse, Typography, Button, Tooltip } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import { red, blue, yellow } from '@material-ui/core/colors';
import { BiMailSend } from 'react-icons/bi';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { IoClose, IoCheckmarkOutline } from 'react-icons/io5';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import DeleteIcon from '@material-ui/icons/Delete';

import ModalDelete from './modalDelete';
import ModalInfo from './modalInfo';

import moment from 'moment';
import { format } from 'date-fns';

import ReenviarEmailPeqAlmocos from '../../../../../../utils/redux/actions/reservas/reserva/ReenviarEmailPeqAlmoco';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '85%',
    marginBottom: '20px',
    margin: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  descricao: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  typo: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    height: '40px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  btnApagar1: {
    width: '100px',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  btnInfo: {
    width: '100px',
    color: theme.palette.getContrastText(blue[600]),
    backgroundColor: blue[600],
    '&:hover': {
      backgroundColor: blue[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: blue[600],
      },
    },
  },
  btnMenu: {
    width: '100px',
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnInfoDisabled: {
    width: '100px',
    color: theme.palette.getContrastText(blue[200]),
    backgroundColor: blue[200],
  },
}));

const EncomendasReserva = ({ encomenda, reservaId, fetchReserva, handleOpenAlert }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const dispatch = useDispatch();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const data = moment(encomenda.dataCriacao).format('DD-MM-YYYY');
  // console.log(encomenda);
  const horaPreenchidaCliente = moment(encomenda.dataPreenchimento).format('HH:mm');

  const horaEnvioParceiro = moment(encomenda.dataEnvio).format('HH:mm');

  // console.log(encomenda);

  // eslint-disable-next-line no-unused-vars
  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();

    if (btn == 'info') {
      setOpenInfo(true);
    } else if (btn == 'delete') {
      setOpenDelete(true);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();

    if (btn == 'delete') {
      setOpenDelete(false);
    }

    if (btn == 'info') {
      setOpenInfo(false);
    }
  };

  const handleResendEmail = () => {
    // console.log('Teste Btn');
    if (encomenda) {
      dispatch(ReenviarEmailPeqAlmocos(encomenda));
    }
  };

  return (
    <>
      <ModalDelete
        openDelete={openDelete}
        handleClose={handleClose}
        encomenda={encomenda}
        data={data}
        reservaId={reservaId}
        fetchReserva={fetchReserva}
        // utilizadorId={utilizadorId}
        // fetchUtilizadores={fetchUtilizadores}
        // utilizadorNome={utilizadorNome}
        handleOpenAlert={handleOpenAlert}
      />
      <ModalInfo openInfo={openInfo} handleClose={handleClose} encomenda={encomenda} />
      <Card className={classes.root}>
        <CardActions
          disableSpacing
          style={{
            background: encomenda.anulado == 1 ? '#FDE4E2' : null,
            boxShadow: '0 1px 4px rgb(0 0 0 / 0.10)',
          }}
        >
          {data != 'Invalid date' ? (
            <div>
              <h4>{encomenda.menuDescricao}</h4>
              {data}
            </div>
          ) : (
            <p>---</p>
          )}
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {/* <div>
                <h3>{encomenda.menuDescricao}</h3>
              </div> */}
              {/* <div>
                <DeleteIcon
                  style={{
                    marginRight: '2px',
                    color: 'tomato',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    handleOpen('delete', e);
                  }}
                />
              </div> */}
            </div>
            <div className={classes.descricao}>
              <Typography paragraph className={classes.typo}>
                Cliente
              </Typography>

              {encomenda.preenchidoCliente === 0 ? (
                <IoClose size="2em" color="tomato" />
              ) : (
                <div>
                  <IoCheckmarkOutline size="2em" color="lightgreen" />
                  <p style={{ fontSize: '8px' }}>{horaPreenchidaCliente}h</p>
                </div>
              )}
            </div>
            <div className={classes.descricao}>
              <Typography paragraph className={classes.typo}>
                Parceiro
              </Typography>

              {encomenda.preenchidoParceiro === 0 ? (
                <IoClose size="2em" color="tomato" />
              ) : (
                <div>
                  <IoCheckmarkOutline size="2em" color="lightgreen" />
                  <p style={{ fontSize: '8px' }}>
                    {horaEnvioParceiro != 'Invalid date' ? horaEnvioParceiro + 'h' : ''}
                  </p>
                </div>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
                flexWrap: 'wrap',
              }}
            >
              {encomenda && encomenda.produtosAdulto && encomenda.produtosAdulto.length > 0 ? (
                <Button
                  onClick={(e) => handleOpen('info', e)}
                  className={classes.btnInfo}
                  style={{ margin: '5px', height: '35px' }}
                >
                  <AiOutlineInfoCircle style={{ color: 'white' }} size="1.6em" />
                </Button>
              ) : null}

              {encomenda && !encomenda.preenchidoCliente && !encomenda.preenchidoParceiro ? (
                <>
                  {data > format(new Date(), 'dd-MM-yyyy') ? (
                    <>
                      <Button
                        onClick={(e) => {
                          handleOpen('delete', e);
                        }}
                        className={classes.btnApagar1}
                        style={{ margin: '5px' }}
                      >
                        <DeleteIcon style={{ color: 'white' }} />
                      </Button>
                      {/* <span style={{ width: '20px' }}></span> */}

                      <Tooltip
                        title={'Reenviar Email'}
                        disabled={data == format(new Date(), 'dd-MM-yyyy')}
                      >
                        <Button
                          onClick={(e) => {
                            handleResendEmail('delete', e);
                          }}
                          className={classes.btnInfo}
                          style={{ margin: '5px' }}
                        >
                          <BiMailSend style={{ color: 'white' }} size="24" />
                        </Button>
                      </Tooltip>
                    </>
                  ) : null}
                </>
              ) : null}

              {encomenda.preenchidoCliente ? null : (
                <>
                  <a href={`${encomenda.urlManual}`} target="blank">
                    <Button className={classes.btnMenu} style={{ margin: '5px' }}>
                      <RestaurantMenuIcon style={{ color: 'white' }} size="24" />
                    </Button>
                  </a>
                </>
              )}
            </div>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};
export default EncomendasReserva;
