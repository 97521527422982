import { limpezaInitialState } from '../initialState/limpeza';

const categoriasLimpezaReducer = (state = limpezaInitialState, { type, payload }) => {
  switch (type) {
    // CATEGORIAS
    case 'GET_CATEGORIAS_LIMPEZA':
      return {
        ...state,
        categoriasLimpeza: payload,
      };
    case 'POST_CATEGORIA_LIMPEZAS':
      return {
        ...state,
        novaCategoriaLimpeza: payload,
      };
    case 'PUT_CATEGORIA_LIMPEZA':
      return {
        ...state,
        novaCategoriaLimpeza: payload,
      };
    case 'DELETE_CATEGORIA_LIMPEZAS':
      return {
        ...state,
        categoriaEditada: payload,
      };
    case 'GET_CATEGORIA_LIMPEZA':
      return {
        ...state,
        categoriaLimpeza: payload,
      };

    // ITENS
    case 'GET_ITENS_LIMPEZA':
      return {
        ...state,
        itensLimpeza: payload,
      };
    case 'POST_ITEM_LIMPEZAS':
      return {
        ...state,
        novoItemLimpeza: payload,
      };
    case 'PUT_ITEM_LIMPEZA':
      return {
        ...state,
        itemEditado: payload,
      };
    case 'DELETE_ITEM_LIMPEZAS':
      return {
        ...state,
        itemRemovido: payload,
      };
    case 'GET_ITEM_LIMPEZA':
      return {
        ...state,
        itemLimpeza: payload,
      };

    // TOOLTIPS
    case 'GET_TOOLTIPS_LIMPEZA':
      return {
        ...state,
        tooltipsLimpeza: payload,
      };
    case 'POST_TOOLTIP_LIMPEZAS':
      return {
        ...state,
        novaTooltipLimpeza: payload,
      };
    case 'PUT_TOOLTIP_LIMPEZA':
      return {
        ...state,
        tooltipEditada: payload,
      };
    case 'DELETE_TOOLTIP_LIMPEZAS':
      return {
        ...state,
        tooltipRemovida: payload,
      };
    case 'GET_TOOLTIP_LIMPEZA':
      return {
        ...state,
        tooltipLimpeza: payload,
      };

    default:
      return state;
  }
};

export default categoriasLimpezaReducer;
