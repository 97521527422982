/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
// import '../parceiros.css';

//COMPONENTS
import DateRangePicker from './DateRangePicker';

//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

// datePicker
import { pt } from 'date-fns/locale';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
//ACTIONS

import GetApartamentoReservas from '../../../../../../utils/redux/actions/reservas/getApartamentoReservasActions';
import EditarReserva from '../../../../../../utils/redux/actions/reservas/reserva/editarReservaAction';

import GetReserva from '../../../../../../utils/redux/actions/reservas/getReservaAction';

// import EditarApartamento from '../../../../utils/redux/actions/apartamentos/editarApartamentoAction';
// import Spinner from '../../../../Global/Components/Spinner';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '80vh',
    margin: 15,
  },

  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  badge: {
    // fontSize: '20px',
    padding: '12px 8px',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: '0.2s ease',
    border: 'solid 1px #ccc',
    backgroundColor: '#ccc',
    '&:hover': {
      backgroundColor: '#b8b8b8',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#ccc',
      },
    },
  },
}));

const ModalEdit = ({
  openEdit,
  handleClose,
  validateOnChange = true,
  handleOpenAlert,
  reserva,
  setReserva,
  validationReserva,
  errorsReserva,
  reservaInfo,
  setApartamentoSelecionado,
  apartamentoSelecionado,
  // openSnackEdit,
  resetReserva,
  linguagemSelecionada,
  setLinguagemSelecionada,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [selectedDateCheckIn, setSelectedDateCheckIn] = useState();
  const [selectedDateCheckOut, setSelectedDateCheckOut] = useState();

  const apartamentos = useSelector((state) => state.apartamentos.apartamentos);
  const apartamentoReservas = useSelector((state) => state.reservas.apartamentoReservas);
  const linguagens = useSelector((state) => state.global.linguagens);

  const selectApartamento = () => {
    if (apartamentos.length > 0 && reservaInfo.id) {
      const ApartamentoAssociado = apartamentos.find(
        (apart) => apart.id === reserva.apartamentosId,
      );

      // console.log(ApartamentoAssociado);

      setApartamentoSelecionado(
        ApartamentoAssociado || {
          id: 0,
          descricao: '',
        },
      );
    } else {
      setApartamentoSelecionado({
        id: 0,
        descricao: '',
      });
    }
  };
  const selectLinguagem = () => {
    if (linguagens.length > 0 && reservaInfo.id) {
      const LinguagemAssociada = linguagens.find((ling) => ling.code === reserva.linguagem);

      setLinguagemSelecionada(
        LinguagemAssociada || {
          id: 0,
          descricao: '',
          code: '',
        },
      );
    } else {
      setLinguagemSelecionada({
        id: 0,
        descricao: '',
        code: '',
      });
    }
  };

  useEffect(() => selectApartamento(), [reserva]);
  useEffect(() => selectLinguagem(), [reserva]);

  const fetchReserva = () => {
    dispatch(GetReserva(reservaInfo.id));
  };

  // eslint-disable-next-line no-unused-vars
  const handleDateChangeChecks = (date) => {
    // setReserva({
    //   ...reserva,
    //   checkIn: selectedDateCheckIn,
    //   checkOut: selectedDateCheckOut,
    // });
  };
  const handleChange = (e) => {
    e.preventDefault();
    setReserva({
      ...reserva,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validationReserva({ [e.target.name]: e.target.value });
  };
  // eslint-disable-next-line no-unused-vars
  const handleChangeNumbers = (e, value) => {
    e.preventDefault();
    let texto = e.target.value;

    texto = texto.replace(/\D/g, '');

    // console.log(e.target.name, e.target.value, texto);
    // texto = texto.replace(/(\d{4})(?=\d)/g, '');
    setReserva({
      ...reserva,
      [e.target.name]: parseInt(texto),
    });
    if (validateOnChange) validationReserva({ [e.target.name]: texto });
  };

  // const handleChangeCodPostal = (e) => {
  //   e.preventDefault();
  //   let texto = e.target.value;

  //   texto = texto.replace(/\D/g, '');
  //   texto = texto.replace(/(\d{4})(?=\d)/g, '$1-');

  //   setReserva({
  //     ...reserva,
  //     clienteCodPostal: texto,
  //   });

  //   if (validateOnChange) validationReserva({ clienteCodPostal: e.target.value });
  // };
  const handleChangeApartamentosId = (e, value) => {
    e.preventDefault();
    setApartamentoSelecionado(
      value || {
        id: 0,
        descricao: '',
      },
    );
    setReserva({
      ...reserva,
      apartamentosId: (value && value.id) || 0,
      checkIn: '',
      checkOut: '',
    });

    dispatch(GetApartamentoReservas(value && value.id));
    if (validateOnChange) validationReserva({ apartamentosId: value });
  };

  const handleLinguagem = (e, value) => {
    e.preventDefault();

    setReserva({
      ...reserva,
      linguagem: value && value.code,
    });

    if (validateOnChange) validationReserva({ linguagem: value });
  };

  // console.log(reserva);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formatedReservaCheckIn =
      reserva.checkIn && format(new Date(reserva.checkIn), 'yyyy-MM-dd 14:00:00');

    const formatedCheckIn =
      selectedDateCheckIn && format(new Date(selectedDateCheckIn), 'yyyy-MM-dd 14:00:00');

    // console.log(selectedDateCheckIn ? formatedCheckIn : formatedReservaCheckIn);

    const formatedReservaCheckOut =
      reserva.checkOut && format(new Date(reserva.checkOut), 'yyyy-MM-dd 11:00:00');

    const formatedCheckOut =
      selectedDateCheckOut && format(new Date(selectedDateCheckOut), 'yyyy-MM-dd 11:00:00');

    if (validationReserva()) {
      const ydata = [
        {
          apartamentosId: reserva.apartamentosId,
          checkin: selectedDateCheckIn ? formatedCheckIn : formatedReservaCheckIn,
          checkout: selectedDateCheckOut ? formatedCheckOut : formatedReservaCheckOut,
          numPessoasAdultas: reserva.numAdultos,
          numPessoasCriancas: reserva.numCriancas,
          reservaBookingID: reserva.reservaBookingId,
          reservaSiteID: reserva.reservaSiteId,
          clienteNome: reserva.clienteNome,
          clienteMorada: reserva.clienteMorada,
          clienteCodPostal: reserva.clienteCodPostal,
          clienteLocalidade: reserva.clienteLocalidade,
          clienteEmail: reserva.clienteEmail,
          clienteContato: reserva.clienteContacto,
          linguaCode: reserva.linguagem,
        },
      ];

      console.log(ydata);

      dispatch(EditarReserva(ydata, reservaInfo.id))
        .then(() => dispatch({ type: 'CLEAR_DATA_RESERVA' }))
        .then(() => resetReserva())
        .then(() => handleClose('edit', e))
        .then(() => handleOpenAlert('edit'))
        .then(() => fetchReserva());
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        onClose={(e) => handleClose('edit', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <>
            <div className={classes.paper}>
              <div className={classes.scroll}>
                <div className="header-modal">
                  <h2 id="transition-modal-title" className="titulo-modal">
                    Editar Apartamento
                  </h2>
                  <p id="transition-modal-description" className="descricao-modal">
                    Pode editar esta reserva <b> {reservaInfo.id}</b> .
                  </p>
                </div>

                <div className="form-add-apartamento">
                  <div>
                    <Autocomplete
                      id="apartamentosId"
                      name="apartamentosId"
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      options={apartamentos}
                      getOptionLabel={(option) => (option.descricao ? `${option.descricao}` : '')}
                      value={apartamentoSelecionado || ''}
                      getOptionSelected={(option, value) => {
                        return value.id === option.id;
                      }}
                      // eslint-disable-next-line no-unused-vars
                      filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                      onChange={(e, value) => handleChangeApartamentosId(e, value)}
                      onInputChange={(event, value, reason) => {
                        if (reason == 'clear') {
                          setReserva({
                            ...reserva,
                            apartamentosId: 0,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Apartamento"
                          placeholder="Apartamento"
                          // variant='outlined'
                          {...(errorsReserva.apartamentosId && {
                            error: true,
                            helperText: errorsReserva.apartamentosId,
                          })}
                        />
                      )}
                    />
                  </div>

                  <div className="andar-tipo-apar-form">
                    <TextField
                      id="numAdultos"
                      name="numAdultos"
                      label="Nº Adultos"
                      value={reserva.numAdultos || ''}
                      InputProps={{
                        inputProps: { min: '0', max: '6', step: '1' },
                      }}
                      type="number"
                      onChange={(e) => handleChangeNumbers(e)}
                      fullWidth
                      autoComplete="off"
                      className="ass-categoria-quantidade m-r-zero-mobile"
                      style={{ marginBottom: 8, marginRight: '4%', width: '45%' }}
                      {...(errorsReserva.numAdultos && {
                        error: true,
                        helperText: errorsReserva.numAdultos,
                      })}
                    />
                    <TextField
                      id="numCriancas"
                      name="numCriancas"
                      label="Nº Crianças"
                      value={reserva.numCriancas || ''}
                      InputProps={{
                        inputProps: { min: '0', max: '6', step: '1' },
                      }}
                      type="number"
                      onChange={(e) => handleChangeNumbers(e)}
                      fullWidth
                      autoComplete="off"
                      className="ass-categoria-quantidade m-r-zero-mobile"
                      style={{ marginBottom: 8, marginRight: '4%', width: '45%' }}
                      {...(errorsReserva.numCriancas && {
                        error: true,
                        helperText: errorsReserva.numCriancas,
                      })}
                    />

                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <Grid
                        container
                        // justify='center'
                        // style={{ marginBottom: '50px' }}
                      >
                        <DateRangePicker
                          disableToolbar
                          fullWidth
                          // value={reservaInfo.checkin}
                          onChange={handleDateChangeChecks}
                          variant="inline"
                          label="CheckIn - CheckOut"
                          formatString="dd/MM/yyyy"
                          setSelectedDateCheckIn={setSelectedDateCheckIn}
                          setSelectedDateCheckOut={setSelectedDateCheckOut}
                          // margin='normal'
                          reserva={reserva}
                          setReserva={setReserva}
                          disabled={reserva.apartamentosId ? false : true}
                          apartamentoReservas={apartamentoReservas}
                          shouldDisableDate={(date) => {
                            return apartamentoReservas.includes(format(date, 'yyyy-MM-dd'));
                          }}
                          {...(errorsReserva.checkIn && {
                            error: true,
                            helperText: errorsReserva.checkIn,
                          })}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="andar-tipo-apar-form">
                  <TextField
                    id="reservaBookingId"
                    name="reservaBookingId"
                    label="Reserva Booking Id"
                    onChange={handleChange}
                    fullWidth
                    autoComplete="off"
                    value={reserva.reservaBookingId || ''}
                    className="ass-categoria-quantidade m-r-zero-mobile"
                    style={{ marginBottom: 8, marginRight: '4%' }}
                    {...(errorsReserva.reservaBookingId && {
                      error: true,
                      helperText: errorsReserva.reservaBookingId,
                    })}
                  />
                  <TextField
                    id="reservaSiteId"
                    name="reservaSiteId"
                    label="Reserva Site Id"
                    value={reserva.reservaSiteId || ''}
                    onChange={handleChangeNumbers}
                    fullWidth
                    autoComplete="off"
                    className="ass-categoria-quantidade "
                    style={{ marginBottom: 8 }}
                    {...(errorsReserva.reservaSiteId && {
                      error: true,
                      helperText: errorsReserva.reservaSiteId,
                    })}
                  />
                </div>
                <div>
                  <TextField
                    id="clienteNome"
                    name="clienteNome"
                    label="Nome completo"
                    onChange={handleChange}
                    fullWidth
                    autoComplete="off"
                    value={reserva.clienteNome || ''}
                    className="ass-categoria-quantidade"
                    style={{ marginBottom: 8 }}
                    {...(errorsReserva.clienteNome && {
                      error: true,
                      helperText: errorsReserva.clienteNome,
                    })}
                  />
                </div>
                <div className="andar-tipo-apar-form">
                  <TextField
                    id="clienteEmail"
                    name="clienteEmail"
                    label="Email"
                    onChange={handleChange}
                    fullWidth
                    autoComplete="off"
                    value={reserva.clienteEmail || ''}
                    className="ass-categoria-quantidade m-r-zero-mobile"
                    style={{ marginBottom: 8, marginRight: '4%' }}
                    {...(errorsReserva.clienteEmail && {
                      error: true,
                      helperText: errorsReserva.clienteEmail,
                    })}
                  />
                </div>
                <div className="andar-tipo-apar-form">
                  <TextField
                    id="clienteContacto"
                    name="clienteContacto"
                    label="Contacto"
                    value={reserva.clienteContacto || ''}
                    onChange={handleChangeNumbers}
                    fullWidth
                    autoComplete="off"
                    className="ass-categoria-quantidade width-100"
                    style={{ marginBottom: 8, marginRight: '4%', width: '40%' }}
                    {...(errorsReserva.clienteContacto && {
                      error: true,
                      helperText: errorsReserva.clienteContacto,
                    })}
                  />
                  <Autocomplete
                    id="linguagem"
                    name="linguagem"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    className="ass-categoria-quantidade width-100"
                    options={linguagens}
                    getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                    value={linguagemSelecionada || ''}
                    getOptionSelected={(option, value) => {
                      return value.id === option.id;
                    }}
                    // eslint-disable-next-line no-unused-vars
                    filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                    onChange={(e, value) => handleLinguagem(e, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Linguagem"
                        placeholder="Linguagem"
                        // variant='outlined'
                        {...(errorsReserva.limpeza && {
                          error: true,
                          helperText: errorsReserva.limpeza,
                        })}
                      />
                    )}
                  />
                </div>
                {/* <div>
                  <TextField
                    id="clienteMorada"
                    name="clienteMorada"
                    label="Morada"
                    onChange={handleChange}
                    fullWidth
                    autoComplete="off"
                    value={reserva.clienteMorada || ''}
                    className="ass-categoria-quantidade"
                    style={{ marginBottom: 8 }}
                    // {...(errorsReserva.clienteMorada && {
                    //   error: true,
                    //   helperText: errorsReserva.clienteMorada,
                    // })}
                  />
                </div> */}
                {/* <div className="andar-tipo-apar-form">
                  <TextField
                    id="clienteCodPostal"
                    name="clienteCodPostal"
                    label="Código Postal"
                    value={reserva.clienteCodPostal || ''}
                    onChange={handleChangeCodPostal}
                    inputProps={{
                      maxLength: 8,
                    }}
                    fullWidth
                    autoComplete="off"
                    className="ass-categoria-quantidade m-r-zero-mobile"
                    style={{ marginBottom: 8, marginRight: '4%' }}
                    // {...(errorsReserva.clienteCodPostal && {
                    //   error: true,
                    //   helperText: errorsReserva.clienteCodPostal,
                    // })}
                  />
                  <TextField
                    id="clienteLocalidade"
                    name="clienteLocalidade"
                    label="Localidade"
                    onChange={handleChange}
                    fullWidth
                    autoComplete="off"
                    value={reserva.clienteLocalidade || ''}
                    className="ass-categoria-quantidade"
                    style={{ marginBottom: 8 }}
                    // {...(errorsReserva.clienteLocalidade && {
                    //   error: true,
                    //   helperText: errorsReserva.clienteLocalidade,
                    // })}
                  />
                </div> */}

                <br />
                <br />
                <div className={classes.modalBtns}>
                  <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                    Confirmar
                  </Button>

                  <Button className={classes.btnCancelar} onClick={(e) => handleClose('edit', e)}>
                    Cancelar
                  </Button>
                </div>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalEdit;
