/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

//PACKAGES
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//ACTIONS
import DefinirSenha from '../../../../utils/redux/actions/senha/definirSenhaAction';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const FormDefinirSenha = ({ dados, validateOnChange = true, hash, dispatch }) => {
  const [user, setUser] = useState({
    password: '',
    confirmarPassword: '',
  });
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const [values_confirmar, setValues_confirmar] = useState({
    amount: '',
    confirmarpassword: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const history = useHistory();

  //   useEffect(() => fetch(), []);

  const handleChangePassword = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setUser({ ...user, [prop]: event.target.value });

    console.log(event.target.name, event.target.value);

    if (validateOnChange) validationUser({ [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePasswordConfirmar = (prop) => (event) => {
    setValues_confirmar({ ...values_confirmar, [prop]: event.target.value });
    setUser({ ...user, [prop]: event.target.value });

    console.log(event.target.name, event.target.value);

    if (validateOnChange) validationUser({ [event.target.name]: event.target.value });
  };

  const handleClickShowPasswordConfirmar = () => {
    setValues_confirmar({
      ...values_confirmar,
      showPassword: !values_confirmar.showPassword,
    });
  };
  const handleMouseDownPasswordConfirmar = (event) => {
    event.preventDefault();
  };

  //--------------------------------- validation user--------------------------/
  const [errorsUser, setErrorsUser] = useState({});
  const validationUser = (fieldValues = user) => {
    const temp = { ...errorsUser };

    if ('password' in fieldValues) temp.password = fieldValues.password ? '' : 'Campo Obrigatório';
    if ('confirmarPassword' in fieldValues)
      temp.confirmarPassword = fieldValues.confirmarPassword ? '' : 'Campo Obrigatório';

    setErrorsUser({
      ...temp,
    });
    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };
  //--------------------------------------------------------------------------/
  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackGreen, setOpenSnackGreen] = useState(false);
  const [openSnackYellow, setOpenSnackYellow] = useState(false);
  const [openSnackRed, setOpenSnackRed] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'green') {
      setOpenSnackGreen(true);
    } else if (btn == 'yellow') {
      setOpenSnackYellow(true);
    } else if (btn == 'red') {
      setOpenSnackRed(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackGreen(false);
    setOpenSnackYellow(false);
    setOpenSnackRed(false);
  };

  //------------------------------------------------------------------------//

  const handleSubmit = (e) => {
    e.preventDefault();
    // history.push('/');
    if (validationUser()) {
      const ydata = [
        {
          password: user.password,
          confirmarPassword: user.confirmarPassword,
          setup: hash,
        },
      ];

      dispatch(DefinirSenha(ydata, handleOpenAlert, history)).then(() => handleOpenAlert('green'));
    }
  };

  return (
    <>
      <Snackbar open={openSnackGreen} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success">
          Senha alterada com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar open={openSnackYellow} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="warning">
          As passwords nao são iguais!
        </Alert>
      </Snackbar>
      <Snackbar open={openSnackRed} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="danger">
          {/* As passwords nao são iguais! */}
        </Alert>
      </Snackbar>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '40px',
          }}
        >
          <form
            className="loginForm"
            noValidate
            autoComplete="off"
            // onSubmit={handleSubmit}
          >
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              className="emailInput"
              value={dados.email}
              name="email"
              disabled
              //   onChange={handleForm}
              //   {...(errorsUser.email && {
              //     error: true,
              //     helperText: errorsUser.email,
              //   })}
            />
            <FormControl
              // className={clsx(classes.margin, classes.textField)}
              variant="outlined"
              {...(errorsUser.password && {
                error: true,
                // , helperText: errors.password
              })}
            >
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                name="password"
                onChange={handleChangePassword('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={80}
              />
              {errorsUser.password && (
                <FormHelperText style={{ color: 'red' }}>{errorsUser.password}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              style={{ marginTop: '20px' }}
              // className={clsx(classes.margin, classes.textField)}
              variant="outlined"
              {...(errorsUser.confirmarPassword && {
                error: true,
                // , helperText: errors.password
              })}
            >
              <InputLabel htmlFor="outlined-adornment-password">Confirmar Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values_confirmar.showPassword ? 'text' : 'password'}
                value={values_confirmar.password}
                name="confirmarPassword"
                onChange={handleChangePasswordConfirmar('confirmarPassword')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordConfirmar}
                      onMouseDown={handleMouseDownPasswordConfirmar}
                      edge="end"
                    >
                      {values_confirmar.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={165}
              />
              {errorsUser.confirmarPassword && (
                <FormHelperText style={{ color: 'red' }}>
                  {errorsUser.confirmarPassword}
                </FormHelperText>
              )}
            </FormControl>
          </form>
        </div>

        <Button
          variant="contained"
          color="primary"
          className="btn"
          type="submit"
          onClick={handleSubmit}
        >
          Definir Senha
        </Button>
      </ThemeProvider>
    </>
  );
};

export default FormDefinirSenha;
