/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

//PACKAGES
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Snackbar } from '@material-ui/core';
import { ThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import MUIDataTable from 'mui-datatables';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import MuiAlert from '@material-ui/lab/Alert';

//COMPONENTS
import CustomToolbar from './components/customToolbar';
// import ModalEdit from './components/modalEdit';
// import ModalDelete from './components/modalDelete';
import ModalInfo from './components/modalInfo';
import ModalEnviar from './components/modalEnviar';

//ACTIONS
import GetPeqAlmocos from '../../../utils/redux/actions/peqAlmocos/peqAlmocosAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnInfo: {
    color: theme.palette.getContrastText(blue[600]),
    backgroundColor: blue[600],
    '&:hover': {
      backgroundColor: blue[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: blue[600],
      },
    },
  },
}));

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const HistoricoReservas = () => {
  const classes = useStyles();
  const [responsive] = useState('vertical');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openInfo, setOpenInfo] = useState(false);
  const [openEnviar, setOpenEnviar] = useState(false);
  const [peqAlmoco, setPeqAlmoco] = useState({});

  const dispatch = useDispatch();

  const fetchPeqAlmocos = (id, e) => {
    dispatch(GetPeqAlmocos());
  };

  useEffect(() => fetchPeqAlmocos(), []);

  const peqAlmocos = useSelector((state) => state.peqAlmocos.peqAlmocos);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();

    if (btn == 'info') {
      console.log(tableMeta);

      const formatedDataCricao = format(new Date(tableMeta && tableMeta.rowData[3]), 'dd-MM-yyyy');
      setPeqAlmoco({
        apartamentoNome: tableMeta.rowData[1],
        dataCriacao: formatedDataCricao,
        clienteNome: tableMeta.rowData[5],
        menuDescricao: tableMeta.rowData[2],
        parceirosNome: tableMeta.rowData[7],
        parceirosEmail: tableMeta.rowData[8],
        produtosAdulto: tableMeta.rowData[9],
        produtosCrianca: tableMeta.rowData[10],
      });
      setOpenInfo(true);
    } else if (btn == 'enviar') {
      setOpenEnviar(true);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();

    if (btn == 'info') {
      setOpenInfo(false);
      // setPeqAlmoco({});
      // setErrorsApartamento({});
      // dispatch({ type: 'CLEAR_DATA_APARTAMENTOS' });
      // setFotoSelected(null);
    } else if (btn == 'enviar') {
      setOpenEnviar(false);
    }
  };

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        display: false,
      },
    },
    {
      name: 'apartamentosNome',
      label: 'Apartamento',
    },
    {
      name: 'menuDescricao',
      label: 'Menu',
    },

    {
      name: 'dataCriacao',
      label: 'Data',
      options: {
        filter: true,
        sort: false,
        empty: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          const formatedCheckIn = format(new Date(tableMeta.rowData[3]), 'dd-MM-yyyy');

          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <p>{formatedCheckIn}</p>
            </div>
          );
        },
      },
    },

    {
      name: 'reservasID',
      label: 'Reserva Id',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,

        customBodyRender: (value, tableMeta, updateValue) => {
          let r = peqAlmocos.filter((pA) => pA.id === tableMeta.rowData[0]);

          r = r.length > 0 ? r[0] : r;

          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <p key={r.id}>{r.reservaBookingID != null ? r.reservaBookingID : r.reservaSiteID}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'clienteNome',
      label: 'Nome',
    },
    {
      name: 'menuDescricao',
      label: 'Descricao Menu',
      options: {
        display: false,
      },
    },
    {
      name: 'parceirosNome',
      label: 'Parceiro nome',
      options: {
        display: false,
      },
    },
    {
      name: 'parceirosEmail',
      label: 'Parceiro email',
      options: {
        display: false,
      },
    },
    {
      name: 'produtosAdulto',
      label: 'Produtos Adulto',
      options: {
        display: false,
      },
    },
    {
      name: 'produtosCrianca',
      label: 'Produtos Criança',
      options: {
        display: false,
      },
    },
    {
      name: ' ',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="table-btns-categorias">
              <Button
                onClick={(e) => handleOpen('info', e, value, tableMeta)}
                className={classes.btnInfo}
                style={{ marginRight: '20px' }}
              >
                <AiOutlineInfoCircle style={{ color: 'white' }} size="1.6em" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    customToolbar: () => {
      return <CustomToolbar handleOpen={handleOpen} peqAlmocos={peqAlmocos} />;
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackEnviar, setOpenSnackEnviar] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'enviar') {
      setOpenSnackEnviar(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackEnviar(false);
  };

  //------------------------------------------------------------------------//

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <>
        <Snackbar open={openSnackEnviar} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Pedidos de pequeno almoço enviados com sucesso!
          </Alert>
        </Snackbar>
        <ModalInfo openInfo={openInfo} handleClose={handleClose} peqAlmoco={peqAlmoco} />
        <ModalEnviar
          openEnviar={openEnviar}
          handleClose={handleClose}
          peqAlmocos={peqAlmocos}
          fetchPeqAlmocos={fetchPeqAlmocos}
          handleOpenAlert={handleOpenAlert}
        />
        <div className="topReserva" style={{ marginBottom: '40px' }}>
          <div className="reservaTitulo">
            <h2>
              {/* Reserva */}
              {/* {reservaInfo && reservaInfo.reserva && reservaInfo.reserva.id} */}
            </h2>
          </div>
        </div>

        <MUIDataTable
          title="Pequenos Almoços"
          description="Nada para mostrar"
          data={peqAlmocos}
          columns={columns}
          options={options}
        />

        {/* </MuiThemeProvider> */}
      </>
    </ThemeProvider>
  );
};

export default HistoricoReservas;
