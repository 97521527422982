import { menusInitialState } from '../initialState/menus';

const menusReducer = (state = menusInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_MENUS':
      return {
        ...state,
        menus: payload,
      };
    case 'GET_MENU':
      return {
        ...state,
        menu: payload,
      };
    case 'GET_MENU_CATEGORIAS':
      return {
        ...state,
        menuCategorias: payload,
      };
    case 'POST_MENU':
      return {
        ...state,
        novoMenu: payload,
      };
    case 'PUT_MENU':
      return {
        ...state,
        menuEditado: payload,
      };
    case 'DELETE_MENU':
      return {
        ...state,
        menuRemovido: payload,
      };

    case 'CLEAR_DATA_MENU':
      return {
        ...state,
        menu: [],
      };

    case 'POST_MENU_CATEGORIAS':
      return {
        ...state,
        menuCategoriaAdicionada: [],
      };

    case 'GET_MENU_PRODUTOS':
      return {
        ...state,
        menuProdutos: payload,
      };
    case 'POST_MENU_PRODUTOS':
      return {
        ...state,
        menuProdutosAdicionados: payload,
      };
    default:
      return state;
  }
};

export default menusReducer;
