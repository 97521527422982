import React from 'react';
// ACTIONS

import AdicionarMenu from '../../../../utils/redux/actions/menus/adicionarMenuAction';

// PACKAGES

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalAdicionar = ({
  openAdicionar,
  handleClose,
  menu,
  setMenu,
  validateOnChange = true,
  validationMenu,
  errorsMenu = null,
  fetchMenus,
  handleOpenAlert,
  parceiros,
  // LINGUAGENS
  linguagens,
  lingState,
  setLingState,
  empty,
  setEmpty,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleChange = (e, value) => {
    // e.preventDefault();

    const lingIndex = lingState.findIndex((l) => l.linguasId === value.id);
    lingState[lingIndex].descricao = e.target.value;
    setLingState(lingState);

    const allEmpty = lingState.map((l) => l.descricao.length > 0).find((a) => a == true);

    if (allEmpty != true) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
  };

  const handleChangeUnidadesId = (e, value) => {
    e.preventDefault();

    setMenu({
      ...menu,
      parceirosId: value && value.id,
    });
    if (validateOnChange) validationMenu({ parceirosId: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let prodDescricao = lingState.filter((l) => l.linguasId > 0);
    // prodDescricao = [{ descricao: prodDescricao }];

    if (empty == true) {
      if (validationMenu()) {
        const ydata = [
          {
            descricao: prodDescricao,
            parceirosId: menu.parceirosId,
          },
        ];

        dispatch(AdicionarMenu(ydata))
          .then(() => handleClose('adicionar', e))
          .then(() => fetchMenus())
          .then(() => handleOpenAlert('add'));
      }
    } else {
      setEmpty(false);
      validationMenu();
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdicionar}
        onClose={(e) => handleClose('adicionar', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdicionar}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Adicionar Menu
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode adicionar aqui um menu que estarão associados aos{' '}
                  <Link to="/admin/parceiros" style={{ color: 'black' }}>
                    <b>parceiros</b>
                  </Link>{' '}
                  definidos.
                </p>
              </div>
              <div>
                {linguagens &&
                  linguagens.length > 0 &&
                  linguagens.map((menu) => (
                    <div key={menu.id}>
                      {menu.id > 0 && (
                        <TextField
                          // id={ling.id}
                          name="descricao"
                          label={menu.descricao}
                          onChange={(e) => handleChange(e, menu)}
                          fullWidth
                          autoComplete="off"
                          className="nome"
                          {...(empty == false && {
                            error: true,
                          })}
                        />
                      )}
                    </div>
                  ))}
              </div>
              <div>
                <Autocomplete
                  id="parceirosId"
                  name="parceirosId"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={parceiros}
                  getOptionLabel={(option) => `${option.nome}` || ''}
                  // eslint-disable-next-line no-unused-vars
                  filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                  getOptionSelected={(option, value) => {
                    if (!value.id) return true;

                    return value.id === option.id;
                  }}
                  // style={{ width: '100%' }}
                  onChange={(e, value) => handleChangeUnidadesId(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parceiro"
                      placeholder="Parceiro"
                      // variant='outlined'
                      {...(errorsMenu.parceirosId && {
                        error: true,
                        helperText: errorsMenu.parceirosId,
                      })}
                    />
                  )}
                />
              </div>

              {empty == false && (
                <div style={{ color: '#f44336', marginTop: '15px', fontSize: '12px' }}>
                  {' '}
                  *Preencher pelo menos um campo
                </div>
              )}

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('add', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
