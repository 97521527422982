/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

//COMPONENTS

// PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

// ACTIONS
import EnviarPeqAlmocos from '../../../../utils/redux/actions/peqAlmocos/enviarPeqAlmocosAction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnEnviar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
    height: '50px',
  },
}));

const ModalEnviar = ({ openEnviar, handleClose, peqAlmocos, fetchPeqAlmocos, handleOpenAlert }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();

    const yData = peqAlmocos;

    dispatch(EnviarPeqAlmocos(yData))
      .then(() => fetchPeqAlmocos())
      .then(() => handleClose('enviar', e))
      .then(() => handleOpenAlert('enviar'));
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEnviar}
        onClose={(e) => handleClose('enviar', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEnviar}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Enviar Pedidos
                </h2>
                {/* <p
                  id='transition-modal-description'
                  className='descricao-modal'
                >
                  Pode ver a reserva <b>{reserva.id}</b>, expirada aquando ao
                  checkOut de um cliente
                </p> */}
              </div>

              <div className="form-add-apartamento">
                <div className="infoReservaP" style={{ marginTop: '0px', marginBottom: '20px' }}>
                  Tem a certeza que quer enviar os pedidos de pequenos almoços aos parceiros?
                </div>
              </div>

              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnEnviar} onClick={handleSubmit}>
                  Confirmar
                </Button>
                <Button className={classes.btnCancelar} onClick={(e) => handleClose('enviar', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalEnviar;
