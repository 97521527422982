/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import '../parceiros.css';

//PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField, Badge } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

//ACTIONS

import EditarApartamento from '../../../../utils/redux/actions/apartamentos/editarApartamentoAction';
// import Spinner from '../../../../Global/Components/Spinner';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '80vh',
    margin: 15,
  },
  scroll: {},
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  badge: {
    // fontSize: '20px',
    padding: '12px 8px',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: '0.2s ease',
    border: 'solid 1px #ccc',
    backgroundColor: '#ccc',
    '&:hover': {
      backgroundColor: '#b8b8b8',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#ccc',
      },
    },
  },
}));
const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#f44336' },
  },
});

const ModalEdit = ({
  openEdit,
  handleClose,
  apartamento,
  setApartamento,
  validateOnChange = true,
  validationApartamento,
  errorsApartamento = null,
  fetchApartamentos,
  apartamentoId,
  handleOpenAlert,
  apartamentoNome,
  apartamentoInfo,
  unidadeSelecionada,
  setUnidadeSelecionada,
  unidades,
  fotoSelected,
  setFotoSelected,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const selectUnidade = () => {
    if (unidades.length > 0 && apartamentoId) {
      const UnidadeAssociada = unidades.find((uni) => uni.id === apartamento.unidadesId);

      setUnidadeSelecionada(
        UnidadeAssociada || {
          id: 0,
          descricao: '',
        },
      );
    } else {
      setUnidadeSelecionada({
        id: 0,
        descricao: '',
      });
    }
  };

  useEffect(() => selectUnidade(), [apartamento, apartamentoInfo]);

  const handleChange = (e) => {
    e.preventDefault();

    setApartamento({
      ...apartamento,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validationApartamento({ [e.target.name]: e.target.value });
  };

  const handleChangeNumbers = (e) => {
    e.preventDefault();
    let texto = e.target.value;

    texto = texto.replace(/\D/g, '');
    // texto = texto.replace(/(\d{4})(?=\d)/g, '');
    setApartamento({
      ...apartamento,
      [e.target.name]: texto,
    });
    if (validateOnChange) validationApartamento({ [e.target.name]: e.target.value });
  };

  const handleChangeUnidadesId = (e, value) => {
    e.preventDefault();

    setUnidadeSelecionada(
      value || {
        id: 0,
        descricao: '',
      },
    );

    setApartamento({
      ...apartamento,
      unidadesId: (value && value.id) || 0,
    });
    if (validateOnChange) validationApartamento({ unidadesId: value });
  };

  useEffect(() => {
    apartamentoInfo &&
      setApartamento({
        unidadesId: apartamentoInfo.unidades_id,
        wpApartamentoId: apartamentoInfo.wp_apartamento_id,
        descricao: apartamentoInfo.descricao,
        tipologia: apartamentoInfo.tipologia,
        numapartamento: apartamentoInfo.numapartamento,
        numandar: apartamentoInfo.numandar,
      });
  }, [apartamentoInfo, setApartamento]);

  const handleChangeFotoFile = (e) => {
    // e.preventDefault();
    // console.log(e.target.files[0], e.target.value);
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      var reader = new FileReader();
      // eslint-disable-next-line no-unused-vars
      var url = reader.readAsDataURL(file);

      reader.onloadend = () => {
        setFotoSelected({
          imgSrc: [reader.result],
          file: file,
        });
      };
    }
    e.target.value = '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validationApartamento()) {
      const ydata = [
        {
          unidadesId: apartamento.unidadesId,
          wpApartamentoId: apartamento.wpApartamentoId,
          descricao: apartamento.descricao,
          tipologia: apartamento.tipologia,
          numapartamento: apartamento.numapartamento,
          numandar: parseInt(apartamento.numandar),
        },
      ];

      if (fotoSelected && fotoSelected.file.size > 1000000) {
        console.log('não enviou');
      } else {
        dispatch(EditarApartamento(ydata, apartamentoId, fotoSelected && fotoSelected.file))
          .then(() => handleClose('edit', e))
          .then(() => fetchApartamentos())
          .then(() => handleOpenAlert('edit'));
      }
    }
  };

  const CHARACTER_LIMIT = 50;

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        onClose={(e) => handleClose('edit', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <>
            <div className={classes.paper}>
              <div className={classes.scroll}>
                <div className="header-modal">
                  <h2 id="transition-modal-title" className="titulo-modal">
                    Editar Apartamento
                  </h2>
                  <p id="transition-modal-description" className="descricao-modal">
                    Pode editar este apartamento <b> {apartamentoNome}</b>, que terá a devida
                    unidade associada.
                  </p>
                </div>

                <div className="form-add-apartamento">
                  <div className="andar-tipo-apar-form">
                    <Autocomplete
                      fullWidth
                      id="unidadesId"
                      name="unidadesId"
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      className="unidadesId-form"
                      options={unidades}
                      getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                      // eslint-disable-next-line no-unused-vars
                      filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                      value={unidadeSelecionada || ''}
                      onInputChange={(event, value, reason) => {
                        if (reason == 'clear') {
                          setApartamento({
                            ...apartamento,
                            unidadesId: 0,
                          });
                        }
                      }}
                      getOptionSelected={(option, value) => {
                        return value.id === option.id;
                      }}
                      style={{ marginRight: '4%' }}
                      onChange={(e, value) => handleChangeUnidadesId(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Unidade"
                          placeholder="Unidade"
                          // variant='outlined'
                          {...(errorsApartamento.unidadesId && {
                            error: true,
                            helperText: errorsApartamento.unidadesId,
                          })}
                        />
                      )}
                    />
                    <TextField
                      id="wpApartamentoId"
                      name="wpApartamentoId"
                      label="Site Apart. Id"
                      // variant='outlined'
                      // color='primary'
                      value={apartamento.wpApartamentoId || ''}
                      onChange={handleChangeNumbers}
                      fullWidth
                      autoComplete="off"
                      className="wpApartamentoId"
                      style={{ width: '40%' }}
                      {...(errorsApartamento.wpApartamentoId && {
                        error: true,
                        helperText: errorsApartamento.wpApartamentoId,
                      })}
                    />
                  </div>

                  <div>
                    <TextField
                      id="Descricao"
                      name="descricao"
                      label="Descrição"
                      // variant='outlined'
                      // color='primary'
                      value={apartamento.descricao || ''}
                      multiline
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className="descricao"
                      // style={{ marginBottom: 8, marginRight: '4%' }}
                      helperText={
                        (apartamento &&
                          apartamento.descricao &&
                          `${Object.keys(apartamento.descricao).length}/${CHARACTER_LIMIT}`) ||
                        ''
                      }
                      {...(errorsApartamento.descricao && {
                        error: true,
                        helperText: errorsApartamento.descricao,
                      })}
                    />
                  </div>
                  <div className="andar-tipo-apar-form">
                    <TextField
                      id="numapartamento"
                      name="numapartamento"
                      label="Nº Apartamento"
                      // variant='outlined'
                      // color='primary'
                      value={apartamento.numapartamento || ''}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className="apartamento-form"
                      style={{ marginRight: '4%' }}
                      {...(errorsApartamento.numapartamento && {
                        error: true,
                        helperText: errorsApartamento.numapartamento,
                      })}
                    />
                    <TextField
                      id="numandar"
                      name="numandar"
                      label="Nº Andar"
                      // variant='outlined'
                      // color='primary'
                      value={apartamento.numandar || ''}
                      onChange={handleChangeNumbers}
                      fullWidth
                      autoComplete="off"
                      className="andar-form"
                      style={{ marginRight: '4%' }}
                      {...(errorsApartamento.numandar && {
                        error: true,
                        helperText: errorsApartamento.numandar,
                      })}
                    />
                    <TextField
                      id="tipologia"
                      name="tipologia"
                      label="Tipologia"
                      // variant='outlined'
                      // color='primary'
                      value={apartamento.tipologia || ''}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="off"
                      className="tipologia-form"
                      // style={{ marginBottom: 8, marginRight: '4%' }}
                      {...(errorsApartamento.tipologia && {
                        error: true,
                        helperText: errorsApartamento.tipologia,
                      })}
                    />
                  </div>

                  <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <label htmlFor="file-upload" className="custom-file-upload">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AddAPhotoIcon style={{ width: '20px' }} />
                        <p style={{ marginLeft: '10px' }}> Carregar fotografia</p>
                      </div>
                    </label>
                    <input
                      type="file"
                      name="foto-apart"
                      id="file-upload"
                      onChange={(e) => handleChangeFotoFile(e)}
                    />
                  </div>
                  {fotoSelected ? (
                    <>
                      <ThemeProvider theme={defaultMaterialTheme}>
                        <Badge
                          badgeContent="X"
                          color="primary"
                          // className={classes.badge}
                          classes={{
                            badge: classes.badge,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setFotoSelected(null);
                          }}
                        />
                      </ThemeProvider>
                      <>
                        <img
                          src={fotoSelected && fotoSelected.imgSrc}
                          alt=""
                          style={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: '5px',
                          }}
                        />
                        {fotoSelected && fotoSelected.file.size > 1000000 ? (
                          <p className="errorMessage">
                            A imagem nao pode conter mais de <b>1Mb</b>.
                          </p>
                        ) : null}
                      </>
                    </>
                  ) : (
                    <>
                      {apartamentoInfo.foto ? (
                        <img
                          src={`${apartamentoInfo && apartamentoInfo.foto}?${new Date().getTime()}`}
                          alt="Foto do apartamento"
                          style={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: '5px',
                          }}
                        />
                      ) : (
                        <div className="spinner-position">{/* <Spinner /> */}</div>
                      )}
                    </>
                  )}
                </div>
                <br />
                <br />
                <div className={classes.modalBtns}>
                  <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                    Confirmar
                  </Button>

                  <Button className={classes.btnCancelar} onClick={(e) => handleClose('edit', e)}>
                    Cancelar
                  </Button>
                </div>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalEdit;
