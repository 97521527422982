import { categoriasInitialState } from '../initialState/categorias';

const categoriasReducer = (state = categoriasInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_CATEGORIAS':
      return {
        ...state,
        categorias: payload,
      };
    case 'GET_CATEGORIA':
      return {
        ...state,
        categoria: payload,
      };
    case 'POST_CATEGORIA':
      return {
        ...state,
        novaCategoria: payload,
      };
    case 'PUT_CATEGORIA':
      return {
        ...state,
        categoriaEditada: payload,
      };
    case 'DELETE_CATEGORIA':
      return {
        ...state,
        categoriaRemovida: payload,
      };
    case 'CLEAR_DATA_CATEGORIA':
      return {
        ...state,
        categoria: [],
      };

    default:
      return state;
  }
};

export default categoriasReducer;
