export const reservasInitialState = {
  reservas: [],
  reserva: [],
  menus: [],
  linguagens: [],

  apartamentoReservas: [],
  novaReserva: [],
  reservaRemovida: [],

  novaEncomenda: [],
  encomendaRemovida: [],
  reenviarEmail: {},

  historico: [],
};
