import React from 'react';
// ACTIONS
import AdicionarParceiro from '../../../../utils/redux/actions/parceiros/adicionarParceirosAction';
// PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalAdicionar = ({
  openAdicionar,
  handleClose,
  parceiro,
  setParceiro,
  validateOnChange = true,
  validationParceiro,
  errorsParceiro = null,
  fetchParceiros,
  handleOpenAlert,
  tipoParceiro,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    setParceiro({
      ...parceiro,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validationParceiro({ [e.target.name]: e.target.value });
  };

  const handleNumbers = (e) => {
    e.preventDefault();

    let value = e.target.value.toString('000000000');
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      setParceiro({ ...parceiro, [e.target.name]: value });
    }

    if (validateOnChange) validationParceiro({ [e.target.name]: value });
  };
  const handleCodPostal = (e) => {
    e.preventDefault();
    let texto = e.target.value;
    if (e.target.name === 'codPostal') {
      texto = texto.replace(/(\d{4})(?=\d)/g, '$1-');
    }
    setParceiro({
      ...parceiro,
      [e.target.name]: texto,
    });

    if (validateOnChange) validationParceiro({ [e.target.name]: e.target.value });
  };

  const handleChangeTipoParceiroId = (e, value) => {
    e.preventDefault();

    setParceiro({
      ...parceiro,
      tipoParceiroId: value && value.id,
    });
    if (validateOnChange) validationParceiro({ tipoParceiroId: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        nome: parceiro.nome,
        tipoParceiroId: parceiro.tipoParceiroId,
        morada: parceiro.morada,
        codPostal: parceiro.codPostal,
        localidade: parceiro.localidade,
        telefone: parceiro.telefone,
        email: parceiro.email,
      },
    ];
    if (validationParceiro()) {
      dispatch(AdicionarParceiro(ydata))
        .then(() => handleClose('adicionar', e))
        .then(() => fetchParceiros())
        .then(() => handleOpenAlert('add'));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdicionar}
        onClose={(e) => handleClose('adicionar', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdicionar}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Adicionar Parceiro
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode adicionar aqui um parceiro que irá confecionar e distribuir as refeições para
                  as unidades.
                </p>
              </div>

              <div className="form-add-apartamento">
                <div className="andar-tipo-apar-form">
                  <TextField
                    id="Nome"
                    name="nome"
                    label="Nome"
                    // variant='outlined'
                    // color='primary'
                    // value={apartamento.wpApartamentoId || ''}
                    onChange={handleChange}
                    fullWidth
                    autoComplete="off"
                    // className='wpApartamentoId'
                    style={{ width: '100%' }}
                    {...(errorsParceiro.nome && {
                      error: true,
                      helperText: errorsParceiro.nome,
                    })}
                  />
                  <Autocomplete
                    fullWidth
                    id="tipoParceiroId"
                    name="tipoParceiroId"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    className="unidadesId-form"
                    options={tipoParceiro}
                    getOptionLabel={(option) => `${option.descricao}` || ''}
                    // value={enc}
                    getOptionSelected={(option, value) => {
                      if (!value.id) return true;

                      return value.id === option.id;
                    }}
                    filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                    style={{ marginLeft: '4%' }}
                    onChange={(e, value) => handleChangeTipoParceiroId(e, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo Parceiro"
                        placeholder="Tipo Parceiro"
                        // variant='outlined'
                        {...(errorsParceiro.tipoParceiroId && {
                          error: true,
                          helperText: errorsParceiro.tipoParceiroId,
                        })}
                      />
                    )}
                  />
                </div>
              </div>

              <div>
                <TextField
                  id="Email"
                  name="email"
                  label="Email"
                  // variant='outlined'
                  // color='primary'
                  value={parceiro.email}
                  onChange={handleChange}
                  fullWidth
                  type="email"
                  autoComplete="off"
                  style={{ marginBottom: 8 }}
                  {...(errorsParceiro.email && {
                    error: true,
                    helperText: errorsParceiro.email,
                  })}
                />
              </div>
              <div className="andar-tipo-apar-form">
                <TextField
                  id="telefone"
                  name="telefone"
                  label="Telefone"
                  // variant='outlined'
                  // inputProps={{
                  //   pattern: '[0-9]*',
                  // }}
                  // type='number'
                  // color='primary'
                  inputProps={{ maxLength: 9 }}
                  value={parceiro.telefone}
                  onChange={handleNumbers}
                  fullWidth
                  autoComplete="off"
                  className="ass-categoria-quantidade"
                  style={{ marginBottom: 8 }}
                  {...(errorsParceiro.telefone && {
                    error: true,
                    helperText: errorsParceiro.telefone,
                  })}
                />
                <TextField
                  id="codPostal"
                  name="codPostal"
                  label="Código Postal"
                  // variant='outlined'
                  // color='primary'
                  value={parceiro.codPostal}
                  onChange={handleCodPostal}
                  fullWidth
                  autoComplete="off"
                  inputProps={{ maxLength: 8 }}
                  className="ass-categoria-quantidade m-r-zero-mobile"
                  style={{ marginBottom: 8, marginLeft: '4%' }}
                  {...(errorsParceiro.codPostal && {
                    error: true,
                    helperText: errorsParceiro.codPostal,
                  })}
                />
              </div>
              <div>
                <TextField
                  id="Morada"
                  name="morada"
                  label="Morada"
                  // variant='outlined'
                  // color='primary'
                  value={parceiro.morada}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  style={{ marginBottom: 8 }}
                  {...(errorsParceiro.morada && {
                    error: true,
                    helperText: errorsParceiro.morada,
                  })}
                />
              </div>
              <div>
                <TextField
                  id="localidade"
                  name="localidade"
                  label="Localidade"
                  // variant='outlined'
                  // color='primary'
                  value={parceiro.localidade}
                  // className='localidade'
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  // style={{ marginBottom: 8 }}
                  {...(errorsParceiro.localidade && {
                    error: true,
                    helperText: errorsParceiro.localidade,
                  })}
                />
              </div>

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('add', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
