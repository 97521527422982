import axios from 'axios';
import BASE_URL from '../../../BASE_URL';

const DefinirSenha = (ydata, handleOpenAlert, history) => async (dispatch) => {
  console.log(ydata);

  if (ydata[0].password === ydata[0].confirmarPassword) {
    await axios
      .post(`${BASE_URL}/backend/signup`, { ydata })
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: 'DEFINIR_SENHA', payload: data });
      })
      .then(() => dispatch({ type: 'CLEAR_DADOS' }))
      .then(() => history.push('/'))

      .catch(({ err }) => console.log(err));
  } else {
    handleOpenAlert('yellow');
    console.log('As password nao sao iguais!');
  }
};

export default DefinirSenha;
