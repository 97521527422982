/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import './login.css';
//PACKAGES
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
//IMAGES
import Logo from '../../../images/logo_valleybeach_black.png';

//COMPONENTS
import SignIn from '../../../utils/redux/actions/authAction';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//       width: '25ch',
//     },
//   },
// }));
const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Login = ({ validateOnChange = true }) => {
  //   const classes = useStyles();
  const [errorsUser, setErrorsUser] = useState({});
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  //--------------------------------- validation ------------------------------/
  const validationUser = (fieldValues = user) => {
    const temp = { ...errorsUser };

    if ('email' in fieldValues) {
      // prettier-ignore
      temp.email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.email) ? '' : 'Coloque um email válido';
    }

    if ('password' in fieldValues) temp.password = fieldValues.password ? '' : 'Campo Obrigatório';

    setErrorsUser({
      ...temp,
    });
    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };
  //--------------------------------------------------------------------------/

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackGreen, setOpenSnackGreen] = useState(false);
  const [openSnackYellow, setOpenSnackYellow] = useState(false);
  const [openSnackRed, setOpenSnackRed] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'green') {
      setOpenSnackGreen(true);
    } else if (btn == 'yellow') {
      setOpenSnackYellow(true);
    } else if (btn == 'red') {
      setOpenSnackRed(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackGreen(false);
    setOpenSnackYellow(false);
    setOpenSnackRed(false);
  };

  //------------------------------------------------------------------------//

  const handleChangePassword = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setUser({ ...user, [prop]: event.target.value });

    console.log(event.target.name, event.target.value);

    if (validateOnChange) validationUser({ [event.target.name]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForm = (e) => {
    e.preventDefault();

    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    console.log(user);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateOnChange) validationUser({ [e.target.name]: e.target.value });

    const ydata = [
      {
        user: {
          email: user.email,
          password: user.password,
        },
      },
    ];

    console.log(ydata);

    if (validationUser()) {
      dispatch(SignIn(ydata, history, handleOpenAlert));
    }
  };
  const storage = localStorage;

  return (
    <>
      {storage.token ? (
        history.push('/app/reservas')
      ) : (
        <>
          <Snackbar open={openSnackGreen} autoHideDuration={3000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity="success">
              Login efetuado com sucesso!
            </Alert>
          </Snackbar>
          <Snackbar open={openSnackYellow} autoHideDuration={3000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity="warning">
              Por favor, confirme os seus dados novamente.
            </Alert>
          </Snackbar>
          <Snackbar open={openSnackRed} autoHideDuration={3000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity="error">
              Houve um problema no servidor.
            </Alert>
          </Snackbar>
          <ThemeProvider theme={defaultMaterialTheme}>
            <div className="home">
              <div className="home-left">
                <img src={Logo} alt="" className="logo" />
                <div className="login-form">
                  <div className="titulo">
                    <h2>Bem Vindo!</h2>
                  </div>

                  <form className="loginForm" noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <TextField
                      id="email"
                      label="Email"
                      variant="outlined"
                      className="emailInput"
                      value={user.email}
                      name="email"
                      onChange={handleForm}
                      {...(errorsUser.email && {
                        error: true,
                        helperText: errorsUser.email,
                      })}
                    />
                    <FormControl
                      // className={clsx(classes.margin, classes.textField)}
                      variant="outlined"
                      {...(errorsUser.password && {
                        error: true,
                        // , helperText: errors.password
                      })}
                    >
                      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        name="password"
                        onChange={handleChangePassword('password')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={80}
                      />
                      {errorsUser.password && (
                        <FormHelperText style={{ color: 'red' }}>
                          {errorsUser.password}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </form>
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Entrar
                  </Button>
                  {/* </Link> */}
                  <Link to="/recuperarSenha" style={{ color: 'black' }}>
                    <p>Recuperar Password</p>
                  </Link>
                </div>
                <div className="bottom"></div>
              </div>
              <div className="home-right"></div>
            </div>
          </ThemeProvider>
        </>
      )}
    </>
  );
};

export default Login;
