import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Logo from '../../../../../images/logo-vbeach.png';
// import MyFont from './Roboto-Bold-bold';

// console.log(MyFont);

const PDFResumo = (ydata, dataInicio, dataFim, dataImpressao, parceiro, analiseDeVendasPDF) => {
  const doc = new jsPDF('p', 'mm', 'a4');
  // var width = doc.internal.pageSize.getWidth();
  // var height = doc.internal.pageSize.getHeight();

  // const registosPorPagina = 15;
  const totalPagesExp = '{total_pages_count_string}';
  //   let pageCount = Math.round(y.length / registosPorPagina);
  //   if (pageCount === 0) {
  //     pageCount = 1;
  //   }
  // doc.setFont('helvetica');
  // const myFont = './Roboto-Bold';
  // doc.setFont('Roboto-Bold', 'normal');

  // doc.addFileToVFS('Roboto-Bold.ttf', MyFont);
  // doc.addFont('Roboto-Bold.ttf', 'Roboto-Bold', 'normal');
  // doc.setFont('Roboto-Bold');
  // eslint-disable-next-line no-unused-vars
  let numPagesTotal = 0;

  const headerContent = () => {
    doc.addImage(Logo, 'PNG', 10, 10, 50, 35);

    doc.setDrawColor(0);
    doc.setFillColor(235, 235, 235);

    // Titulo do documento
    // doc.setFontSize(12);
    doc.setLineWidth(0.02);
    // doc.rect(200, 8, 60, 13, 'FD');
    doc.setFont('helvetica');
    doc.setFontSize(10);
    doc.text('Desde:', 150, 20, { align: 'left' });
    doc.text(`${dataInicio}`, 190, 20, { align: 'right' });
    doc.text('Até:', 150, 25, { align: 'left' });
    doc.text(`${dataFim}`, 190, 25, { align: 'right' });
    doc.text('Impressão:', 150, 30, { align: 'left' });
    doc.text(`${dataImpressao}`, 190, 30, { align: 'right' });
    // doc.text(232, 13, `Ano: ${ano}`);
    // doc.text(252, 13, `Mês: ${mes}`);
    // doc.text(232, 18, `Data de Impressão: ${data}`);
  };

  headerContent();
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(16);
  doc.text('Parceiro', 15, 50, { align: 'left' });
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(12);
  doc.text(`${parceiro.nome}`, 15, 58, { align: 'left' });
  doc.text(`${parceiro.morada}`, 15, 63, { align: 'left' });
  doc.text(`Tel: ${parceiro.telefone}`, 15, 68, { align: 'left' });

  const headers = [
    {
      dataKey: 'produtoNome',
      header: 'Produto',
    },
    {
      dataKey: 'qtd',
      header: 'Quantidade',
    },

    {
      dataKey: 'custo',
      header: 'Custo',
    },
    {
      dataKey: 'txttotal',
      header: 'Valor',
    },
  ];

  const headersValores = [
    {
      dataKey: 'descricao',
      header: 'Descrição',
    },
    {
      dataKey: 'menus',
      header: 'Menus',
    },
    {
      dataKey: 'valor',
      header: 'Valor',
    },
  ];

  doc.text('Menu Adulto', 15, 80, { align: 'left' });

  //----------------------- TABELA ADULTO -------------------------------//

  let bodyAdulto = analiseDeVendasPDF && analiseDeVendasPDF.produtosAdulto;

  // Calculo Total ( ultima row )

  let subTotalAdulto = bodyAdulto.reduce((sum, el) => sum + el.total, 0).toFixed(2);

  // const yDataMaped = ydata.map((venda) => [venda]);
  // console.log(ydata.map((venda) => console.log([venda].apartamentoNome)));

  // body.push(yDataMaped);

  bodyAdulto = [
    ...bodyAdulto.map((el) => [el.produtoNome, el.qtd, el.custo, el.txttotal]),
    [
      {
        content: ``,
        colSpan: 2,
        styles: { halign: 'right', border: 0 },
      },
      {
        content: `Subtotal`,
        colSpan: 1,
        styles: { halign: 'right' },
      },
      {
        content: `${subTotalAdulto} €`,
        styles: { halign: 'right' },
      },
    ],
  ];

  doc.autoTable({
    columns: headers,
    columnStyles: {
      0: { cellWidth: 115 },
      1: { halign: 'right', cellWidth: 25 },
      2: { halign: 'right', cellWidth: 20 },
      3: { halign: 'right', cellWidth: 20 },
    },
    body: bodyAdulto,
    startX: 30,
    startY: 85,
    theme: 'grid',
    margin: { left: 15, right: 15, top: 45 },
    // padding: { top: 0, right: 0, bottom: 0, left: 0 },
    didParseCell(dataParseCell) {
      const rows = dataParseCell.table.body;
      if (dataParseCell.row.index === rows.length - 1) {
        if (!dataParseCell.cell.raw.content) {
          dataParseCell.cell.styles.lineWidth = 0;
          dataParseCell.cell.styles.fillColor = null;
        }
      }
      if (
        (dataParseCell.row.section === 'head' || dataParseCell.row.section === 'foot') &&
        !['qtd', 'descricao', 'precoUnidade', 'valor'].includes(dataParseCell.column.dataKey)
      ) {
        // let anoActual = ano;
        // let mesActual = mes - 1;
        //   if (
        //     parseInt(dataParseCell.column.raw.header, 10) >= 25 &&
        //     parseInt(dataParseCell.column.raw.header, 10) <= 31
        //   ) {
        //     mesActual--;
        //     if (mesActual < 0) {
        //       mesActual = 11;
        //       anoActual--;
        //     }
        //   }
        //   if (
        //     isWeekend(
        //       new Date(
        //         anoActual,
        //         mesActual,
        //         parseInt(dataParseCell.column.raw.header, 10)
        //       )
        //     )
        //   ) {
        //     dataParseCell.cell.styles.fillColor = [40, 170, 100];
        //   }
        // }
      }
    },

    // didDrawPage: (dataPage) => {
    //   headerContent();
    //   let footerStr = `Página ${doc.internal.getNumberOfPages()}`;
    //   if (typeof doc.putTotalPages === 'function') {
    //     footerStr = `${footerStr} de ${totalPagesExp}`;
    //   }
    //   doc.setFontSize(10);
    //   doc.text(footerStr, dataPage.settings.margin.left, doc.internal.pageSize.height - 10);

    //   numPagesTotal++;
    // },

    // eslint-disable-next-line no-unused-vars
    addPageContent: (dataPage) => {
      // headerContent();
      let footerStr = `Página ${doc.internal.getNumberOfPages()}`;
      const totalPag = doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        footerStr = `Página ${totalPag} de ${totalPagesExp}`;
      }
      doc.setFontSize(10);
      // doc.text(footerStr, dataPage.settings.margin.left, doc.internal.pageSize.height - 10);

      doc.text(footerStr, 15, doc.internal.pageSize.height - 10, {
        align: 'left',
      });
    },

    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [80, 80, 80],
    },
    styles: {
      fontSize: 8,
      margins: 0,
      // padding: 1,
      printHeaders: true,
      lineWidth: 0.15,
    },
  });

  //----------------------- TABELA Crianca -------------------------------//

  let bodyCrianca = analiseDeVendasPDF && analiseDeVendasPDF.produtosCrianca;

  // Calculo Total ( ultima row )

  let subTotalCrianca = bodyCrianca.reduce((sum, el) => sum + el.total, 0).toFixed(2);

  if (bodyCrianca && bodyCrianca.length > 0) {
    doc.text('Menu Criança', 15, doc.lastAutoTable.finalY + 5, { align: 'left' });
    bodyCrianca = [
      ...bodyCrianca.map((el) => [el.produtoNome, el.qtd, el.custo, el.txttotal]),
      [
        {
          content: ``,
          colSpan: 2,
          styles: { halign: 'right', border: 0 },
        },
        {
          content: `Subtotal`,
          colSpan: 1,
          styles: { halign: 'right' },
        },
        {
          content: `${subTotalCrianca} €`,
          styles: { halign: 'right' },
        },
      ],
    ];

    doc.autoTable({
      columns: headers,
      columnStyles: {
        0: { cellWidth: 115 },
        1: { halign: 'right', cellWidth: 25 },
        2: { halign: 'right', cellWidth: 20 },
        3: { halign: 'right', cellWidth: 20 },
      },

      body: bodyCrianca,

      startY: doc.lastAutoTable.finalY + 10,
      theme: 'grid',
      margin: { left: 15, right: 15, top: 45 },

      didParseCell(dataParseCell) {
        const rows = dataParseCell.table.body;
        if (dataParseCell.row.index === rows.length - 1) {
          if (!dataParseCell.cell.raw.content) {
            dataParseCell.cell.styles.lineWidth = 0;
            dataParseCell.cell.styles.fillColor = null;
          }
        }
      },

      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [80, 80, 80],
      },
      styles: {
        fontSize: 8,
        margins: 0,
        // padding: 1,
        printHeaders: true,
        lineWidth: 0.15,
      },
    });
  }

  //----------------------- TABELA VALORES -------------------------------//

  doc.text('Resumo', 130, doc.lastAutoTable.finalY + 5, { align: 'left' });

  let bodyValores = analiseDeVendasPDF && analiseDeVendasPDF.totais;

  // Calculo Total ( ultima row )

  // const yDataMaped = ydata.map((venda) => [venda]);
  // console.log(ydata.map((venda) => console.log([venda].apartamentoNome)));

  // body.push(yDataMaped);

  bodyValores = [...bodyValores.map((el) => [el.descricao, el.menus, el.valor])];

  doc.autoTable({
    columns: headersValores,
    columnStyles: {
      0: { cellWidth: 25 },
      1: { halign: 'right', cellWidth: 20 },
      2: { halign: 'right', cellWidth: 20 },
    },

    body: bodyValores,

    startY: doc.lastAutoTable.finalY + 10,
    theme: 'grid',
    margin: { left: 130, right: 15, top: 45 },

    // didParseCell(dataParseCell) {
    //   const rows = dataParseCell.table.body;
    //   if (dataParseCell.row.index === rows.length - 1) {
    //     if (!dataParseCell.cell.raw.content) {
    //       dataParseCell.cell.styles.lineWidth = 0;
    //       dataParseCell.cell.styles.fillColor = null;
    //     }
    //   }
    // },

    // eslint-disable-next-line no-unused-vars
    addPageContent: (dataPage) => {
      headerContent();
      let footerStr = `Página ${doc.internal.getNumberOfPages()}`;
      const totalPag = doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        footerStr = `Página ${totalPag} de ${totalPagesExp}`;
      }
      doc.setFontSize(10);
      // doc.text(footerStr, dataPage.settings.margin.left, doc.internal.pageSize.height - 10);

      doc.text(footerStr, 15, doc.internal.pageSize.height - 10, {
        align: 'left',
      });
    },

    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [80, 80, 80],
    },
    styles: {
      fontSize: 8,
      margins: 0,
      // padding: 1,
      printHeaders: true,
      lineWidth: 0.15,
    },
  });

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save('Análise de vendas.pdf');
  doc.autoPrint();
};

export default PDFResumo;
