/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import '../menus.css';
//PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField, InputAdornment } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IoMdTrash } from 'react-icons/io';

//ACTIONS
import AssociarMenuCategoria from '../../../../utils/redux/actions/menus/adicionarMenuCategoriasAction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '700px',
    width: '700px',

    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  btnAdicionar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnAdicionarDisabled: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[200],
    '&:hover': {
      backgroundColor: green[300],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[200],
      },
    },
  },
}));

const ModalCategorias = ({
  openCategorias,
  handleClose,
  menuCategorias,
  menuInfo,
  menuId,
  fetchMenus,
  menuNome,
  defaultSelector,
  setDefaultSelector,
  handleOpenAlert,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState({});

  const handleChangeQtdAdulto = (e, c) => {
    e.preventDefault();
    const catIndex = menuCategorias.yData.findIndex((cat) => cat.id == c.id);

    // console.log(e.target.value, c, menuCategorias);
    menuCategorias.yData[catIndex].qtdAdulto = parseInt(e.target.value);
    dispatch({
      type: 'GET_MENU_CATEGORIAS',
      payload: { ...menuCategorias },
    });
  };
  const handleChangeQtdCrianca = (e, c) => {
    e.preventDefault();
    const catIndex = menuCategorias.yData.findIndex((cat) => cat.id == c.id);

    // console.log(e.target.value, c, menuCategorias);
    menuCategorias.yData[catIndex].qtdCrianca = parseInt(e.target.value);
    dispatch({
      type: 'GET_MENU_CATEGORIAS',
      payload: { ...menuCategorias },
    });
  };
  const handleChangeOrdem = (e, c) => {
    e.preventDefault();
    const catIndex = menuCategorias.yData.findIndex((cat) => cat.id == c.id);

    // console.log(e.target.value, c, menuCategorias);
    menuCategorias.yData[catIndex].ordem = parseInt(e.target.value);
    dispatch({
      type: 'GET_MENU_CATEGORIAS',
      payload: { ...menuCategorias },
    });
  };

  const lastValue = () => {
    // return Math.max.apply(
    //   Math,
    //   menuCategorias.yData.map((categoria) => categoria.ordem)
    // );

    return Math.max.apply(
      Math,
      menuCategorias.yData.map((categoria) => categoria.ordem).length <= 0
        ? [0]
        : menuCategorias.yData.map((categoria) => categoria.ordem),
    );
  };

  const handleChangeCategorias = (e, value) => {
    e.preventDefault();

    setSelectedValue(value);
    setDefaultSelector(value);
  };

  const handleAssociar = (e, value) => {
    e.preventDefault();

    selectedValue.ordem = lastValue() + 1;
    menuCategorias.yData.push(selectedValue);

    menuCategorias.dropdown = menuCategorias.dropdown
      .map((rCat) => {
        if (rCat.id != selectedValue.id) {
          return rCat;
        }
      })
      .filter((rProd) => rProd !== undefined);

    dispatch({
      type: 'GET_MENU_CATEGORIAS',
      payload: {
        yData: [...menuCategorias.yData],
        dropdown: menuCategorias.dropdown,
      },
    });

    setDefaultSelector({
      id: 0,
      descricao: '',
      qtd: 0,
    });
  };

  const handleRemover = (e, categoria) => {
    e.preventDefault();

    menuCategorias.yData = menuCategorias.yData
      .map((rCat) => {
        if (rCat.id != categoria.id) {
          return rCat;
        }
      })
      .filter((rProd) => rProd !== undefined);

    menuCategorias.dropdown.push(categoria);
    menuCategorias.dropdown.sort(function (a, b) {
      if (a.descricao > b.descricao) {
        return 1;
      }
      if (a.descricao < b.descricao) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    dispatch({
      type: 'GET_MENU_PRODUTOS',
      // payload: { yData: produtos.yData, dropdown: produtos.dropdown },
      payload: {
        yData: [...menuCategorias.yData],
        dropdown: menuCategorias.dropdown,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = menuCategorias.yData.map(({ id, qtdAdulto, qtdCrianca, ordem }) => ({
      categoriaId: id,
      qtdAdulto,
      qtdCrianca,
      ordem,
    }));

    dispatch(AssociarMenuCategoria(ydata, menuId))
      .then(() => handleClose('categorias', e))
      .then(() => fetchMenus())
      .then(() => handleOpenAlert('categorias'));
  };

  // eslint-disable-next-line no-unused-vars
  let c = null;

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openCategorias}
        onClose={(e) => handleClose('categorias', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCategorias}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Associar Categorias
                </h2>

                <p id="transition-modal-description" className="descricao-modal">
                  Pode associar aqui categorias ao menu<b> {menuNome}</b>
                </p>
                <br />

                <div className="add-prod-form">
                  <div className="add-prod-selector">
                    <Autocomplete
                      id="combo-box-demo"
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      noOptionsText="Sem dados"
                      name="categoria"
                      options={menuCategorias.dropdown || []}
                      includeInputInList
                      getOptionLabel={(option) => `${option.descricao}`}
                      value={defaultSelector}
                      onInputChange={(event, value, reason) => {
                        if (reason == 'clear') {
                          setDefaultSelector({
                            id: 0,
                            descricao: '',
                            qtd: 0,
                          });
                        }
                      }}
                      getOptionSelected={(option, value) => {
                        return value.id === option.id;
                      }}
                      style={{ width: '100%' }}
                      filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                      onChange={(e, value) => handleChangeCategorias(e, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Escolher categoria"
                          variant="outlined"
                          placeholder="Escolher categoria"
                          // required
                          // {...(errors.encarregado && {
                          //   error: true,
                          //   helperText: errors.encarregado,
                          // })}
                          inputProps={{
                            ...params.inputProps,
                            style: { paddingLeft: '20px' },
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="add-prod-btn">
                    <Button
                      className={
                        defaultSelector && defaultSelector.id !== 0
                          ? classes.btnAdicionar
                          : classes.btnAdicionarDisabled
                      }
                      onClick={(e, value) => handleAssociar(e, value)}
                      disabled={(defaultSelector && defaultSelector.id === 0) || !defaultSelector}
                    >
                      +
                    </Button>
                  </div>
                </div>
                <br />
                <br />
                <div
                  style={{
                    overflowY: 'scroll',
                    maxHeight: 'calc(100% - 37vh)',
                    minHeight: '5%',
                    width: '100%',
                  }}
                >
                  {menuCategorias &&
                    menuCategorias.yData.map((categoria) => (
                      <div key={categoria.id}>
                        <div className="menu-list-cats" key={categoria.id}>
                          <div className="menu-cats">
                            <p>{categoria.descricao}</p>
                          </div>

                          <div>
                            {/* Posição no menu */}
                            <TextField
                              // id='posição'
                              name={categoria.descricao}
                              label="Pos."
                              InputProps={{
                                endAdornment: <InputAdornment position="start">º</InputAdornment>,
                              }}
                              style={{ width: '38px', marginRight: '20px' }}
                              // variant='outlined'
                              // color='primary'
                              inputProps={{ maxLength: 2 }}
                              value={categoria.ordem || 0}
                              onChange={(e) => handleChangeOrdem(e, categoria)}
                              fullWidth
                              autoComplete="off"
                              className="ass-categoria-quantidade"
                            />
                          </div>

                          <div>
                            <TextField
                              // id='quantidadeAdulto'
                              name={categoria.descricao}
                              label="Pax"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">X</InputAdornment>,
                              }}
                              // variant='outlined'
                              // color='primary'
                              value={categoria.qtdAdulto || 0}
                              onChange={(e) => handleChangeQtdAdulto(e, categoria)}
                              fullWidth
                              autoComplete="off"
                              className="ass-categoria-quantidade"
                              style={{ width: '40px', marginRight: '20px' }}
                              // {...(errorsMenu.descricao && {
                              //   error: true,
                              //   helperText: errorsMenu.descricao,
                              // })}
                            />
                            <TextField
                              // id='quantidadeCrianca'
                              name={categoria.descricao}
                              label="Pax"
                              InputProps={{
                                startAdornment: <InputAdornment position="start">X</InputAdornment>,
                              }}
                              // variant='outlined'
                              // color='primary'
                              value={categoria.qtdCrianca || 0}
                              onChange={(e) => handleChangeQtdCrianca(e, categoria)}
                              fullWidth
                              autoComplete="off"
                              className="ass-categoria-quantidade"
                              style={{ width: '40px' }}
                              // {...(errorsMenu.descricao && {
                              //   error: true,
                              //   helperText: errorsMenu.descricao,
                              // })}
                            />
                          </div>
                          <div className="menu-cats-remover">
                            {/* <DeleteIcon style={{ height: '200px !important' }} /> */}
                            <IoMdTrash
                              size="2em"
                              onClick={(e) => handleRemover(e, categoria)}
                              className="clickable"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button
                  className={classes.btnCancelar}
                  onClick={(e) => handleClose('categorias', e)}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalCategorias;
