import axios from 'axios';
import BASE_URL from '../../../BASE_URL';

const RecuperarSenha =
  // eslint-disable-next-line no-unused-vars
  (ydata, handleOpenAlert, history, counter, setCounter) => async (dispatch) => {
    console.log(ydata);
    console.log(counter);

    await axios
      .post(`${BASE_URL}/backend/utilizadores/recuperarsenha`, { ydata })
      .then(({ data }) => {
        console.log(data);
        setCounter({
          ...counter,
          running: true,
        });
        console.log('Deu 401');
      })
      .then(() => handleOpenAlert('green'))
      .catch(({ err }) => {
        handleOpenAlert('yellow');
        console.log(err);
      });
  };
export default RecuperarSenha;
