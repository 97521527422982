import Instance from '../../../../Axios';

const EditarReserva = (ydata, id) => async (dispatch) => {
  console.log(ydata, id);
  await Instance()
    .put(`/backend/reservas/${id}`, { ydata })
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'PUT_RESERVA', payload: data.yData[0] });
      //   show('success', 'Foram associados novos funcionários á obra');
    })
    .catch((err) => {
      // eslint-disable-next-line eqeqeq
      if (err.response.status != 200) {
        // show('warning', 'Preencha todos os campos');
      }
    });
};

export default EditarReserva;
