/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
//PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

//ACTIONS
import RemoverEncomenda from '../../../../../../utils/redux/actions/reservas/reserva/RemoverEncomendaAction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalDelete = ({
  openDelete,
  handleClose,
  handleOpenAlert,
  encomenda,
  validateOnChange = true,
  data,
  fetchReserva,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [apagarEncomenda, setApagarEncomenda] = useState({
    justificacao: '',
  });

  useEffect(() => {
    setErrorsApagarEncomenda({});
    setApagarEncomenda({
      justificacao: '',
    });
  }, [handleClose]);

  const handleChange = (e) => {
    e.preventDefault();

    setApagarEncomenda({
      ...apagarEncomenda,
      [e.target.name]: e.target.value,
    });

    if (validateOnChange) validationEncomenda({ [e.target.name]: e.target.value });
  };

  // -------------------------- Validacao Reserva --------------------//
  const [errorsApagarEncomenda, setErrorsApagarEncomenda] = useState({});

  const validationEncomenda = (fieldValues = apagarEncomenda) => {
    const temp = { ...errorsApagarEncomenda };
    if ('justificacao' in fieldValues)
      temp.justificacao =
        fieldValues.justificacao.replace(/\s/g, '').length > 0 ? '' : 'Campo Obrigatório';

    setErrorsApagarEncomenda({
      ...temp,
    });
    if (fieldValues == apagarEncomenda) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  const handleRemover = (e) => {
    e.preventDefault();
    if (validationEncomenda()) {
      const ydata = [
        {
          encomendaId: encomenda.id,
          justificacao: apagarEncomenda.justificacao,
        },
      ];

      console.log(ydata);

      dispatch(RemoverEncomenda(ydata))
        .then(() => handleClose('delete', e))
        .then(() => fetchReserva())
        .then(() => handleOpenAlert('delete'));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDelete}
        onClose={(e) => handleClose('delete', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Remover Pequeno Almoço
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Tem a certeza que quer remover o pequeno almoço associado ao dia <b>{data}</b> da
                  lista?
                </p>
              </div>

              <div>
                <TextField
                  id="justificacao"
                  name="justificacao"
                  label="Justificação"
                  multiline
                  // variant='outlined'
                  // color='primary'
                  // value={categoria.descricao}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="nome"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsApagarEncomenda.justificacao && {
                    error: true,
                    helperText: errorsApagarEncomenda.justificacao,
                  })}
                />
              </div>

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleRemover}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('delete', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalDelete;
