/* eslint-disable eqeqeq */
import React, { useState } from 'react';
//PACKAGES
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { red, yellow } from '@material-ui/core/colors';

//ACTIONS
import ModalDelete from './modalDelete';
import ModalEdit from './editarReserva';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '85%',
    marginBottom: '20px',
    margin: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  descricao: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  typo: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    height: '40px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  btnApagar1: {
    minWidth: '100px',
    marginLeft: '5%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  btnEditar: {
    width: '100px',
    // marginRight: '5%',

    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    width: '120px',
    zIndex: '55',

    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
}));

const DetalhesReserva = ({
  reservaInfo,
  resetReserva,
  fetchReserva,
  reserva,
  setReserva,
  validationReserva,
  errorsReserva,
  setErrorsReserva,
  setApartamentoSelecionado,
  apartamentoSelecionado,
  openSnackEdit,
  openSnackAdd,
  openSnackDelete,
  handleOpenAlert,
  handleCloseAlert,
  linguagemSelecionada,
  setLinguagemSelecionada,
}) => {
  const classes = useStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const checkIn = moment(reservaInfo.checkin).format('DD-MM-YYYY');

  const checkOut = moment(reservaInfo.checkout).format('DD-MM-YYYY');

  // useEffect(() => {
  //   reservaInfo &&
  //     setReserva({
  //       apartamentosId: reservaInfo.apartamentos_id,
  //       checkIn: reservaInfo.checkin,
  //       checkOut: reservaInfo.checkout,
  //       numPessoas: reservaInfo.numPessoas,
  //       reservaBookingId: reservaInfo.reservaBookingID,
  //       reservaSiteId: reservaInfo.reservaSiteID,
  //       clienteNome: reservaInfo.clienteNome,
  //       clienteMorada: reservaInfo.clienteMorada,
  //       clienteCodPostal: reservaInfo.clienteCodPostal,
  //       clienteLocalidade: reservaInfo.clienteLocalidade,
  //       clienteEmail: reservaInfo.clienteEmail,
  //       clienteContacto: reservaInfo.clienteContato,
  //     });
  // }, [reservaInfo, setReserva]);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();

    if (btn == 'edit') {
      setOpenEdit(true);
      setReserva({
        apartamentosId: reservaInfo.apartamentos_id,
        checkIn: reservaInfo.checkin,
        checkOut: reservaInfo.checkout,
        numAdultos: reservaInfo.numPessoasAdultas,
        numCriancas: reservaInfo.numPessoasCriancas,
        reservaBookingId: reservaInfo.reservaBookingID,
        reservaSiteId: reservaInfo.reservaSiteID,
        clienteNome: reservaInfo.clienteNome,
        clienteMorada: reservaInfo.clienteMorada,
        clienteCodPostal: reservaInfo.clienteCodPostal,
        clienteLocalidade: reservaInfo.clienteLocalidade,
        clienteEmail: reservaInfo.clienteEmail,
        clienteContacto: reservaInfo.clienteContato,
        linguagem: reservaInfo.linguaCode,
      });
      // setUnidadeId(id);
      // setUnidadeNome(tableMeta.rowData[0]);
      // fetchUnidade(id, e);
      setErrorsReserva({});
    } else if (btn == 'delete') {
      setOpenDelete(true);
      // setUnidadeId(id);
      // setUnidadeNome(tableMeta.rowData[0]);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();

    if (btn == 'edit') {
      setOpenEdit(false);
      // setParceiroSelecionado({
      //   parceirosId: '',
      //   descricao: '',
      // });
      // setErrorsUnidade({});
      // dispatch({ type: 'CLEAR_DATA_UNIDADE' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else {
      // setOpenAdicionar(false);
      // resetReserva();
      // setErrorsReserva({});
    }
    // setUnidadeNome();
    // resetReserva();
  };
  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  //------------------------------------------------------------------------//

  return (
    <>
      <ModalDelete openDelete={openDelete} handleClose={handleClose} reservaInfo={reservaInfo} />
      <ModalEdit
        openEdit={openEdit}
        openSnackEdit={openSnackEdit}
        handleClose={handleClose}
        reserva={reserva}
        setReserva={setReserva}
        resetReserva={resetReserva}
        validationReserva={validationReserva}
        errorsReserva={errorsReserva}
        reservaInfo={reservaInfo}
        setApartamentoSelecionado={setApartamentoSelecionado}
        apartamentoSelecionado={apartamentoSelecionado}
        handleOpenAlert={handleOpenAlert}
        linguagemSelecionada={linguagemSelecionada}
        setLinguagemSelecionada={setLinguagemSelecionada}
      />
      <div className="infoReservaP">
        <p>
          <b> Cliente:</b> {reservaInfo.clienteNome}
        </p>
        <p>
          <b> Check-In:</b> {checkIn}
        </p>
        <p>
          <b> Check-Out: </b>
          {checkOut}
        </p>
        <p>
          <b> Contacto:</b> {reservaInfo.clienteContato}
        </p>
        <p>
          <b> Nº Adultos:</b> {reservaInfo.numPessoasAdultas}
        </p>
        <p>
          <b> Nº Crianças:</b> {reservaInfo.numPessoasCriancas}
        </p>
        <p>
          <b> Email: </b>
          {reservaInfo.clienteEmail}
        </p>

        {reservaInfo &&
        reservaInfo.reservaBookingID != null &&
        reservaInfo.reservaBookingID != '' ? (
          <p>
            <b> Booking Id:</b> {reservaInfo.reservaBookingID}
          </p>
        ) : (
          <p>
            <b> Site Id:</b> {reservaInfo.reservaSiteID}
          </p>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '25px',
          marginBottom: '10px ',
        }}
      >
        <Button
          onClick={(e) => {
            handleOpen('edit', e);
          }}
          className={classes.btnEditar}
        >
          <EditIcon style={{ color: 'white' }} />
        </Button>
        <Button
          onClick={(e) => {
            handleOpen('delete', e);
          }}
          className={classes.btnApagar1}
        >
          <DeleteIcon style={{ color: 'white' }} />
        </Button>
      </div>
    </>
    // <div className='infoReservaP'>
    //   <p>
    //     <b>
    //       <FaConciergeBell />
    //     </b>{' '}
    //     {reservaInfo.id}
    //   </p>
    //   <p>
    //     <b>
    //       <FaUserAlt />
    //     </b>{' '}
    //     {reservaInfo.clienteNome}
    //   </p>
    //   <p>
    //     <b>
    //       <FaArrowUp style={{ transform: 'rotate(-135deg)' }} size='1.4em' />
    //     </b>{' '}
    //     {checkIn}
    //   </p>
    //   <p>
    //     <b>
    //       <b>
    //         <FaArrowUp style={{ transform: 'rotate(45deg)' }} />
    //       </b>{' '}
    //     </b>
    //     {checkOut}
    //   </p>
    //   <p>
    //     <b>
    //       <IoPhonePortraitOutline size='1.4em' />
    //     </b>{' '}
    //     {reservaInfo.clienteContato}
    //   </p>
    //   <p>
    //     <b>
    //       <FaUsers />
    //     </b>{' '}
    //     {reservaInfo.numPessoas}
    //   </p>
    //   <p>
    //     <b>
    //       <HiMail />
    //     </b>
    //     {reservaInfo.clienteEmail}
    //   </p>

    //   {reservaInfo && reservaInfo.reservaBookingID != null ? (
    //     <p>
    //       <b>B</b> {reservaInfo.reservaBookingID}
    //     </p>
    //   ) : (
    //     <p>
    //       <b>S</b> {reservaInfo.reservaSiteID}
    //     </p>
    //   )}
    // </div>
  );
};
export default DetalhesReserva;
