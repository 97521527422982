import React from 'react';

//COMPONENTS

// PACKAGES
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField, InputAdornment } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '80vh',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
    height: '50px',
  },
}));

const ModalAdicionar = ({ openInfo, handleClose, peqAlmoco }) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openInfo}
        onClose={(e) => handleClose('info', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openInfo}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Informação do pequeno almoço
                </h2>
              </div>

              <div className="form-add-apartamento">
                <div className="infoReservaP" style={{ marginTop: '0px' }}>
                  <p>
                    <b> Apartamento:</b> {peqAlmoco.apartamentoNome}
                  </p>
                  <p>
                    <b> Tipo de Menu:</b> {peqAlmoco.menuDescricao}
                  </p>
                  <p>
                    <b> Criação:</b> {peqAlmoco.dataCriacao}
                  </p>
                  <p>
                    <b> Parceiro: </b>
                    {peqAlmoco.parceirosNome}
                  </p>
                  <p>
                    <b> Cliente: </b>
                    {peqAlmoco.clienteNome}
                  </p>

                  <p>
                    <b> Pedido de Adulto(s):</b>
                  </p>
                  <div>
                    {peqAlmoco &&
                      peqAlmoco.produtosAdulto &&
                      peqAlmoco.produtosAdulto.map((produtos) => (
                        <div key={produtos.id}>
                          <div className="menu-list-cats">
                            <div className="menu-cats">
                              <p>{produtos.produtosNome}</p>
                            </div>
                            <div style={{ marginLeft: '50px' }}>
                              <TextField
                                name={produtos.descricao}
                                label="Pax"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">X</InputAdornment>
                                  ),
                                }}
                                value={produtos.qtd || 0}
                                disabled
                                fullWidth
                                autoComplete="off"
                                className="ass-categoria-quantidade"
                                style={{ width: '100px' }}
                              />
                            </div>
                            <div className="menu-cats-remover"></div>
                          </div>
                        </div>
                      ))}
                  </div>

                  {peqAlmoco &&
                  peqAlmoco.produtosCrianca &&
                  peqAlmoco.produtosCrianca.length > 0 ? (
                    <p>
                      <b> Pedido de Criança(s):</b>
                    </p>
                  ) : null}
                  <div>
                    {peqAlmoco &&
                      peqAlmoco.produtosCrianca &&
                      peqAlmoco.produtosCrianca.map((produtos) => (
                        <div key={produtos.id}>
                          <div className="menu-list-cats">
                            <div className="menu-cats">
                              <p>{produtos.produtosNome}</p>
                            </div>
                            <div style={{ marginLeft: '50px' }}>
                              <TextField
                                name={produtos.descricao}
                                label="Pax"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">X</InputAdornment>
                                  ),
                                }}
                                value={produtos.qtd || 0}
                                disabled
                                fullWidth
                                autoComplete="off"
                                className="ass-categoria-quantidade"
                                style={{ width: '100px' }}
                              />
                            </div>
                            <div className="menu-cats-remover"></div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnCancelar} onClick={(e) => handleClose('info', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
