import { relatoriosInitialState } from '../initialState/relatorios';

const relatoriosReducer = (state = relatoriosInitialState, { type, payload }) => {
  switch (type) {
    // ITENS
    case 'GET_ANALISE_DE_VENDAS_RELATORIOS':
      return {
        ...state,
        analiseDeVendasRelatorio: payload,
      };
    case 'GET_ANALISE_DE_VENDAS_RELATORIOS_RESUMO':
      return {
        ...state,
        analiseDeVendasRelatorioResumo: payload,
      };
    case 'GET_ANALISE_DE_VENDAS_PDF':
      return {
        ...state,
        analiseDeVendasPDF: payload,
      };
    case 'CLEAR_ANALISE_DE_VENDAS_RELATORIOS':
      return {
        ...state,
        analiseDeVendasRelatorio: [],
      };

    default:
      return state;
  }
};

export default relatoriosReducer;
