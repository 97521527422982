import React, { useEffect } from 'react';
// import '../parceiros.css';

//PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

//ACTIONS
import EditarCategoria from '../../../../utils/redux/actions/categorias/editarCategoriaAction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalEdit = ({
  openEdit,
  handleClose,
  categoria,
  setCategoria,
  // validateOnChange = true,
  // validationCategoria,
  // errorsLingState = null,
  fetchCategorias,
  categoriaId,
  handleOpenAlert,
  categoriaNome,
  categoriaInfo,
  // LINGUAGENS
  empty,
  setEmpty,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleChange = (e, value) => {
    e.preventDefault();
    const catIndex =
      categoria.descricao && categoria.descricao.findIndex((c) => c.linguasId === value.linguasId);

    categoria.descricao[catIndex].descricao = e.target.value;

    dispatch({ type: 'GET_CATEGORIA', payload: categoria });

    const allEmpty = categoria.descricao.map((l) => l.descricao.length > 0).find((a) => a == true);

    if (allEmpty != true) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
  };

  useEffect(() => {
    setCategoria({
      descricao: categoriaInfo.descricao,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriaInfo]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let ydata = categoria.descricao;
    // let ydata = categoria.filter((c) => categoria.linguaId > 0);
    ydata = [{ descricao: ydata }];
    const allEmpty = categoria.descricao.map((l) => l.descricao.length > 0).find((a) => a == true);

    if (empty == true || allEmpty == true) {
      dispatch(EditarCategoria(ydata, categoriaId))
        .then(() => handleClose('edit', e))
        .then(() => fetchCategorias())
        .then(() => handleOpenAlert('edit'));
    } else {
      setEmpty(false);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        onClose={(e) => handleClose('edit', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Editar Categoria
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode editar a categoria <b> {categoriaNome}</b> que irá ser confecionado e
                  entregue aos clientes.
                </p>
              </div>
              {categoria &&
                categoria.descricao &&
                categoria.descricao.length > 0 &&
                categoria.descricao.map((cat) => (
                  <div key={cat.linguasId}>
                    <TextField
                      // id={ling.id}
                      name="descricao"
                      label={cat.linguasDescricao}
                      onChange={(e) => handleChange(e, cat)}
                      value={cat.descricao}
                      fullWidth
                      autoComplete="off"
                      className="nome"
                      {...(empty == false && {
                        error: true,
                      })}
                    />
                  </div>
                ))}

              {empty == false && (
                <div style={{ color: '#f44336', marginTop: '15px', fontSize: '12px' }}>
                  {' '}
                  *Preencher pelo menos um campo
                </div>
              )}
              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('edit', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalEdit;
