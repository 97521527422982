/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

//COMPONENTS

// PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

// ACTIONS
import AdicionarEncomenda from '../../../../../../utils/redux/actions/reservas/reserva/adicionarEncomendaAction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalAdicionar = ({
  openAdicionar,
  handleClose,
  validateOnChange = true,
  errorsReserva = null,
  handleOpenAlert,
  menus,
  menuSelecionado,
  setMenuSelecionado,
  reservaId,
  fetchReserva,
  encomendaMenu,
  setEncomendaMenu,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  // const [encomenda, setEncomenda] = useState({
  //   menusId: 1,
  // });

  const selectMenu = () => {
    if (menus.length > 0 && reservaId) {
      const MenuAssociado = menus.find((menu) => menu.id === encomendaMenu.menusId);

      // console.log(MenuAssociado);

      setMenuSelecionado(
        MenuAssociado || {
          id: 0,
          descricao: '',
        },
      );
    } else {
      setMenuSelecionado({
        id: 0,
        descricao: '',
      });
    }
  };

  useEffect(() => selectMenu(), [encomendaMenu]);

  const handleChangeMenusId = (e, value) => {
    e.preventDefault();
    setMenuSelecionado({
      id: 0,
      descricao: '',
    });
    setEncomendaMenu({
      ...encomendaMenu,
      menusId: (value && value.id) || 0,
    });

    if (validateOnChange) validationEncomenda({ menusId: value });
  };

  // -------------------------- Validacao Reserva --------------------//
  const [errorsEncomenda, setErrorsEncomenda] = useState({});

  const validationEncomenda = (fieldValues = encomendaMenu) => {
    const temp = { ...errorsEncomenda };
    if ('menusId' in fieldValues) temp.menusId = fieldValues.menusId ? '' : 'Campo Obrigatório';

    setErrorsEncomenda({
      ...temp,
    });
    if (fieldValues == encomendaMenu) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  // console.log(reservaInfo);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validationEncomenda()) {
      const ydata = [
        {
          reservasId: reservaId,
          menusId: encomendaMenu.menusId,
        },
      ];

      console.log(ydata);

      dispatch(AdicionarEncomenda(ydata))
        .then(() => handleClose('add', e))
        .then(() => fetchReserva())
        .then(() => handleOpenAlert('add'));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdicionar}
        onClose={(e) => handleClose('add', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdicionar}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Adicionar Pequeno Almoço
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode adicionar aqui um pequeno almoço, e acompanhar o seu processo até chegar dar
                  saida do parceiro.
                </p>
              </div>

              <div className="form-add-apartamento">
                <div>
                  <Autocomplete
                    id="menusId"
                    name="menusId"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={menus}
                    getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                    value={menuSelecionado || ''}
                    getOptionSelected={(option, value) => {
                      if (!value.id) return true;

                      return value.id === option.id;
                    }}
                    filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                    onChange={(e, value) => handleChangeMenusId(e, value)}
                    onInputChange={(event, value, reason) => {
                      if (reason == 'clear') {
                        setEncomendaMenu({
                          ...encomendaMenu,
                          menusId: 0,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Menu"
                        placeholder="Menu"
                        // variant='outlined'
                        {...(errorsEncomenda.menusId && {
                          error: true,
                          helperText: errorsEncomenda.menusId,
                        })}
                      />
                    )}
                  />
                </div>
              </div>
              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('add', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
