export const parceirosInitialState = {
  parceiros: [],
  novoParceiro: [],
  parceiro: [],
  parceiroRemovido: [],
  parceiroEditado: [],

  // Tipo Parceiro

  tipoParceiro: [],

  //Parceiros Dropdown
  parceirosAlimentacao: [],
  parceirosLavandaria: [],
};
