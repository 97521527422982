import Instance from '../../../Axios';

const AdicionarCategoria = (ydata) => async (dispatch) => {
  await Instance()
    .post('/backend/categorias/', { ydata })
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'POST_CATEGORIA', payload: data });
      //   show('success', 'Foram associados novos funcionários á obra');
    })
    .catch((err) => {
      // eslint-disable-next-line eqeqeq
      if (err.response.status != 200) {
        // show('warning', 'Preencha todos os campos');
      }
    });
};

export default AdicionarCategoria;
