import React from 'react';
// ACTIONS
import AdicionarTooltipLimpeza from '../../../../../utils/redux/actions/limpeza/tooltips/adicionarTooltipLimpezaAction';

// PACKAGES

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalAdicionar = ({
  openAdicionar,
  handleClose,
  tooltip,
  setTooltip,
  validateOnChange = true,
  validationTooltip,
  errorsTooltip = null,
  fetchTooltips,
  handleOpenAlert,
  itens,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    setTooltip({
      ...tooltip,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validationTooltip({ [e.target.name]: e.target.value });
  };
  const handleChangeCategoriasId = (e, value) => {
    e.preventDefault();
    setTooltip({
      ...tooltip,
      checklistItensId: value && value.id,
    });
    // console.log(produto.categoriasId.id);
    if (validateOnChange) validationTooltip({ checklistItensId: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validationTooltip()) {
      const ydata = [
        {
          descricao: tooltip.descricao,
          checklistItensId: tooltip.checklistItensId,
        },
      ];

      dispatch(AdicionarTooltipLimpeza(ydata))
        .then(() => handleClose('adicionar', e))
        .then(() => fetchTooltips())
        .then(() => handleOpenAlert('add'));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdicionar}
        onClose={(e) => handleClose('adicionar', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdicionar}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Adicionar Produto
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode adicionar aqui um produto onde irão pertencer as suas{' '}
                  <Link to="/admin/categorias" style={{ color: 'black' }}>
                    <b>categorias</b>
                  </Link>{' '}
                  relacionadas.
                </p>
              </div>

              <div>
                <TextField
                  id="Descricao"
                  name="descricao"
                  label="Descrição"
                  // variant='outlined'
                  // color='primary'
                  // value={categoria.descricao}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="nome"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsTooltip.descricao && {
                    error: true,
                    helperText: errorsTooltip.descricao,
                  })}
                />
              </div>
              <div>
                <Autocomplete
                  id="categoriasId"
                  name="categoriasId"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={itens}
                  getOptionLabel={(option) => `${option.descricao}` || ''}
                  // value={enc}
                  getOptionSelected={(option, value) => {
                    if (!value.id) return true;

                    return value.id === option.id;
                  }}
                  filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                  onChange={(e, value) => handleChangeCategoriasId(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Item"
                      // placeholder='Categoria'
                      // variant='outlined'
                      {...(errorsTooltip.checklistItensId && {
                        error: true,
                        helperText: errorsTooltip.checklistItensId,
                      })}
                    />
                  )}
                />
              </div>

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('add', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
