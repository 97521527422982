import axios from 'axios';
import BASE_URL from '../../BASE_URL';

// New Actions for the app

export const SignIn = (ydata, history, handleOpenAlert) => async (dispatch) => {
  console.log(ydata);
  await axios
    .post(`${BASE_URL}/backend/user/login`, { ydata })
    .then(({ data }) => {
      console.log(data);
      if (data.token) {
        dispatch({ type: 'SIGN_IN', payload: data });
        localStorage.setItem('token', data.token);
        history.push('/app/reservas');
      }
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        if (err.response.status === 403) {
          handleOpenAlert('yellow');
        }
      } else {
        handleOpenAlert('red');
      }
    });
};

export default SignIn;
