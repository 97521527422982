import Instance from '../../../../Axios';

// New Actions for the app

const GetMenusReserva = (reservaInfo) => async (dispatch) => {
  const apartamentoId = reservaInfo && reservaInfo.reserva && reservaInfo.reserva.apartamentos_id;
  await Instance()
    .get(`/backend/reservas/menus/${apartamentoId}`)
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'GET_MENUS_RESERVA', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetMenusReserva;
