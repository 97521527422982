import Instance from '../../../Axios';

// New Actions for the app

const GetUnidade = (id) => async (dispatch) => {
  console.log('id', id);
  await Instance()
    .get(`/backend/unidades/${id}`)
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'GET_UNIDADE', payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetUnidade;
