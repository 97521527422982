/* eslint-disable eqeqeq */
import React from 'react';
//PACKAGES

import { ThemeProvider, createTheme } from '@material-ui/core/styles';

//IMAGES
// import Logo from '../../images/logo_valleybeach_black.png';

//COMPONENTS
// import SignIn from '../../../utils/redux/actions/authAction';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//       width: '25ch',
//     },
//   },
// }));
const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Dashboard = () => {
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <div>Hello from Dashboard!</div>
    </ThemeProvider>
  );
};

export default Dashboard;
