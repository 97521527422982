/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

// PACKAGES
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

// ACTIONS

import AdicionarUtilizador from '../../../../utils/redux/actions/utilizadores/adicionarUtilizadorActions';
import GetPerfis from '../../../../utils/redux/actions/utilizadores/getPerfisAction';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalAdicionar = ({
  openAdicionar,
  handleClose,
  utilizador,
  setUtilizador,
  validateOnChange = true,
  validationUtilizador,
  errorsUtilizador = null,
  fetchUtilizadores,
  handleOpenAlert,
}) => {
  const classes = useStyles();

  // const parceiros = useSelector((state) => state.parceiros.parceiros);

  const dispatch = useDispatch();

  const perfis = useSelector((state) => state.utilizadores.perfis);

  const fetchPerfis = () => {
    dispatch(GetPerfis());
  };

  useEffect(() => fetchPerfis(), []);

  const handleChange = (e) => {
    e.preventDefault();
    setUtilizador({
      ...utilizador,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validationUtilizador({ [e.target.name]: e.target.value });
  };
  const handleChangePerfisId = (e, value) => {
    e.preventDefault();

    setUtilizador({
      ...utilizador,
      perfisId: value && value.id,
    });
    if (validateOnChange) validationUtilizador({ perfisId: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validationUtilizador()) {
      const ydata = [
        {
          nome: utilizador.nome,
          email: utilizador.email,
          perfil: utilizador.perfisId,
        },
      ];

      dispatch(AdicionarUtilizador(ydata))
        .then(() => handleClose('adicionar', e))
        .then(() => fetchUtilizadores())
        .then(() => handleOpenAlert('add'));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdicionar}
        onClose={(e) => handleClose('adicionar', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdicionar}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Adicionar Utilizador
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode adicionar aqui um utilizador onde pode associar o perfil associado.
                </p>
              </div>

              <div>
                <TextField
                  id="Nome"
                  name="nome"
                  label="Nome"
                  // variant='outlined'
                  // color='primary'
                  // value={categoria.descricao}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="nome"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsUtilizador.nome && {
                    error: true,
                    helperText: errorsUtilizador.nome,
                  })}
                />
              </div>
              <div>
                <TextField
                  id="Email"
                  name="email"
                  label="Email"
                  // variant='outlined'
                  // color='primary'
                  // value={categoria.descricao}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="email"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsUtilizador.email && {
                    error: true,
                    helperText: errorsUtilizador.email,
                  })}
                />
              </div>
              <div>
                <Autocomplete
                  id="perfisId"
                  name="perfisId"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={perfis}
                  getOptionLabel={(option) => `${option.descricao}` || ''}
                  // value={enc}
                  getOptionSelected={(option, value) => {
                    if (!value.id) return true;

                    return value.id === option.id;
                  }}
                  filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                  onChange={(e, value) => handleChangePerfisId(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Perfil"
                      placeholder="Perfil"
                      // variant='outlined'
                      {...(errorsUtilizador.perfisId && {
                        error: true,
                        helperText: errorsUtilizador.perfisId,
                      })}
                    />
                  )}
                />
              </div>

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('add', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
