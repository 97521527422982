import Instance from '../../../../Axios';

const ReenviarEmailPeqAlmocos = (encomenda) => async (dispatch) => {
  const dados = encomenda;
  await Instance()
    .post('/backend/encomendas/reenviarEmail', { ydata: dados })
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'POST_REEINVIAR_EMAIL_PEQ_ALMOCOS', payload: data });
      //   show('success', 'Foram associados novos funcionários á obra');
    })
    .catch((err) => {
      // eslint-disable-next-line eqeqeq
      if (err.response.status != 200) {
        // show('warning', 'Preencha todos os campos');
      }
    });
};

export default ReenviarEmailPeqAlmocos;
