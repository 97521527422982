/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

//PACKAGES
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { ThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import MUIDataTable from 'mui-datatables';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { AiOutlineInfoCircle } from 'react-icons/ai';

//COMPONENTS
import ModalInfo from './components/ModalInfo/modalInfo';

//ACTIONS
import GetHistorico from '../../../../utils/redux/actions/reservas/historico/getReservasHistoricoAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(blue[600]),
    backgroundColor: blue[600],
    '&:hover': {
      backgroundColor: blue[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: blue[600],
      },
    },
  },
}));

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const HistoricoReservas = () => {
  const classes = useStyles();
  const [responsive] = useState('vertical');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openInfo, setOpenInfo] = useState(false);
  const [reserva, setReserva] = useState({});

  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const fetchHistorico = (id, e) => {
    dispatch(GetHistorico());
  };

  useEffect(() => fetchHistorico(), []);

  const historicoReservas = useSelector((state) => state.reservas.historico);

  const handleOpen = (btn, e, id, tableMeta) => {
    if (btn == 'info') {
      const formatedCheckIn = format(new Date(tableMeta.rowData[2]), 'yyyy-MM-dd');
      const formatedCheckOut = format(new Date(tableMeta.rowData[3]), 'yyyy-MM-dd');
      e.preventDefault();

      setReserva({
        id: tableMeta.rowData[0],
        apartamentoNome: tableMeta.rowData[1],
        checkIn: formatedCheckIn,
        checkOut: formatedCheckOut,
        apartamentoFoto: tableMeta.rowData[6],
        clienteCodPostal: tableMeta.rowData[8],
        clienteContato: tableMeta.rowData[9],
        clienteEmail: tableMeta.rowData[10],
        clienteNome: tableMeta.rowData[11],
        numPessoasAdultas: tableMeta.rowData[12],
        numPessoasCriancas: tableMeta.rowData[13],
        reservaBookingID: tableMeta.rowData[14],
        reservaSiteID: tableMeta.rowData[15],
        encomendas: tableMeta.rowData[16],
      });
      setOpenInfo(true);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();

    if (btn == 'info') {
      setOpenInfo(false);
    }
  };

  const columns = [
    // 0
    {
      name: 'id',
      label: 'id',
      options: {
        display: false,
      },
    },
    // 1
    {
      name: 'apartamentoNome',
      label: 'Apartamento',
    },
    // 2
    {
      name: 'checkin',
      label: 'Check In',
      options: {
        filter: true,
        sort: true,
        empty: true,

        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          const formatedCheckIn = format(new Date(tableMeta.rowData[2]), 'dd-MM-yyyy');

          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <p>{formatedCheckIn}</p>
            </div>
          );
        },
      },
    },
    // 3
    {
      name: 'checkout',
      label: 'Check Out',
      options: {
        filter: true,
        sort: true,
        empty: true,

        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          const formatedCheckOut = format(new Date(tableMeta.rowData[3]), 'dd-MM-yyyy');

          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <p>{formatedCheckOut}</p>
            </div>
          );
        },
      },
    },
    // 4
    {
      name: 'Reserva Id',
      label: 'Reserva Id',
      options: {
        filter: true,
        sort: false,
        empty: true,

        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          let r = historicoReservas.filter((reserva) => reserva.id === tableMeta.rowData[0]);

          r = r.length > 0 ? r[0] : r;

          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <p key={r.id}>
                {r.reservaBookingID == null || r.reservaBookingID == ''
                  ? r.reservaSiteID
                  : r.reservaBookingID}
              </p>
            </div>
          );
        },
      },
    },
    // 5
    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="table-btns-categorias">
              <Button
                onClick={(e) => handleOpen('info', e, value, tableMeta)}
                className={classes.btnEditar}
                style={{ marginRight: '20px' }}
              >
                <AiOutlineInfoCircle style={{ color: 'white' }} size="1.6em" />
              </Button>
            </div>
          );
        },
      },
    },
    // 6
    {
      name: 'apartamentoFoto',
      label: 'Fotografia',
      options: {
        display: false,
      },
    },
    // 7
    {
      name: 'apartamentos_id',
      label: 'Apartamento Id',
      options: {
        display: false,
      },
    },
    // 8
    {
      name: 'clienteCodPostal',
      label: 'Código Postal',
      options: {
        display: false,
      },
    },
    // 9
    {
      name: 'clienteContato',
      label: 'Contacto',
      options: {
        display: false,
      },
    },
    // 10
    {
      name: 'clienteEmail',
      label: 'Email',
      options: {
        display: false,
      },
    },
    // 11
    {
      name: 'clienteNome',
      label: 'Nome',
      options: {
        display: false,
      },
    },
    // 12
    {
      name: 'numPessoasAdultas',
      label: 'Nº de Adultos',
      options: {
        display: false,
      },
    },
    // 12
    {
      name: 'numPessoasCriancas',
      label: 'Nº de Crianças',
      options: {
        display: false,
      },
    },
    // 14
    {
      name: 'reservaBookingID',
      label: 'Booking Id',
      options: {
        display: false,
      },
    },
    // 15
    {
      name: 'reservaSiteID',
      label: 'Site Id',
      options: {
        display: false,
      },
    },
    // 16
    {
      name: 'encomendas',
      label: 'Encomendas',
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <>
        <ModalInfo openInfo={openInfo} handleClose={handleClose} reserva={reserva} />
        <div className="topReserva" style={{ marginBottom: '40px' }}>
          <div className="backBtnPosition">
            <Link to={`/app/reservas`} className="link-btn">
              <Button variant="outlined" className="back-btn">
                <ChevronLeftIcon />
              </Button>
            </Link>
          </div>
          <div className="reservaTitulo">
            <h2>
              {/* Reserva */}
              {/* {reservaInfo && reservaInfo.reserva && reservaInfo.reserva.id} */}
            </h2>
          </div>
        </div>

        <MUIDataTable
          title="Histórico"
          description="Nada para mostrar"
          data={historicoReservas}
          columns={columns}
          options={options}
        />

        {/* </MuiThemeProvider> */}
      </>
    </ThemeProvider>
  );
};

export default HistoricoReservas;
