import Instance from '../../../../Axios';

// New Actions for the app

const GetTooltipLimpeza = (id) => async (dispatch) => {
  console.log('id', id);
  await Instance()
    .get(`/backend/checklist/tooltip/${id}`)
    .then(({ data }) => {
      dispatch({ type: 'GET_TOOLTIP_LIMPEZA', payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetTooltipLimpeza;
