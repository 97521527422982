/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import '../parceiros.css';

//PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

//ACTIONS

import EditarMenu from '../../../../utils/redux/actions/menus/editarMenu';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalEdit = ({
  openEdit,
  handleClose,
  menu,
  setMenu,
  validateOnChange = true,
  validationMenu,
  errorsMenu = null,
  fetchMenus,
  menuId,
  handleOpenAlert,
  menuNome,
  menuInfo,
  parceiroSelecionado,
  setParceiroSelecionado,
  parceiros,
  // LINGUAGENS
  empty,
  setEmpty,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const selectUnidade = () => {
    if (parceiros.length > 0 && menuId) {
      const ParceiroAssociado = parceiros.find((parc) => parc.id === menu.parceirosId);

      setParceiroSelecionado(
        ParceiroAssociado || {
          id: 0,
          nome: '',
        },
      );
    } else {
      setParceiroSelecionado({
        id: 0,
        nome: '',
      });
    }
  };

  useEffect(() => selectUnidade(), [menu]);

  // const handleChange = (e) => {
  //   e.preventDefault();

  //   setMenu({
  //     ...menu,
  //     [e.target.name]: e.target.value,
  //   });
  //   if (validateOnChange) validationMenu({ [e.target.name]: e.target.value });
  // };

  const handleChange = (e, value) => {
    e.preventDefault();
    const catIndex =
      menu.descricao && menu.descricao.findIndex((c) => c.linguasId === value.linguasId);

    menu.descricao[catIndex].descricao = e.target.value;

    dispatch({ type: 'GET_MENU', payload: menu });

    const allEmpty = menu.descricao.map((l) => l.descricao.length > 0).find((a) => a == true);

    if (allEmpty != true) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
  };

  const handleChangeParceirosId = (e, value) => {
    e.preventDefault();
    setParceiroSelecionado(
      value || {
        id: 0,
        nome: '',
      },
    );
    setMenu({
      ...menu,
      parceirosId: (value && value.id) || 0,
    });
    if (validateOnChange) validationMenu({ parceirosId: value });
  };

  useEffect(() => {
    setMenu({
      ...menuInfo,
      descricao: menuInfo.descricao,
      parceirosId: menuInfo.parceiros_id,
    });
  }, [menuInfo, setMenu]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   if (validationMenu()) {
  //     const ydata = [
  //       {
  //         descricao: menu.descricao,
  //         parceirosId: menu.parceirosId,
  //       },
  //     ];

  //     dispatch(EditarMenu(ydata, menuId))
  //       .then(() => handleClose('edit', e))
  //       .then(() => fetchMenus())
  //       .then(() => handleOpenAlert('edit'));
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    const allEmpty = menu.descricao.map((l) => l.descricao.length > 0).find((a) => a == true);

    if (empty == true || allEmpty == true) {
      if (validationMenu()) {
        const ydata = [
          {
            descricao: menu.descricao,
            parceirosId: menu.parceirosId,
          },
        ];

        dispatch(EditarMenu(ydata, menuId))
          .then(() => handleClose('edit', e))
          .then(() => fetchMenus())
          .then(() => handleOpenAlert('edit'));
      }
    } else {
      setEmpty(false);
      validationMenu();
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        onClose={(e) => handleClose('edit', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Editar Menu
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode editar este menu<b> {menuNome}</b>, que terá o devido parceiro associado.
                </p>
              </div>

              {/* <div>
                <TextField
                  id="Descricao"
                  name="descricao"
                  label="Descrição"
                  // variant='outlined'
                  // color='primary'
                  value={menu.descricao || ''}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="nome"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsMenu.descricao && {
                    error: true,
                    helperText: errorsMenu.descricao,
                  })}
                />
              </div> */}

              <div>
                {menu &&
                  menu.descricao &&
                  menu.descricao.length > 0 &&
                  menu.descricao.map((men) => (
                    <div key={men.linguasId}>
                      <TextField
                        // id={ling.id}
                        name="descricao"
                        label={men.linguasDescricao}
                        onChange={(e) => handleChange(e, men)}
                        value={men.descricao}
                        fullWidth
                        autoComplete="off"
                        className="nome"
                        {...(empty == false && {
                          error: true,
                        })}
                      />
                    </div>
                  ))}
              </div>

              <div>
                <Autocomplete
                  id="parceirosId"
                  name="parceirosId"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={parceiros}
                  getOptionLabel={(option) => (option.nome ? `${option.nome} ` : '')}
                  value={parceiroSelecionado || ''}
                  getOptionSelected={(option, value) => {
                    return value.id === option.id;
                  }}
                  // eslint-disable-next-line no-unused-vars
                  filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                  onChange={(e, value) => handleChangeParceirosId(e, value)}
                  onInputChange={(event, value, reason) => {
                    if (reason == 'clear') {
                      setMenu({
                        ...menu,
                        parceirosId: 0,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parceiro"
                      placeholder="Parceiro"
                      // variant='outlined'
                      {...(errorsMenu.parceirosId && {
                        error: true,
                        helperText: errorsMenu.parceirosId,
                      })}
                    />
                  )}
                />
              </div>
              {empty == false && (
                <div style={{ color: '#f44336', marginTop: '15px', fontSize: '12px' }}>
                  {' '}
                  *Preencher pelo menos um campo
                </div>
              )}
              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('edit', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalEdit;
