/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

//COMPONENTS
import DateRangePicker from './DateRangePicker';

// PACKAGES
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
// datePicker
import { pt } from 'date-fns/locale';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// ACTIONS
import AdicionarReserva from '../../../../utils/redux/actions/reservas/adicionarReservaAction';

import GetApartamentoReservas from '../../../../utils/redux/actions/reservas/getApartamentoReservasActions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '80vh',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalAdicionar = ({
  openAdicionar,
  handleClose,
  reserva,
  setReserva,
  validateOnChange = true,
  validationReserva,
  errorsReserva = null,
  handleOpenAlert,
  fetchReservas,
  linguagens,
}) => {
  const classes = useStyles();

  const [selectedDateCheckIn, setSelectedDateCheckIn] = useState();
  const [selectedDateCheckOut, setSelectedDateCheckOut] = useState();

  // const parceiros = useSelector((state) => state.parceiros.parceiros);

  const apartamentos = useSelector((state) => state.apartamentos.apartamentos);
  const apartamentoReservas = useSelector((state) => state.reservas.apartamentoReservas);

  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const handleDateChangeChecks = (date) => {
    // setReserva({
    //   ...reserva,
    //   checkIn: selectedDateCheckIn,
    //   checkOut: selectedDateCheckOut,
    // });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setReserva({
      ...reserva,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validationReserva({ [e.target.name]: e.target.value });
  };
  // eslint-disable-next-line no-unused-vars
  const handleChangeNumbers = (e, value) => {
    e.preventDefault();
    let texto = e.target.value;

    texto = texto.replace(/\D/g, '');

    // console.log(e.target.name, e.target.value, texto);
    // texto = texto.replace(/(\d{4})(?=\d)/g, '');
    setReserva({
      ...reserva,
      [e.target.name]: parseInt(texto),
    });
    if (validateOnChange) validationReserva({ [e.target.name]: texto });
  };
  // const handleChangeCodPostal = (e) => {
  //   e.preventDefault();
  //   let texto = e.target.value;

  //   texto = texto.replace(/\D/g, '');
  //   texto = texto.replace(/(\d{4})(?=\d)/g, '$1-');

  //   setReserva({
  //     ...reserva,
  //     clienteCodPostal: texto,
  //   });

  //   if (validateOnChange) validationReserva({ clienteCodPostal: e.target.value });
  // };
  const handleChangeApartamentosId = (e, value) => {
    e.preventDefault();

    setReserva({
      ...reserva,
      apartamentosId: value && value.id,
      checkIn: '',
      checkOut: '',
    });

    dispatch(GetApartamentoReservas(value && value.id));
    if (validateOnChange) validationReserva({ apartamentosId: value });
  };
  const handleLinguagem = (e, value) => {
    e.preventDefault();

    setReserva({
      ...reserva,
      linguagem: value && value.code,
    });
    // console.log(reserva);

    if (validateOnChange) validationReserva({ linguagem: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formatedCheckIn =
      selectedDateCheckIn && format(new Date(selectedDateCheckIn), 'yyyy-MM-dd 14:00:00');

    const formatedCheckOut =
      selectedDateCheckOut && format(new Date(selectedDateCheckOut), 'yyyy-MM-dd 11:00:00');

    if (validationReserva()) {
      const ydata = [
        {
          apartamentosId: reserva.apartamentosId,
          checkin: formatedCheckIn,
          checkout: formatedCheckOut,
          numPessoasAdultas: reserva.numAdultos,
          numPessoasCriancas: reserva.numCriancas,
          reservaBookingID: reserva.reservaBookingId,
          reservaSiteID: reserva.reservaSiteId,
          clienteNome: reserva.clienteNome,
          clienteMorada: reserva.clienteMorada,
          clienteCodPostal: reserva.clienteCodPostal,
          clienteLocalidade: reserva.clienteLocalidade,
          clienteEmail: reserva.clienteEmail,
          clienteContato: reserva.clienteContacto,
          linguaCode: reserva.linguagem,
        },
      ];

      dispatch(AdicionarReserva(ydata))
        .then(() => handleClose('adicionar', e))
        .then(() => fetchReservas())
        .then(() => handleOpenAlert('add'));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdicionar}
        onClose={(e) => handleClose('adicionar', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdicionar}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Adicionar Reserva
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode adicionar aqui uma reserva, associado a um apartamento disponivel nas{' '}
                  <Link to="/admin/unidades" style={{ color: 'black' }}>
                    <b>unidades</b>
                  </Link>
                  .
                </p>
              </div>

              <div className="form-add-apartamento">
                <div>
                  <Autocomplete
                    id="apartamentosId"
                    name="apartamentosId"
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={apartamentos}
                    getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                    // value={enc}
                    getOptionSelected={(option, value) => {
                      if (!value.id) return true;

                      return value.id === option.id;
                    }}
                    filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                    onChange={(e, value) => handleChangeApartamentosId(e, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Apartamento"
                        placeholder="Apartamento"
                        // variant='outlined'
                        {...(errorsReserva.apartamentosId && {
                          error: true,
                          helperText: errorsReserva.apartamentosId,
                        })}
                      />
                    )}
                  />
                </div>

                <div className="andar-tipo-apar-form">
                  <TextField
                    id="numAdultos"
                    name="numAdultos"
                    label="Nº Adultos"
                    value={reserva.numAdultos || ''}
                    InputProps={{
                      inputProps: { min: '0', max: '6', step: '1' },
                    }}
                    type="number"
                    onChange={(e) => handleChangeNumbers(e)}
                    fullWidth
                    autoComplete="off"
                    className="ass-categoria-quantidade m-r-zero-mobile"
                    style={{ marginBottom: 8, marginRight: '4%', width: '45%' }}
                    {...(errorsReserva.numAdultos && {
                      error: true,
                      helperText: errorsReserva.numAdultos,
                    })}
                  />
                  <TextField
                    id="numCriancas"
                    name="numCriancas"
                    label="Nº Crianças"
                    value={reserva.numCriancas || ''}
                    InputProps={{
                      inputProps: { min: '0', max: '6', step: '1' },
                    }}
                    type="number"
                    onChange={(e) => handleChangeNumbers(e)}
                    fullWidth
                    autoComplete="off"
                    className="ass-categoria-quantidade m-r-zero-mobile"
                    style={{ marginBottom: 8, marginRight: '4%', width: '45%' }}
                    {...(errorsReserva.numCriancas && {
                      error: true,
                      helperText: errorsReserva.numCriancas,
                    })}
                  />

                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                    <Grid
                      container
                      // justify='center'
                      // style={{ marginBottom: '50px' }}
                    >
                      <DateRangePicker
                        disableToolbar
                        fullWidth
                        // value={moment(selectedDateCheckIn).add(1, 'days')}
                        onChange={handleDateChangeChecks}
                        variant="inline"
                        label="CheckIn - CheckOut"
                        formatString="dd/MM/yyyy"
                        setSelectedDateCheckIn={setSelectedDateCheckIn}
                        setSelectedDateCheckOut={setSelectedDateCheckOut}
                        // margin='normal'
                        reserva={reserva}
                        setReserva={setReserva}
                        disabled={reserva.apartamentosId ? false : true}
                        apartamentoReservas={apartamentoReservas}
                        shouldDisableDate={(date) => {
                          // true for all dates will crashe entire app.
                          //
                          // On this step I mocked case when dynamic provided data won't have
                          // any disabled date

                          // const dates = [
                          //   '2021-07-20',
                          //   '2021-07-29',
                          //   '2021-08-01',
                          //   '2021-08-10',
                          // ];

                          // return dates.includes(format(date, 'yyyy-MM-dd'));
                          return apartamentoReservas.includes(format(date, 'yyyy-MM-dd'));
                        }}
                        {...(errorsReserva.checkIn && {
                          error: true,
                          helperText: errorsReserva.checkIn,
                        })}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="andar-tipo-apar-form">
                <TextField
                  id="reservaBookingId"
                  name="reservaBookingId"
                  label="Reserva Booking Id"
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="ass-categoria-quantidade m-r-zero-mobile"
                  style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsReserva.reservaBookingId && {
                    error: true,
                    helperText: errorsReserva.reservaBookingId,
                  })}
                />
                <TextField
                  id="reservaSiteId"
                  name="reservaSiteId"
                  label="Reserva Site Id"
                  value={reserva.reservaSiteId || ''}
                  onChange={handleChangeNumbers}
                  fullWidth
                  autoComplete="off"
                  className="ass-categoria-quantidade "
                  style={{ marginBottom: 8 }}
                  {...(errorsReserva.reservaSiteId && {
                    error: true,
                    helperText: errorsReserva.reservaSiteId,
                  })}
                />
              </div>
              <div>
                <TextField
                  id="clienteNome"
                  name="clienteNome"
                  label="Nome completo"
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="ass-categoria-quantidade"
                  style={{ marginBottom: 8 }}
                  {...(errorsReserva.clienteNome && {
                    error: true,
                    helperText: errorsReserva.clienteNome,
                  })}
                />
              </div>
              <div className="andar-tipo-apar-form">
                <TextField
                  id="clienteEmail"
                  name="clienteEmail"
                  label="Email"
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="ass-categoria-quantidade m-r-zero-mobile"
                  style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsReserva.clienteEmail && {
                    error: true,
                    helperText: errorsReserva.clienteEmail,
                  })}
                />
              </div>
              <div className="andar-tipo-apar-form">
                <TextField
                  id="clienteContacto"
                  name="clienteContacto"
                  label="Contacto"
                  value={reserva.clienteContacto || ''}
                  onChange={handleChangeNumbers}
                  fullWidth
                  autoComplete="off"
                  className="ass-categoria-quantidade width-100"
                  style={{ marginBottom: 8, marginRight: '4%', width: '40%' }}
                  {...(errorsReserva.clienteContacto && {
                    error: true,
                    helperText: errorsReserva.clienteContacto,
                  })}
                />
                <Autocomplete
                  id="linguagem"
                  name="linguagem"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  className="ass-categoria-quantidade width-100"
                  options={linguagens || []}
                  getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                  // // value={enc}
                  getOptionSelected={(option, value) => {
                    if (!value.id) return true;

                    return value.id === option.id;
                  }}
                  // eslint-disable-next-line no-unused-vars
                  filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                  onChange={(e, value) => handleLinguagem(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Linguagem"
                      placeholder="Linguagem"
                      // variant='outlined'
                      {...(errorsReserva.limpeza && {
                        error: true,
                        helperText: errorsReserva.limpeza,
                      })}
                    />
                  )}
                />
              </div>

              {/* <div>
                <TextField
                  id="clienteMorada"
                  name="clienteMorada"
                  label="Morada"
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="ass-categoria-quantidade"
                  style={{ marginBottom: 8 }}
                  // {...(errorsReserva.clienteMorada && {
                  //   error: true,
                  //   helperText: errorsReserva.clienteMorada,
                  // })}
                />
              </div> */}
              {/* <div className="andar-tipo-apar-form">
                <TextField
                  id="clienteCodPostal"
                  name="clienteCodPostal"
                  label="Código Postal"
                  value={reserva.clienteCodPostal || ''}
                  onChange={handleChangeCodPostal}
                  inputProps={{
                    maxLength: 8,
                  }}
                  fullWidth
                  autoComplete="off"
                  className="ass-categoria-quantidade m-r-zero-mobile"
                  style={{ marginBottom: 8, marginRight: '4%' }}
                  // {...(errorsReserva.clienteCodPostal && {
                  //   error: true,
                  //   helperText: errorsReserva.clienteCodPostal,
                  // })}
                />
                <TextField
                  id="clienteLocalidade"
                  name="clienteLocalidade"
                  label="Localidade"
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="ass-categoria-quantidade"
                  style={{ marginBottom: 8 }}
                  // {...(errorsReserva.clienteLocalidade && {
                  //   error: true,
                  //   helperText: errorsReserva.clienteLocalidade,
                  // })}
                />
              </div> */}

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('add', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
