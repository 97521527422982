import React from 'react';
import { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const GraficoResumo = ({ dadosGraficos }) => {
  // console.log(dadosGraficos);

  const [state, setState] = useState({
    series: [
      // {
      //   name: dimensions && dimensions.width < 1050 ? 'Quantidade Menu' : ['Quantidade ', 'Menu'],
      //   type: 'column',
      //   data: [440, 505],
      // },
      // {
      //   name: 'Valor Total',
      //   type: 'line',
      //   data: [23, 42],
      // },
    ],
    options: {
      chart: {
        width: '100%',
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easein',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },

      stroke: {
        width: [0, 4],
      },
      title: {
        // text: 'Resumo',
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: ['Menu Adulto', 'Menu Criança'],
      xaxis: {
        // type: 'datetime',
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      responsive: [
        {
          breakpoint: 950,
          options: {
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
              },
            },
          },
        },
      },
      yaxis: [
        {
          title: {
            text: 'Quantidade Menus',
          },
        },
        {
          opposite: true,
          title: {
            text: 'Valor Total',
          },
        },
      ],
    },
  });

  useEffect(() => {
    dadosGraficos && setState({ ...state, series: dadosGraficos });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosGraficos]);

  return (
    <div id="chart">
      {dadosGraficos && dadosGraficos.length > 0 && (
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="line"
          height="300px"
          // width="600px"
        />
      )}
    </div>
  );
};

export default GraficoResumo;
