import { globalStateState } from '../initialState/global';

const GlobalReducer = (state = globalStateState, { type, payload }) => {
  switch (type) {
    case 'GET_LINGUAGENS':
      return {
        ...state,
        linguagens: payload,
      };
    default:
      return state;
  }
};

export default GlobalReducer;
