import { reservasInitialState } from '../initialState/reservas';

const reservasReducer = (state = reservasInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_RESERVAS':
      return {
        ...state,
        reservas: payload,
      };
    case 'GET_RESERVA':
      return {
        ...state,
        reserva: payload,
      };
    case 'POST_RESERVA':
      return {
        ...state,
        novaReserva: payload,
      };
    case 'GET_APARTAMENTO_RESERVAS':
      return {
        ...state,
        apartamentoReservas: payload,
      };
    case 'DELETE_RESERVA':
      return {
        ...state,
        reservaRemovida: payload,
      };
    case 'PUT_RESERVA':
      return {
        ...state,
        reserva: payload,
      };
    case 'POST_ENCOMENDA':
      return {
        ...state,
        novaEncomenda: payload,
      };
    case 'POST_APAGAR_ENCOMENDA':
      return {
        ...state,
        encomendaRemovida: payload,
      };
    case 'POST_REEINVIAR_EMAIL_PEQ_ALMOCOS':
      return {
        ...state,
        reenviarEmail: payload,
      };
    case 'GET_RESERVAS_HISTORICO':
      return {
        ...state,
        historico: payload,
      };
    case 'CLEAR_DATA_RESERVA':
      return {
        ...state,
        reserva: [],
      };
    case 'GET_MENUS_RESERVA':
      return {
        ...state,
        menus: payload,
      };
    case 'CLEAR_DATA_MENU_RESERVA':
      return {
        ...state,
        menus: [],
      };

    default:
      return state;
  }
};

export default reservasReducer;
