/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import '../parceiros.css';

//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

//ACTIONS
import EditarTooltipLimpeza from '../../../../../utils/redux/actions/limpeza/tooltips/editarTooltipLimpezaAction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalEdit = ({
  openEdit,
  handleClose,
  tooltip,
  setTooltip,
  validateOnChange = true,
  validationTooltip,
  errorsTooltip = null,
  fetchTooltips,
  tooltipId,
  handleOpenAlert,
  tooltipNome,
  tooltipInfo,
  itemSelecionado,
  setItemSelecionado,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const itens = useSelector((state) => state.limpeza.itensLimpeza);

  const selectItem = () => {
    if (itens.length > 0 && tooltip.checklistItensId) {
      const ItemAssociado = itens.find((item) => item.id === tooltip.checklistItensId);

      setItemSelecionado(
        ItemAssociado || {
          id: 0,
          descricao: '',
        },
      );
    } else {
      setItemSelecionado({ id: 0, descricao: '' });
    }
  };

  useEffect(() => selectItem(), [tooltip, tooltipInfo, itens]);

  const handleChange = (e) => {
    e.preventDefault();

    setTooltip({
      ...tooltip,
      [e.target.name]: e.target.value,
    });

    if (validateOnChange) validationTooltip({ [e.target.name]: e.target.value });
  };

  const handleChangeCategoriasId = (e, value) => {
    e.preventDefault();
    setItemSelecionado(
      value || {
        id: 0,
        descricao: '',
      },
    );
    setTooltip({
      ...tooltip,
      checklistItensId: (value && value.id) || 0,
    });
    // console.log(produto.categoriasId.id);
    if (validateOnChange) validationTooltip({ checklistItensId: value });
  };

  useEffect(() => {
    setTooltip({
      descricao: tooltipInfo.descricao,
      checklistItensId: tooltipInfo.checklist_itens_id,
    });
  }, [tooltipInfo, setTooltip]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validationTooltip()) {
      const ydata = [
        {
          descricao: tooltip.descricao,
          checklistItensId: tooltip.checklistItensId,
        },
      ];

      dispatch(EditarTooltipLimpeza(ydata, tooltipId))
        .then(() => handleClose('edit', e))
        .then(() => fetchTooltips())
        .then(() => handleOpenAlert('edit'));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        onClose={(e) => handleClose('edit', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Editar Produto
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode editar a tooltip <b> {tooltipNome}</b>, que irá ter os seus itens associados.
                </p>
              </div>

              <div>
                <TextField
                  id="Descricao"
                  name="descricao"
                  label="Descrição"
                  // variant='outlined'
                  // color='primary'
                  value={tooltip.descricao || ''}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="nome"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsTooltip.descricao && {
                    error: true,
                    helperText: errorsTooltip.descricao,
                  })}
                />
              </div>
              <div>
                <Autocomplete
                  id="checklistItensId"
                  name="checklistItensId"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={itens}
                  getOptionLabel={(option) => (option.descricao ? `${option.descricao}` : '')}
                  value={itemSelecionado || ''}
                  filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                  getOptionSelected={(option, value) => {
                    return value.id === option.id;
                  }}
                  // style={{ width: '100%' }}
                  onChange={(e, value) => handleChangeCategoriasId(e, value)}
                  onInputChange={(event, value, reason) => {
                    if (reason == 'clear') {
                      setTooltip({
                        ...tooltip,
                        checklistItensId: 0,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Item"
                      // placeholder='Categoria'
                      // variant='outlined'
                      {...(errorsTooltip.checklistItensId && {
                        error: true,
                        helperText: errorsTooltip.checklistItensId,
                      })}
                    />
                  )}
                />
              </div>

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('edit', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalEdit;
