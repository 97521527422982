import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// REDUCERS
import {
  authReducer,
  parceirosReducer,
  categoriasReducer,
  produtosReducer,
  unidadesReducer,
  apartamentosReducer,
  menusReducer,
  utilizadoresReducer,
  definirSenhaReducer,
  reservasReducer,
  peqAlmocosReducer,
  limpezaRecucer,
  relatoriosReducer,
  globalReducer,
} from './redux/reducers/index';

const reducer = combineReducers({
  user: authReducer,
  parceiros: parceirosReducer,
  categorias: categoriasReducer,
  produtos: produtosReducer,
  unidades: unidadesReducer,
  apartamentos: apartamentosReducer,
  menus: menusReducer,
  utilizadores: utilizadoresReducer,
  senha: definirSenhaReducer,
  reservas: reservasReducer,
  peqAlmocos: peqAlmocosReducer,
  limpeza: limpezaRecucer,
  relatorios: relatoriosReducer,
  global: globalReducer,
});

const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));

const persistor = persistStore(store);

export { store, persistor };
