import Instance from '../../../Axios';

// New Actions for the app

const GetMenuCategorias = (id) => async (dispatch) => {
  console.log('id', id);
  await Instance()
    .get(`/backend/menus/${id}/categorias`)
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'GET_MENU_CATEGORIAS', payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetMenuCategorias;
