/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

//COMPONENTS
import TabelaMenus from './tabelaMenus';
// PACKAGES
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

// ACTIONS
// import AdicionarEncomenda from '../../../../../../utils/redux/actions/reservas/reserva/adicionarEncomendaAction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    overflowY: 'scroll',
    maxWidth: '600px',
    width: '600px',
    margin: 15,
    maxHeight: '80vh',
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
    height: '50px',
  },
}));

const ModalAdicionar = ({ openInfo, handleClose, reserva, validateOnChange = true }) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openInfo}
        onClose={(e) => handleClose('info', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openInfo}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Informação da reserva
                </h2>
                {/* <p
                  id='transition-modal-description'
                  className='descricao-modal'
                >
                  Pode ver a reserva <b>{reserva.id}</b>, expirada aquando ao
                  checkOut de um cliente
                </p> */}
              </div>

              <div className="form-add-apartamento">
                <div className="infoReservaP" style={{ marginTop: '0px' }}>
                  <p>
                    <b> Id:</b> {reserva.id}
                  </p>
                  <p>
                    <b> Cliente:</b> {reserva.clienteNome}
                  </p>
                  <p>
                    <b> Check-In:</b> {reserva.checkIn}
                  </p>
                  <p>
                    <b> Check-Out: </b>
                    {reserva.checkOut}
                  </p>
                  <p>
                    <b> Contacto:</b> {reserva.clienteContato}
                  </p>
                  <p>
                    <b> Hóspedes Adultos:</b> {reserva.numPessoasAdultas}
                  </p>
                  <p>
                    <b> Hóspedes Crianças:</b> {reserva.numPessoasCriancas}
                  </p>
                  <p>
                    <b> Email: </b>
                    {reserva.clienteEmail}
                  </p>

                  {reserva && reserva.reservaBookingID != null && reserva.reservaBookingID != '' ? (
                    <p>
                      <b> Booking Id:</b> {reserva.reservaBookingID}
                    </p>
                  ) : (
                    <p>
                      <b> Site Id:</b> {reserva.reservaSiteID}
                    </p>
                  )}
                  {reserva && reserva.apartamentoFoto ? (
                    <img
                      src={reserva.apartamentoFoto}
                      alt="Foto do apartamento"
                      style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '5px',
                        marginTop: '15px',
                      }}
                    />
                  ) : null}
                </div>
              </div>

              <div>
                <TabelaMenus reserva={reserva} />
              </div>

              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnCancelar} onClick={(e) => handleClose('info', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
