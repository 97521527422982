/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';

//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { pt } from 'date-fns/locale';
import { green } from '@material-ui/core/colors';
import { format, addDays } from 'date-fns';
import MUIDataTable, { ExpandButton } from 'mui-datatables';
import SearchIcon from '@material-ui/icons/Search';

//ACTIONS

import GetAnaliseDeVendasRelatorios from '../../../../../utils/redux/actions/relatorios/analiseDeVendas/analiseDeVendasRelatoriosAction';
import GetAnaliseDeVendasPDF from '../../../../../utils/redux/actions/relatorios/analiseDeVendas/analiseDeVendasPDFAction';
import GraficoResumo from './graficoResumo';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '85%',
    marginBottom: '20px',
    margin: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  descricao: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  typo: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    height: '40px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  btnAdicionar: {
    minWidth: '100px',
    maxWidth: '120px',
    height: '40px',
    marginTop: '10px',
    // marginRight: '20px',
    // marginLeft: '20px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnAdicionarDisabled: {
    minWidth: '100px',
    color: theme.palette.getContrastText(green[100]),
    backgroundColor: green[100],
    '&:hover': {
      backgroundColor: green[100],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[100],
      },
    },
  },
}));

const FormSelectors = ({
  children,
  options,
  columns,
  analiseDeVendas,
  parceiros,
  selectedDateInicio,
  setSelectedDateInicio,
  selectedDateFim,
  setSelectedDateFim,
  parceiro,
  setParceiro,
  parceiroSelecionado,
  setParceiroSelecionado,
  dimensions,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dadosGraficos = useSelector((state) => state.relatorios.analiseDeVendasRelatorioResumo);
  // const dadosGraficos = [];

  const selectParceiros = () => {
    if (parceiros && parceiros.length > 0 && parceiro.id) {
      const ParceiroAssociado = parceiros.find((parc) => parc.id === parceiro.id);

      setParceiroSelecionado(
        ParceiroAssociado || {
          id: 0,
          nome: '',
        },
      );
    } else {
      setParceiroSelecionado({ id: 0, nome: '' });
    }
  };

  useEffect(() => selectParceiros(), [parceiro, parceiros]);
  useEffect(() => dispatch({ type: 'CLEAR_ANALISE_DE_VENDAS_RELATORIOS' }), []);
  useEffect(() => dispatch({ type: 'GET_ANALISE_DE_VENDAS_RELATORIOS_RESUMO' }), []);

  const handleDateChangeInicio = (date) => {
    setSelectedDateInicio(date);
    // fetchHistoricoMensal();
  };
  const handleDateChangeFim = (date) => {
    setSelectedDateFim(date);
    // fetchHistoricoMensal();
  };

  const handleChangeParceiroId = (e, value) => {
    e.preventDefault();
    setParceiro({
      id: value && value.id,
    });
    // console.log(produto.categoriasId.id);
    // if (validateOnChange) validationTooltip({ checklistItensId: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formatSelectedDateInicio = format(new Date(selectedDateInicio), 'yyyy-MM-dd');
    const formatSelectedDateFim = format(new Date(selectedDateFim), 'yyyy-MM-dd');

    const parceiroId = parceiro && parceiro.id;

    await dispatch(
      GetAnaliseDeVendasRelatorios(formatSelectedDateInicio, formatSelectedDateFim, parceiroId),
    ).then(() =>
      dispatch(GetAnaliseDeVendasPDF(formatSelectedDateInicio, formatSelectedDateFim, parceiroId)),
    );
  };

  const components = {
    ExpandButton: function (props) {
      if (props.dataIndex === (analiseDeVendas && analiseDeVendas.length - 1))
        return <div style={{ width: '24px' }} />;
      return <ExpandButton {...props} />;
    },
    // TableViewCol: function (props) {
    //   console.log(props);
    //   return <TableViewCol {...props} />;
    // },
  };

  let formSize = '100%';
  let inputSize = '25%';

  if (dadosGraficos && dadosGraficos.length > 0) {
    if (dimensions && dimensions.width >= 750 && dimensions && dimensions.width < 1050) {
      formSize = '100%';
      inputSize = '45%';
    } else if (dimensions && dimensions.width < 750) {
      formSize = '100%';
      inputSize = '100%';
    }
    if (dimensions && dimensions.width >= 1050) {
      formSize = '30%';
      inputSize = '100%';
    }
  } else {
    formSize = '100%';
    if (dimensions && dimensions.width > 1300) {
      inputSize = '20%';
    }
    if (dimensions && dimensions.width > 700 && dimensions && dimensions.width <= 1300) {
      inputSize = '45%';
    } else if (dimensions && dimensions.width <= 700) {
      inputSize = '100%';
    }
  }

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <div className="reservaCards">
        <div className="detalhesReservaCard">
          <div className="detalhesReservaDrops">
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
              <h2> Análise de vendas</h2>
            </div>

            <ThemeProvider theme={defaultMaterialTheme}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      // justifyContent: 'center',
                      marginLeft: '40px',
                      marginRight: '40px',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        // width: dimensions && dimensions.width < 1050 ? '100%' : formSize
                        width: formSize,
                      }}
                    >
                      <DatePicker
                        variant="inline"
                        maxDate={new Date()}
                        label="Data de inicio"
                        oklabel="ok"
                        cancellabel="cancelar"
                        format="dd/MM/yyyy"
                        // error={false}
                        // helperText=''
                        value={selectedDateInicio}
                        onChange={handleDateChangeInicio}
                        style={{ width: inputSize, marginRight: '5%' }}
                      />

                      <DatePicker
                        variant="inline"
                        maxDate={addDays(new Date(), 1)}
                        label="Data de fim"
                        oklabel="ok"
                        cancellabel="cancelar"
                        format="dd/MM/yyyy"
                        // error=''
                        // helperText=''
                        value={selectedDateFim}
                        onChange={handleDateChangeFim}
                        style={{ width: inputSize, marginRight: '5%' }}
                      />
                      <Autocomplete
                        id="parceiroId"
                        name="parceiroId"
                        clearText="Limpar"
                        openText="Abrir"
                        closeText="Fechar"
                        noOptionsText="Sem dados"
                        options={parceiros}
                        getOptionLabel={(option) => `${option.nome}` || ''}
                        value={parceiroSelecionado || ''}
                        // onInputChange={(event, value, reason) => {
                        //   if (reason == 'clear') {
                        //     setApartamento({
                        //       ...apartamento,
                        //       unidadesId: 0,
                        //     });
                        //   }
                        // }}
                        // disableClearable
                        getOptionSelected={(option, value) => {
                          return value.id === option.id;
                        }}
                        // eslint-disable-next-line no-unused-vars
                        filterOptions={(options, state) =>
                          options.filter((option) => option.id > 0)
                        }
                        onChange={(e, value) => handleChangeParceiroId(e, value)}
                        style={{ width: inputSize, marginRight: '5%' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Parceiro"
                            placeholder="Parceiro"
                            // error={!!errors.nome}
                            helperText={parceiroSelecionado.id > 0 ? '' : 'Escolha um parceiro'}
                          />
                        )}
                      />
                      <div style={{ width: inputSize, marginRight: '5%' }}>
                        <Button
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                          disabled={
                            (parceiro && parceiro.id == null) || (parceiro && parceiro.id === 1)
                              ? true
                              : false
                          }
                          //   className={
                          //     !disableAdicionar
                          //       ? classes.btnAdicionar
                          //       : classes.btnAdicionarDisabled
                          //   }
                          className={classes.btnAdicionar}

                          //   disabled={disableAdicionar}
                        >
                          <SearchIcon className={classes.searchIcon} />
                          Pesquisar
                        </Button>
                      </div>
                    </div>
                    <div style={{ width: dimensions && dimensions.width < 1050 ? '100%' : '70%' }}>
                      <GraficoResumo
                        style={{ background: 'red' }}
                        dadosGraficos={dadosGraficos}
                        dimensions={dimensions}
                      />
                    </div>
                  </div>
                </MuiPickersUtilsProvider>
              </div>
            </ThemeProvider>

            <div
              style={{
                // display: 'flex',
                // justifyContent: 'center',
                // marginTop: '25px',
                // marginBottom: '30px ',
                margin: '40px 40px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '25px',
                  marginBottom: '30px ',
                }}
              ></div>
              <MUIDataTable
                title="Análise de vendas"
                description="Nada para mostrar"
                data={analiseDeVendas}
                components={components}
                columns={columns}
                options={options}
                style={{ marginBottom: '30px !important' }}
              />
            </div>
          </div>
        </div>
      </div>
      {children}
    </ThemeProvider>
  );
};

export default FormSelectors;
