import Instance from '../../../Axios';

// New Actions for the app

const GetMenuProdutos = (id) => async (dispatch) => {
  await Instance()
    .get(`/backend/menus/${id}/produtos`)
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'GET_MENU_PRODUTOS', payload: data });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetMenuProdutos;
