/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import './parceiros.css';
//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import {
  // Modal,
  // Backdrop,
  // Fade,
  // MuiThemeProvider,
  Button,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import AddIcon from '@material-ui/icons/Add';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
//COMPONENTS
import ModalEdit from './components/modalEdit';
import ModalDelete from './components/modalDelete';
import ModalAdicionar from './components/modalAdicionar';
import CustomToolbar from './components/customToolbar';

//ACTIONS
import GetParceiros from '../../../utils/redux/actions/parceiros/parceirosAction';
import GetParceiro from '../../../utils/redux/actions/parceiros/getParceiroAction';
import GetTipoParceiro from '../../../utils/redux/actions/parceiros/tipoParceiro/tipoParceiroAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    color: theme.palette.getContrastText(yellow[600]),
    // backgroundColor: yellow[600],
    border: `1px solid #fdd835`,
    '&:hover': {
      // backgroundColor: yellow[700],
      border: '1px solid yellow[700]',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnAdicionar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
}));
const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Parceiros = () => {
  const classes = useStyles();
  const [responsive] = useState('vertical');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [parceiro, setParceiro] = useState({
    nome: '',
    tipoParceiroId: '',
    telefone: '',
    email: '',
    morada: '',
    codPostal: '',
    localidade: '',
  });
  const [tipoParceiroSelecionado, setTipoParceiroSelecionado] = useState({
    id: 0,
    descricao: '',
  });

  const dispatch = useDispatch();

  const resetParceiro = () => {
    setParceiro({
      nome: '',
      tipoParceiroId: '',
      telefone: '',
      email: '',
      morada: '',
      codPostal: '',
      localidade: '',
    });
  };

  const pinfo = useSelector((state) => state.parceiros.parceiro);

  const ydata = useSelector((state) => state.parceiros.parceiros);

  const tipoParceiro = useSelector((state) => state.parceiros.tipoParceiro);

  const fetchParceiros = async () => {
    await dispatch(GetParceiros()).then(() =>
      dispatch({
        type: 'CLEAR_DATA_PARCEIRO',
      }),
    );
    // dispatch({ type: 'CLEAR_DATA_PARCEIRO' });
  };
  const fetchTiposParceiro = () => {
    dispatch(GetTipoParceiro());
    // dispatch({ type: 'CLEAR_DATA_PARCEIRO' });
  };

  const fetchParceiro = (id, e) => {
    e.preventDefault();
    return dispatch(GetParceiro(id));
  };

  const [parceiroId, setParceiroId] = useState();
  const [parceiroNome, setParceiroNome] = useState();

  useEffect(() => fetchParceiros(), []);
  useEffect(() => fetchTiposParceiro(), []);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setErrorsParceiro({});
    if (btn == 'edit') {
      setParceiroId(id);
      setParceiroNome(tableMeta.rowData[0]);
      fetchParceiro(id, e).then(() => setOpenEdit(true));
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setParceiroId(id);
      setParceiroNome(tableMeta.rowData[0]);
    } else {
      resetParceiro();
      setOpenAdicionar(true);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    setErrorsParceiro({});
    if (btn == 'edit') {
      setOpenEdit(false);
      // dispatch({ type: 'CLEAR_DATA_PARCEIRO' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else {
      setOpenAdicionar(false);
      resetParceiro();
    }
    // resetParceiro();
  };

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackAdd, setOpenSnackAdd] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackDelete, setOpenSnackDelete] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'edit') {
      setOpenSnack(true);
    } else if (btn == 'delete') {
      setOpenSnackDelete(true);
    } else if (btn == 'add') {
      setOpenSnackAdd(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenSnackDelete(false);
    setOpenSnackAdd(false);
  };

  //-------------------------------------------------------------------------//

  // -------------------------- Validacao do parceiro -----------------//
  const [errorsParceiro, setErrorsParceiro] = useState({});

  const validationParceiro = (fieldValues = parceiro) => {
    const temp = { ...errorsParceiro };
    if ('nome' in fieldValues) temp.nome = fieldValues.nome ? '' : 'Campo Obrigatório';
    if ('tipoParceiroId' in fieldValues)
      temp.tipoParceiroId = fieldValues.tipoParceiroId ? '' : 'Campo Obrigatório';
    if ('telefone' in fieldValues) {
      if (isNaN(fieldValues.telefone) == false) {
        temp.telefone = fieldValues.telefone.length === 9 ? '' : 'Campo Obrigatório';
      } else {
        temp.telefone = 'Apenas números permitidos';
      }
    }

    if ('codPostal' in fieldValues)
      temp.codPostal = fieldValues.codPostal ? '' : 'Campo Obrigatório';

    if ('morada' in fieldValues) temp.morada = fieldValues.morada ? '' : 'Campo Obrigatório';

    if ('email' in fieldValues)
      // prettier-ignore
      temp.email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.email) ? '' : 'Campo Obrigatório';

    if ('localidade' in fieldValues)
      temp.localidade = fieldValues.localidade ? '' : 'Campo Obrigatório';

    setErrorsParceiro({
      ...temp,
    });
    if (fieldValues == parceiro) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  const columns = [
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'email',
      label: 'Email',
      // options: {
      //   filter: true,
      //   sort: false,
      //   setCellProps: () => ({
      //     style: { minWidth: '200px', maxWidth: '300px' },
      //   }),
      // },
    },

    {
      name: 'telefone',
      label: 'Telefone',
    },
    {
      name: 'tipoParceiroDescricao',
      label: 'Tipo de Parceiro',
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns">
              <Button
                onClick={(e) => handleOpen('edit', e, value, tableMeta)}
                className={classes.btnEditar}
                style={{ marginRight: '20px' }}
              >
                <EditIcon style={{ color: 'white' }} />
              </Button>
              {/* <Button
                onClick={(e, tableMeta) =>
                  // window.alert(`Clicked "Edit" for row ${tableMeta.rowIndex}`)
                  handleOpen('edit', e, value)
                }
                className={classes.btnEditar1}
                style={{ marginRight: '20px' }}
              >
                <EditIcon style={{ color: '#fdd835' }} />
              </Button>
              <Tooltip
                title={'Remover Parceiro'}
                style={{ height: '40px', width: '40px', marginRight: '20px' }}
              >
                <IconButton
                  className={classes.iconButton}
                  // onClick={this.handleClick}
                >
                  <DeleteIcon style={{ color: '#f44336' }} />
                </IconButton>
              </Tooltip> */}

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
                className={classes.btnApagar}
              >
                <DeleteIcon style={{ color: 'white' }} />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,
    customToolbar: () => {
      return <CustomToolbar handleOpen={handleOpen} />;
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  // -----------------------------------------------------------------------//

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <>
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Parceiro Editado com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackDelete} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Parceiro Removido com sucesso!
          </Alert>
        </Snackbar>

        <Snackbar open={openSnackAdd} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Parceiro Adicionado com sucesso!
          </Alert>
        </Snackbar>
        {/* <Button
          className={classes.btnAdicionar}
          onClick={(e) => {
            handleOpen('add', e);
          }}
        >
          Adicionar Parceiro
        </Button> */}
        <ModalAdicionar
          openAdicionar={openAdicionar}
          handleClose={handleClose}
          parceiro={parceiro}
          setParceiro={setParceiro}
          validationParceiro={validationParceiro}
          errorsParceiro={errorsParceiro}
          fetchParceiros={fetchParceiros}
          handleOpenAlert={handleOpenAlert}
          tipoParceiro={tipoParceiro}
        />
        {/* <MuiThemeProvider theme={myTheme}> */}
        <ModalEdit
          openEdit={openEdit}
          handleClose={handleClose}
          parceiro={parceiro}
          setParceiro={setParceiro}
          validationParceiro={validationParceiro}
          errorsParceiro={errorsParceiro}
          fetchParceiros={fetchParceiros}
          pinfo={pinfo}
          parceiroId={parceiroId}
          handleOpenAlert={handleOpenAlert}
          parceiroNome={parceiroNome}
          tipoParceiro={tipoParceiro}
          tipoParceiroSelecionado={tipoParceiroSelecionado}
          setTipoParceiroSelecionado={setTipoParceiroSelecionado}
          parceiros={ydata}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          parceiroId={parceiroId}
          fetchParceiros={fetchParceiros}
          parceiro={parceiro}
          setParceiro={setParceiro}
          parceiroNome={parceiroNome}
          handleOpenAlert={handleOpenAlert}
        />
        <MUIDataTable
          title="Parceiros"
          description="Nada para mostrar"
          data={ydata}
          columns={columns}
          options={options}
          style={{ marginBottom: '30px !important' }}
        />
        {/* </MuiThemeProvider> */}
      </>
    </ThemeProvider>
  );
};

export default Parceiros;
