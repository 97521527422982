import axios from 'axios';
import BASE_URL from '../../../BASE_URL';

const DadosSenhaUtilizador = (hash) => async (dispatch) => {
  console.log(hash);
  await axios
    .get(`${BASE_URL}/backend/signup/${hash}`)
    .then(({ data }) => {
      console.log(data);

      dispatch({ type: 'GET_DEF_SENHA', payload: data.yData });
    })
    .catch(({ err }) => console.log(err));
};

export default DadosSenhaUtilizador;
