/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import '../parceiros.css';

//PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

//ACTIONS
import EditarUnidade from '../../../../utils/redux/actions/unidades/editarUnidadeAction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalEdit = ({
  openEdit,
  handleClose,
  unidade,
  setUnidade,
  validateOnChange = true,
  validationUnidade,
  errorsUnidade = null,
  fetchUnidades,
  unidadeId,
  handleOpenAlert,
  unidadeNome,
  unidadeInfo,
  parceiroSelecionado,
  setParceiroSelecionado,
  parceiros,
  parceirosAlimentacao,
  parceirosLavandaria,
  parceiroAlimentacaoSelecionado,
  setParceiroAlimentacaoSelecionado,
  parceiroLavandariaSelecionado,
  setParceiroLavandariaSelecionado,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const selectParceiroAlimentacao = () => {
    if (parceirosAlimentacao && parceirosAlimentacao.length > 0 && unidadeId) {
      const ParceiroAlimentacaoAssociado = parceirosAlimentacao.find(
        (parc) => parc.id === unidade.parceiroAlimentacaoId,
      );

      setParceiroAlimentacaoSelecionado(
        ParceiroAlimentacaoAssociado || {
          id: 0,
          nome: '',
        },
      );
    } else {
      setParceiroAlimentacaoSelecionado({
        id: 0,
        nome: '',
      });
    }
  };
  const selectParceiroLavandaria = () => {
    if (parceirosLavandaria && parceirosLavandaria.length > 0 && unidadeId) {
      const ParceiroLavandariaAssociado = parceirosLavandaria.find(
        (parc) => parc.id === unidade.parceiroLavandariaId,
      );

      setParceiroLavandariaSelecionado(
        ParceiroLavandariaAssociado || {
          id: 0,
          nome: '',
        },
      );
    } else {
      setParceiroLavandariaSelecionado({
        id: 0,
        nome: '',
      });
    }
  };

  useEffect(() => selectParceiroAlimentacao(), [unidade]);
  useEffect(() => selectParceiroLavandaria(), [unidade]);

  const handleChange = (e) => {
    e.preventDefault();

    setUnidade({
      ...unidade,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validationUnidade({ [e.target.name]: e.target.value });
  };

  const handleChangeParceiroAlimentacaoId = (e, value) => {
    e.preventDefault();

    setUnidade({
      ...unidade,
      parceiroAlimentacaoId: value && value.id,
    });
    if (validateOnChange) validationUnidade({ parceiroAlimentacaoId: value });
  };
  const handleChangeParceiroLavandariaId = (e, value) => {
    e.preventDefault();

    setUnidade({
      ...unidade,
      parceiroLavandariaId: value && value.id,
    });
    if (validateOnChange) validationUnidade({ parceiroLavandariaId: value });
  };

  const handleChangeCodPostal = (e) => {
    e.preventDefault();
    let texto = e.target.value;

    texto = texto.replace(/\D/g, '');
    texto = texto.replace(/(\d{4})(?=\d)/g, '$1-');

    setUnidade({
      ...unidade,
      codpostal: texto,
    });

    if (validateOnChange) validationUnidade({ codpostal: e.target.value });
  };

  useEffect(() => {
    unidadeInfo &&
      setUnidade({
        descricao: unidadeInfo.descricao,
        parceiroAlimentacaoId: unidadeInfo.parceiros_alimentacao_id,
        parceiroLavandariaId: unidadeInfo.parceiros_lavandaria_id,
        direcoes: unidadeInfo.direcoes,
        morada: unidadeInfo.morada,
        codpostal: unidadeInfo.codpostal,
        localidade: unidadeInfo.localidade,
      });
  }, [unidadeInfo, setUnidade]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        descricao: unidade.descricao,
        parceirosAlimentacaoId: unidade.parceiroAlimentacaoId,
        parceirosdLavandariaId: unidade.parceiroLavandariaId,
        direcoes: unidade.direcoes,
        morada: unidade.morada,
        codpostal: unidade.codpostal,
        localidade: unidade.localidade,
      },
    ];
    if (validationUnidade()) {
      // console.log(unidadeId, ydata);

      dispatch(EditarUnidade(ydata, unidadeId))
        .then(() => handleClose('edit', e))
        .then(() => fetchUnidades())
        .then(() => handleOpenAlert('edit'));
    }
  };

  const CHARACTER_LIMIT = 50;

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        onClose={(e) => handleClose('edit', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Editar Unidade
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode editar esta unidade <b> {unidadeNome}</b>, que terá os devidos apartamentos
                  associados.
                </p>
              </div>

              <div>
                <TextField
                  id="Descricao"
                  name="descricao"
                  label="Descrição"
                  // variant='outlined'
                  // color='primary'
                  value={unidade.descricao || ''}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="nome"
                  inputProps={{ maxLength: 50 }}
                  helperText={
                    (unidade &&
                      unidade.descricao &&
                      `${Object.keys(unidade.descricao).length}/${CHARACTER_LIMIT}`) ||
                    ''
                  }
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsUnidade.descricao && {
                    error: true,
                    helperText: errorsUnidade.descricao,
                  })}
                />
              </div>
              <div>
                <Autocomplete
                  id="parceiroAlimentacaoId"
                  name="parceiroAlimentacaoId"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={parceirosAlimentacao}
                  getOptionLabel={(option) => `${option.nome}` || ''}
                  value={parceiroAlimentacaoSelecionado || ''}
                  getOptionSelected={(option, value) => {
                    if (!value.id) return true;

                    return value.id === option.id;
                  }}
                  filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                  onChange={(e, value) => handleChangeParceiroAlimentacaoId(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parceiro de Alimentação"
                      placeholder="Parceiro de Alimentação"
                      // variant='outlined'
                      {...(errorsUnidade.parceiroAlimentacaoId && {
                        error: true,
                        helperText: errorsUnidade.parceiroAlimentacaoId,
                      })}
                    />
                  )}
                />
              </div>
              <div>
                <Autocomplete
                  id="parceiroLavandariaId"
                  name="parceiroLavandariaId"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={parceirosLavandaria}
                  getOptionLabel={(option) => `${option.nome}` || ''}
                  value={parceiroLavandariaSelecionado || ''}
                  getOptionSelected={(option, value) => {
                    if (!value.id) return true;

                    return value.id === option.id;
                  }}
                  filterOptions={(options, state) => options.filter((option) => option.id > 0)}
                  onChange={(e, value) => handleChangeParceiroLavandariaId(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parceiro de Lavandaria"
                      placeholder="Parceiro de Lavandaria"
                      // variant='outlined'
                      {...(errorsUnidade.parceiroLavandariaId && {
                        error: true,
                        helperText: errorsUnidade.parceiroLavandariaId,
                      })}
                    />
                  )}
                />
              </div>
              <div>
                <TextField
                  id="morada"
                  name="morada"
                  label="Morada"
                  // variant='outlined'
                  // color='primary'
                  value={unidade.morada || ''}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="morada"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsUnidade.morada && {
                    error: true,
                    helperText: errorsUnidade.morada,
                  })}
                />
              </div>
              <div>
                <TextField
                  id="codpostal"
                  name="codpostal"
                  label="Código Postal"
                  className="codpostal"
                  // variant='outlined'
                  // color='primary'
                  value={unidade.codpostal || ''}
                  onChange={handleChangeCodPostal}
                  fullWidth
                  autoComplete="off"
                  inputProps={{
                    maxLength: 8,
                  }}
                  // style={{ marginBottom: 8 }}
                  {...(errorsUnidade.codpostal && {
                    error: true,
                    helperText: errorsUnidade.codpostal,
                  })}
                />
              </div>
              <div>
                <TextField
                  id="localidade"
                  name="localidade"
                  label="Localidade"
                  // variant='outlined'
                  // color='primary'
                  value={unidade.localidade || ''}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="localidade"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsUnidade.localidade && {
                    error: true,
                    helperText: errorsUnidade.localidade,
                  })}
                />
              </div>
              <div>
                <TextField
                  id="direcoes"
                  name="direcoes"
                  label="Direções"
                  // variant='outlined'
                  // color='primary'
                  value={unidade.direcoes}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="direcoes"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsUnidade.direcoes && {
                    error: true,
                    helperText: errorsUnidade.direcoes,
                  })}
                />
              </div>

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('edit', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalEdit;
