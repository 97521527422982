import Instance from '../../../Axios';

// New Actions for the app

const GetReserva = (id) => async (dispatch) => {
  await Instance()
    .get(`/backend/reservas/${id}`)
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'GET_RESERVA', payload: data.yData });
    })
    .catch((err) => {
      // history.push('/app/reservas');
      console.log(err);
    });
};

export default GetReserva;
