export const limpezaInitialState = {
  // CATEGORIAS
  categoriasLimpeza: [],
  novaCategoriaLimpeza: {},
  categoriaEditada: {},
  categoriaRemovida: {},
  categoriaLimpeza: {},

  //ITENS
  itensLimpeza: [],
  novoItemLimpeza: {},
  itemEditado: {},
  itemRemovido: {},
  itemLimpeza: {},

  //TOOLTIPS
  tooltipsLimpeza: [],
  novaTooltipLimpeza: {},
  tooltipEditada: {},
  tooltipRemovida: {},
  tooltipLimpeza: {},
};
