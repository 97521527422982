/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import clsx from 'clsx';
//PACKAGES
import {
  SwipeableDrawer,
  List,
  Divider,
  ListItemIcon,
  ListItemText,
  IconButton,
  MenuItem,
  ListItem,
  Collapse,
  ListSubheader,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import ApartmentIcon from '@material-ui/icons/Apartment';
// import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import StorefrontIcon from '@material-ui/icons/Storefront';
import BookIcon from '@material-ui/icons/Book';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { FaAppleAlt, FaBed } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Cleaning from '../images/cleaning.svg';
import Broom from '../images/broom.svg';

import { BsGraphUp } from 'react-icons/bs';
import { HiOutlineDocumentReport } from 'react-icons/hi';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 260,
    marginTop: 62,
  },
  fullList: {
    width: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const SwipeableTemporaryDrawer = ({ windowDimensions, updateSelected, item }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const [open, setOpen] = React.useState(true);
  const [openRelatorios, setOpenRelatorios] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if ((item && item.selected != null) || '') {
      if (item.selected !== 11 && item.selected !== 12) {
        setOpen(false);
      }

      if (item.selected !== 13) {
        setOpenRelatorios(false);
      }
    }
  }, [item.selected]);

  const toggleDrawer = (anchor, open) => (event) => {
    // if (
    //   event &&
    //   event.type === 'keydown' &&
    //   (event.key === 'Tab' || event.key === 'Shift')
    // ) {
    //   return;
    // }

    setState({ ...state, [anchor]: open });
  };

  const handleClick2 = () => {
    setOpenRelatorios(!openRelatorios);
  };

  const list = (anchor) => (
    <>
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top',
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        {windowDimensions.width < 1220 ? (
          <>
            <Divider />
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Geral
                </ListSubheader>
              }
            >
              <MenuItem
                button
                component={Link}
                to={'/app/reservas'}
                onClick={() => updateSelected(0)}
                selected={item.selected === 0}
              >
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText primary="Reservas" />
              </MenuItem>

              <MenuItem
                button
                component={Link}
                to={'/app/pequenos-almocos'}
                onClick={() => updateSelected(1)}
                selected={item.selected === 1}
              >
                <ListItemIcon>
                  <EmojiFoodBeverageIcon />
                </ListItemIcon>
                <ListItemText primary="Pequenos almoços" />
              </MenuItem>

              {/* <MenuItem
              button
              component={Link}
              to={'/app/fechaduras'}
              onClick={() => updateSelected(2)}
              selected={item.selected === 2}
            >
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText primary='Fechaduras' />
            </MenuItem> */}
            </List>
            <Divider />
          </>
        ) : (
          <Divider />
        )}

        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Administração
            </ListSubheader>
          }
          style={{ paddingBottom: 0 }}
        >
          <MenuItem
            button
            component={Link}
            to={'/admin/parceiros'}
            onClick={() => updateSelected(3)}
            selected={item.selected === 3}
          >
            <ListItemIcon>
              <StorefrontIcon />
            </ListItemIcon>
            <ListItemText primary="Parceiros" />
          </MenuItem>

          <MenuItem
            button
            component={Link}
            to={'/admin/utilizadores'}
            onClick={() => updateSelected(4)}
            selected={item.selected === 4}
          >
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Utilizadores" />
          </MenuItem>
          <MenuItem
            button
            component={Link}
            to={'/admin/menus'}
            onClick={() => updateSelected(5)}
            selected={item.selected === 5}
          >
            <ListItemIcon>
              <RestaurantMenuIcon />
            </ListItemIcon>
            <ListItemText primary="Menus" />
          </MenuItem>
          <MenuItem
            button
            component={Link}
            to={'/admin/categorias'}
            onClick={() => updateSelected(6)}
            selected={item.selected === 6}
          >
            <ListItemIcon>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText primary="Categorias" />
          </MenuItem>
          <MenuItem
            button
            component={Link}
            to={'/admin/produtos'}
            onClick={() => updateSelected(7)}
            selected={item.selected === 7}
          >
            <ListItemIcon>
              <FaAppleAlt size="1.3em" />
            </ListItemIcon>
            <ListItemText primary="Produtos" />
          </MenuItem>
          <MenuItem
            button
            component={Link}
            to={'/admin/apartamentos'}
            onClick={() => updateSelected(8)}
            selected={item.selected === 8}
          >
            <ListItemIcon>
              <FaBed size="1.3em" />
            </ListItemIcon>
            <ListItemText primary="Apartamentos" />
          </MenuItem>
          <MenuItem
            button
            component={Link}
            to={'/admin/unidades'}
            onClick={() => updateSelected(9)}
            selected={item.selected === 9}
          >
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText primary="Unidades" />
          </MenuItem>
        </List>
      </div>
      <div>
        {/* <List component='nav' className={classes.root}> */}
        <ListItem button onClick={handleClick} style={{ marginTop: 0 }}>
          <ListItemIcon>
            <img src={Broom} style={{ height: '1.6em', color: 'red' }} />
          </ListItemIcon>
          <ListItemText primary="Limpezas" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
            <List component="div" disablePadding>
              {/* <ListItem
              button
              className={classes.nested}
              component={Link}
              to={'/admin/limpeza/categorias'}
              onClick={() => updateSelected(10)}
              selected={item.selected === 10}
            >
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary='Categorias' />
            </ListItem> */}
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to={'/admin/limpeza/itens'}
                onClick={() => updateSelected(11)}
                selected={item.selected === 11}
              >
                <ListItemIcon>
                  {/* <StarBorder /> */}
                  <img src={Cleaning} style={{ height: '1.6em' }} />
                </ListItemIcon>
                <ListItemText primary="Items" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to={'/admin/limpeza/tooltips'}
                onClick={() => updateSelected(12)}
                selected={item.selected === 12}
              >
                <ListItemIcon>
                  {/* <StarBorder /> */}
                  <img src={Cleaning} style={{ height: '1.6em' }} />
                </ListItemIcon>
                <ListItemText primary="Tooltips" />
              </ListItem>
            </List>
          </div>
        </Collapse>
      </div>
      <div>
        {/* Relatórios */}
        <ListItem button onClick={handleClick2} style={{ marginTop: 0 }}>
          <ListItemIcon>
            <HiOutlineDocumentReport size="1.6em" />
          </ListItemIcon>
          <ListItemText primary="Relatórios" />
          {openRelatorios ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openRelatorios} timeout="auto" unmountOnExit>
          <div onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to={'/admin/relatorios/analise-de-vendas'}
                onClick={() => updateSelected(13)}
                selected={item.selected === 13}
              >
                <ListItemIcon>
                  {/* <StarBorder /> */}
                  {/* <img src={Cleaning} style={{ height: '1.6em' }} /> */}
                  <BsGraphUp size="1.3em" />
                </ListItemIcon>
                <ListItemText primary="Análise de vendas" />
              </ListItem>
            </List>
          </div>
        </Collapse>
      </div>
      {/* </List> */}
    </>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // onClick={handleDrawerOpen}
            edge="start"
            // className={clsx(classes.menuButton, open && classes.hide)}
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default SwipeableTemporaryDrawer;
