/* eslint-disable eqeqeq */
import React from 'react';
//PACKAGES
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Card,
  CardContent,
  CardActions,
  Collapse,
  Typography,
  IconButton,
} from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IoClose, IoCheckmarkOutline } from 'react-icons/io5';

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '85%',
    marginBottom: '20px',
    margin: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  descricao: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  typo: {
    display: 'flex',
    alignItems: 'center',
    width: '250px',
    height: '40px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  typoJustificacao: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    height: '40px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const LimpezaDropdown = ({ limpeza }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card className={classes.root}>
        <CardActions
          disableSpacing
          style={{
            background: limpeza.semFalhas == 0 ? '#FDE4E2' : '#e5fde2',
            boxShadow: '0 1px 4px rgb(0 0 0 / 0.10)',
          }}
        >
          {limpeza && limpeza.data ? moment(limpeza.data).format('DD-MM-YYYY') : <p>---</p>}
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            ></div>
            {/* <div className={classes.descricao}> */}
            {limpeza &&
              limpeza.itens.map((item) => (
                <>
                  <div className={classes.descricao}>
                    <Typography paragraph className={classes.typo}>
                      {item.descricao}
                    </Typography>
                    <br />
                    {item.checked === 0 ? (
                      <IoClose size="2em" color="tomato" />
                    ) : (
                      <div>
                        <IoCheckmarkOutline size="2em" color="lightgreen" />
                        <p style={{ fontSize: '8px' }}>{moment(item.data).format('HH:mm')}h</p>
                      </div>
                    )}
                  </div>
                </>
              ))}
            {limpeza && limpeza.observacoes ? (
              <div>
                <Typography paragraph>
                  <div>
                    <b>Justificação:</b>
                  </div>
                  <div>{limpeza && limpeza.observacoes}</div>
                </Typography>
              </div>
            ) : null}

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              {/* {encomenda && encomenda.produtos.length > 0 ? (
                        <Button
                          onClick={(e) => handleOpen('info', e)}
                          className={classes.btnInfo}
                          style={{ marginRight: '20px' }}
                        >
                          <AiOutlineInfoCircle
                            style={{ color: 'white' }}
                            size='1.6em'
                          />
                        </Button>
                      ) : null} */}
              {/* {encomenda &&
                      !encomenda.preenchidoCliente &&
                      !encomenda.preenchidoParceiro ? (
                        <Button
                          onClick={(e) => {
                            handleOpen('delete', e);
                          }}
                          className={classes.btnApagar1}
                        >
                          <DeleteIcon style={{ color: 'white' }} />
                        </Button>
                      ) : null} */}
            </div>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default LimpezaDropdown;
