import { parceirosInitialState } from '../initialState/parceiros';

const parceirosReducer = (state = parceirosInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_PARCEIROS':
      return {
        ...state,
        parceiros: payload,
      };
    case 'GET_PARCEIRO':
      return {
        ...state,
        parceiro: payload,
      };
    case 'POST_PARCEIROS':
      return {
        ...state,
        novoParceiro: payload,
      };
    case 'PUT_PARCEIRO':
      return {
        ...state,
        parceiroEditado: payload,
      };
    case 'DELETE_PARCEIRO':
      return {
        ...state,
        parceiroRemovido: payload,
      };
    case 'CLEAR_DATA_PARCEIRO':
      return {
        ...state,
        parceiro: [],
      };

    // TIPO PARCEIRO
    case 'GET_TIPO_PARCEIRO':
      return {
        ...state,
        tipoParceiro: payload,
      };

    // Parceiros Dropdown

    case 'GET_PARCEIROS_ALIMENTACAO':
      return {
        ...state,
        parceirosAlimentacao: payload,
      };
    case 'GET_PARCEIROS_LAVANDARIA':
      return {
        ...state,
        parceirosLavandaria: payload,
      };

    default:
      return state;
  }
};

export default parceirosReducer;
