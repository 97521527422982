import Instance from '../../../../Axios';

const EditarItemLimpeza = (ydata, id) => async (dispatch) => {
  console.log(ydata, id);
  await Instance()
    .put(`/backend/checklist/itens/${id}`, { ydata })
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'PUT_ITEM_LIMPEZA', payload: data });
      //   show('success', 'Foram associados novos funcionários á obra');
    })
    .catch((err) => {
      // eslint-disable-next-line eqeqeq
      if (err.response.status != 200) {
        // show('warning', 'Preencha todos os campos');
      }
    });
};

export default EditarItemLimpeza;
