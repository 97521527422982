import Instance from '../../../../Axios';

const RemoverItemLimpeza = (id) => async (dispatch) => {
  await Instance()
    .delete(`/backend/checklist/itens/${id}`)
    .then(({ data }) => {
      dispatch({ type: 'DELETE_ITEM_LIMPEZAS', payload: data });
    })
    .catch(({ err }) => console.log(err));
};

export default RemoverItemLimpeza;
