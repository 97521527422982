import { peqAlmocosInitialState } from '../initialState/peqAlmocos';

const peqAlmocosReducer = (state = peqAlmocosInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_PEQALMOCOS':
      return {
        ...state,
        peqAlmocos: payload,
      };
    case 'POST_PEQ_ALMOCOS':
      return {
        ...state,
        enviarPeqAlmocos: payload,
      };

    default:
      return state;
  }
};

export default peqAlmocosReducer;
