import React from 'react';
//PACKAGES
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

//COMPONENTS
import LimpezaDropdown from './limpezas/limpezaDropdown';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '85%',
    marginBottom: '20px',
    margin: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '80vh',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    height: '50px',
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalLimpezas = ({ openLimpezas, handleClose, limpezaInfo, apartamentoNome }) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openLimpezas}
        onClose={(e) => handleClose('limpezas', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openLimpezas}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Histórico de limpezas
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  {/* Tem a certeza que quer remover <b>{apartamentoNome}</b> da
                  lista? */}
                </p>
              </div>

              {limpezaInfo &&
                limpezaInfo.map((limpeza) => (
                  <div key={limpeza.id}>
                    <LimpezaDropdown limpeza={limpeza} limpezaInfo={limpezaInfo} />
                  </div>
                ))}

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnCancelar} onClick={(e) => handleClose('limpezas', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalLimpezas;
