import { utilizadoresInitialState } from '../initialState/utilizadores';

const utilizadoresReducer = (state = utilizadoresInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_UTILIZADORES':
      return {
        ...state,
        utilizadores: payload,
      };
    case 'GET_PERFIS':
      return {
        ...state,
        perfis: payload,
      };
    case 'POST_UTILIZADOR':
      return {
        ...state,
        novoUtilizador: payload,
      };
    case 'GET_UTILIZADOR':
      return {
        ...state,
        utilizador: payload,
      };
    case 'DELETE_UTILIZADOR':
      return {
        ...state,
        utilizadorRemovido: payload,
      };
    case 'PUT_UTILIZADOR':
      return {
        ...state,
        utilizadorEditado: payload,
      };
    case 'CLEAR_DATA_UTILIZADOR':
      return {
        ...state,
        utilizador: payload,
      };

    default:
      return state;
  }
};

export default utilizadoresReducer;
