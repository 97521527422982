import Instance from '../../../../Axios';

// New Actions for the app

const GetAnaliseDeVendasPDF =
  (formatSelectedDateInicio, formatSelectedDateFim, parceiroId) => async (dispatch) => {
    await Instance()
      .get(
        `/backend/relatorios/analisevendas/pdf/${formatSelectedDateInicio}/${formatSelectedDateFim}/${parceiroId}`,
      )
      .then(({ data }) => {
        console.log(data);
        dispatch({
          type: 'GET_ANALISE_DE_VENDAS_PDF',
          payload: data.yData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export default GetAnaliseDeVendasPDF;
