/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import './produtos.css';

//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { Button, Snackbar } from '@material-ui/core';
import { ThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';

//COMPONENTS
import CustomToolbar from './components/customToolbar';
import ModalEdit from './components/modalEdit';
import ModalDelete from './components/modalDelete';
import ModalAdicionar from './components/modalAdicionar';

//ACTIONS
import GetProdutos from '../../../utils/redux/actions/produtos/produtosAction';
import GetProduto from '../../../utils/redux/actions/produtos/getProdutoAction';
import GetCategoriasProduto from '../../../utils/redux/actions/produtos/getCategoriasProdutoAction';
import GetLinguagens from '../../../utils/redux/actions/getLinguagensAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    color: theme.palette.getContrastText(yellow[600]),
    // backgroundColor: yellow[600],
    border: `1px solid #fdd835`,
    '&:hover': {
      // backgroundColor: yellow[700],
      border: '1px solid yellow[700]',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnAdicionar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
}));

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Produtos = () => {
  const classes = useStyles();
  const [responsive] = useState('vertical');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [produto, setProduto] = useState({
    descricao: '',
    categoriasId: '',
  });
  const [produtoId, setProdutoId] = useState();
  const [produtoNome, setProdutoNome] = useState();
  const [categoriaSelecionada, setCategoriaSelecionada] = useState({
    descricao: '',
    id: 0,
  });
  const dispatch = useDispatch();

  const resetProduto = () => {
    setProduto({
      descricao: '',
      categoriasId: 0,
    });
  };

  const produtos = useSelector((state) => state.produtos.produtos);

  const fetchProdutos = () => {
    dispatch(GetProdutos());
    dispatch({ type: 'CLEAR_DATA_PRODUTO' });
  };

  const fetchCategorias = () => {
    dispatch(GetCategoriasProduto());
  };

  const fetchLinguagens = () => {
    dispatch(GetLinguagens()).then(() =>
      setLingState(linguagens.map((ling) => ({ linguasId: ling.id, descricao: '' }))),
    );
  };

  const linguagens = useSelector((state) => state.global.linguagens);
  const [lingState, setLingState] = useState({ id: 0, descricao: '' });
  const [empty, setEmpty] = useState(null);

  useEffect(() => fetchProdutos(), []);
  useEffect(() => fetchCategorias(), []);
  useEffect(() => fetchLinguagens(), []);

  const produtoInfo = useSelector((state) => state.produtos.produto);

  const fetchProduto = (id, e) => {
    e.preventDefault();
    return dispatch(GetProduto(id));
  };

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setErrorsProduto({});
    if (btn == 'edit') {
      setProdutoId(id);
      setProdutoNome(tableMeta.rowData[0]);
      fetchProduto(id, e).then(() => setOpenEdit(true));
      setEmpty(null);
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setProdutoId(id);
      setProdutoNome(tableMeta.rowData[0]);
    } else {
      setOpenAdicionar(true);
      resetProduto();
      setEmpty(null);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    setErrorsProduto({});
    if (btn == 'edit') {
      setOpenEdit(false);
      // setCategoriaSelecionada({
      //   descricao: '',
      //   categoriasId: 0,
      // });

      // dispatch({ type: 'CLEAR_DATA_PRODUTO' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else {
      setOpenAdicionar(false);

      // resetCategoria();
    }
  };

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackAdd, setOpenSnackAdd] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackDelete, setOpenSnackDelete] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'edit') {
      setOpenSnack(true);
    } else if (btn == 'delete') {
      setOpenSnackDelete(true);
    } else if (btn == 'add') {
      setOpenSnackAdd(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenSnackDelete(false);
    setOpenSnackAdd(false);
  };

  //------------------------------------------------------------------------//

  const columns = [
    {
      name: 'descricao',
      label: 'Descrição',
    },
    {
      name: 'categoriasNome',
      label: 'Categoria',
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-categorias">
              <Button
                onClick={(e) => handleOpen('edit', e, value, tableMeta)}
                className={classes.btnEditar}
                style={{ marginRight: '20px' }}
              >
                <EditIcon style={{ color: 'white' }} />
              </Button>

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
                className={classes.btnApagar}
              >
                <DeleteIcon style={{ color: 'white' }} />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    customToolbar: () => {
      return <CustomToolbar handleOpen={handleOpen} />;
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  // -------------------------- Validacao produtos --------------------//
  const [errorsProduto, setErrorsProduto] = useState({});

  const validationProduto = (fieldValues = produto) => {
    const temp = { ...errorsProduto };
    // if ('descricao' in fieldValues)
    //   temp.descricao = fieldValues.descricao ? '' : 'Campo Obrigatório';

    if ('categoriasId' in fieldValues)
      temp.categoriasId = fieldValues.categoriasId ? '' : 'Campo Obrigatório';

    setErrorsProduto({
      ...temp,
    });
    if (fieldValues == produto) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <>
        <Snackbar open={openSnackAdd} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Produto adicionado com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Produto editado com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackDelete} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Produto removido com sucesso!
          </Alert>
        </Snackbar>

        <ModalAdicionar
          openAdicionar={openAdicionar}
          handleClose={handleClose}
          produto={produto}
          setProduto={setProduto}
          validationProduto={validationProduto}
          errorsProduto={errorsProduto}
          setErrorsProduto={setErrorsProduto}
          fetchProdutos={fetchProdutos}
          handleOpenAlert={handleOpenAlert}
          //Linguagens
          linguagens={linguagens}
          lingState={lingState}
          setLingState={setLingState}
          empty={empty}
          setEmpty={setEmpty}
        />

        <ModalEdit
          openEdit={openEdit}
          handleClose={handleClose}
          produto={produto}
          setProduto={setProduto}
          validationProduto={validationProduto}
          errorsProduto={errorsProduto}
          fetchProdutos={fetchProdutos}
          produtoId={produtoId}
          handleOpenAlert={handleOpenAlert}
          produtoInfo={produtoInfo}
          produtoNome={produtoNome}
          categoriaSelecionada={categoriaSelecionada}
          setCategoriaSelecionada={setCategoriaSelecionada}
          //Linguagens
          linguagens={linguagens}
          lingState={lingState}
          setLingState={setLingState}
          empty={empty}
          setEmpty={setEmpty}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          produtoId={produtoId}
          fetchProdutos={fetchProdutos}
          produtoNome={produtoNome}
          handleOpenAlert={handleOpenAlert}
        />
        <MUIDataTable
          title="Produtos"
          description="Nada para mostrar"
          data={produtos}
          columns={columns}
          options={options}
        />
        {/* </MuiThemeProvider> */}
      </>
    </ThemeProvider>
  );
};

export default Produtos;
