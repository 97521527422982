/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { Button, Snackbar } from '@material-ui/core';
import { ThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';

//COMPONENTS
import CustomToolbar from './components/customToolbar';
import ModalEdit from './components/modalEdit';
import ModalDelete from './components/modalDelete';
import ModalAdicionar from './components/modalAdicionar';

//ACTIONS
import GetTooltips from '../../../../utils/redux/actions/limpeza/tooltips/tooltipsLimpezaAction';
import GetTooltip from '../../../../utils/redux/actions/limpeza/tooltips/getTooltipLimpezaAction';
import GetItensTooltip from '../../../../utils/redux/actions/limpeza/tooltips/getItensTooltipAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    color: theme.palette.getContrastText(yellow[600]),
    // backgroundColor: yellow[600],
    border: `1px solid #fdd835`,
    '&:hover': {
      // backgroundColor: yellow[700],
      border: '1px solid yellow[700]',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnAdicionar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
}));

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Tolltips = () => {
  const classes = useStyles();
  const [responsive] = useState('vertical');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [tooltip, setTooltip] = useState({
    descricao: '',
    checklistItensId: 0,
  });
  const [tooltipId, serTooltipId] = useState();
  const [tooltipNome, setTooltipNome] = useState();
  const [itemSelecionado, setItemSelecionado] = useState({
    descricao: '',
    checklistItensId: 0,
  });
  const dispatch = useDispatch();

  const resetTooltip = () => {
    setTooltip({
      descricao: '',
      checklistItensId: 0,
    });
  };

  const tooltips = useSelector((state) => state.limpeza.tooltipsLimpeza);

  const itens = useSelector((state) => state.limpeza.itensLimpeza);

  const fetchTooltips = () => {
    dispatch(GetTooltips());
    // dispatch({ type: 'CLEAR_DATA_PRODUTO' });
  };

  const fetchItens = () => {
    dispatch(GetItensTooltip());
  };

  useEffect(() => fetchTooltips(), []);
  useEffect(() => fetchItens(), []);

  const tooltipInfo = useSelector((state) => state.limpeza.tooltipLimpeza);

  const fetchTooltip = (id, e) => {
    e.preventDefault();
    return dispatch(GetTooltip(id));
  };

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setErrorsTootltip({});
    if (btn == 'edit') {
      serTooltipId(id);
      setTooltipNome(tableMeta.rowData[0]);
      fetchTooltip(id, e).then(() => setOpenEdit(true));
    } else if (btn == 'delete') {
      setOpenDelete(true);
      serTooltipId(id);
      setTooltipNome(tableMeta.rowData[0]);
    } else {
      setOpenAdicionar(true);
      resetTooltip();
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    setErrorsTootltip({});
    if (btn == 'edit') {
      setOpenEdit(false);
      // setCategoriaSelecionada({
      //   descricao: '',
      //   categoriasId: 0,
      // });

      // dispatch({ type: 'CLEAR_DATA_PRODUTO' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else {
      setOpenAdicionar(false);

      // resetCategoria();
    }
  };

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackAdd, setOpenSnackAdd] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackDelete, setOpenSnackDelete] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'edit') {
      setOpenSnack(true);
    } else if (btn == 'delete') {
      setOpenSnackDelete(true);
    } else if (btn == 'add') {
      setOpenSnackAdd(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenSnackDelete(false);
    setOpenSnackAdd(false);
  };

  //------------------------------------------------------------------------//

  const columns = [
    {
      name: 'descricao',
      label: 'Descrição',
    },
    {
      name: 'itemDescricao',
      label: 'Item',
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-categorias">
              <Button
                onClick={(e) => handleOpen('edit', e, value, tableMeta)}
                className={classes.btnEditar}
                style={{ marginRight: '20px' }}
              >
                <EditIcon style={{ color: 'white' }} />
              </Button>

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
                className={classes.btnApagar}
              >
                <DeleteIcon style={{ color: 'white' }} />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    customToolbar: () => {
      return <CustomToolbar handleOpen={handleOpen} />;
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  // -------------------------- Validacao produtos --------------------//
  const [errorsTooltip, setErrorsTootltip] = useState({});

  const validationTooltip = (fieldValues = tooltip) => {
    const temp = { ...errorsTooltip };
    if ('descricao' in fieldValues)
      temp.descricao = fieldValues.descricao ? '' : 'Campo Obrigatório';

    if ('checklistItensId' in fieldValues)
      temp.checklistItensId = fieldValues.checklistItensId ? '' : 'Campo Obrigatório';

    setErrorsTootltip({
      ...temp,
    });
    if (fieldValues == tooltip) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <>
        <Snackbar open={openSnackAdd} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Tooltip adicionada com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Tooltip editada com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackDelete} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Tooltip removida com sucesso!
          </Alert>
        </Snackbar>

        <ModalAdicionar
          openAdicionar={openAdicionar}
          handleClose={handleClose}
          tooltip={tooltip}
          setTooltip={setTooltip}
          validationTooltip={validationTooltip}
          errorsTooltip={errorsTooltip}
          fetchTooltips={fetchTooltips}
          handleOpenAlert={handleOpenAlert}
          itens={itens}
        />

        <ModalEdit
          openEdit={openEdit}
          handleClose={handleClose}
          tooltip={tooltip}
          setTooltip={setTooltip}
          validationTooltip={validationTooltip}
          errorsTooltip={errorsTooltip}
          fetchTooltips={fetchTooltips}
          tooltipId={tooltipId}
          handleOpenAlert={handleOpenAlert}
          tooltipInfo={tooltipInfo}
          tooltipNome={tooltipNome}
          itemSelecionado={itemSelecionado}
          setItemSelecionado={setItemSelecionado}
          itens={itens}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          tooltipId={tooltipId}
          fetchTooltips={fetchTooltips}
          tooltipNome={tooltipNome}
          handleOpenAlert={handleOpenAlert}
        />
        <MUIDataTable
          title="Produtos"
          description="Nada para mostrar"
          data={tooltips}
          columns={columns}
          options={options}
        />
        {/* </MuiThemeProvider> */}
      </>
    </ThemeProvider>
  );
};

export default Tolltips;
