/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import '../parceiros.css';

//PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

//ACTIONS
import EditarUtilizador from '../../../../utils/redux/actions/utilizadores/editarUtilizadorAction';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '48%',
    height: '50px',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnCancelar: {
    width: '48%',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalEdit = ({
  openEdit,
  handleClose,
  utilizador,
  setUtilizador,
  validateOnChange = true,
  validationUtilizador,
  errorsUtilizador = null,
  fetchUtilizadores,
  utilizadorId,
  handleOpenAlert,
  utilizadorNome,
  utilizadorInfo,
  utilizadorSelecionado,
  setUtilizadorSelecionado,
  perfis,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const selectUnidade = () => {
    if (perfis.length > 0 && utilizador) {
      const PerfilAssociado = perfis.find((perf) => perf.id === utilizador.perfisId);

      setUtilizadorSelecionado(
        PerfilAssociado || {
          id: 0,
          descricao: '',
        },
      );
    } else {
      setUtilizadorSelecionado({
        id: 0,
        descricao: '',
      });
    }
  };

  useEffect(() => selectUnidade(), [utilizadorInfo, setUtilizador, utilizador]);

  const handleChange = (e) => {
    e.preventDefault();

    setUtilizador({
      ...utilizador,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validationUtilizador({ [e.target.name]: e.target.value });
  };

  const handleChangePerfisId = (e, value) => {
    e.preventDefault();
    setUtilizadorSelecionado(
      value || {
        id: 0,
        descricao: '',
      },
    );
    setUtilizador({
      ...utilizador,
      perfisId: (value && value.id) || 0,
    });
    if (validateOnChange) validationUtilizador({ perfisId: value });
  };

  useEffect(() => {
    utilizadorInfo &&
      setUtilizador({
        email: utilizadorInfo.email,
        nome: utilizadorInfo.nome,
        perfisId: utilizadorInfo.perfil,
      });
  }, [utilizadorInfo, setUtilizador]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validationUtilizador()) {
      const ydata = [
        {
          email: utilizador.email,
          nome: utilizador.nome,
          perfil: utilizador.perfisId,
        },
      ];

      dispatch(EditarUtilizador(ydata, utilizadorId))
        .then(() => handleClose('edit', e))
        .then(() => fetchUtilizadores())
        .then(() => handleOpenAlert('edit'));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        onClose={(e) => handleClose('edit', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <>
            <div className={classes.paper}>
              <div className="header-modal">
                <h2 id="transition-modal-title" className="titulo-modal">
                  Editar Utilizador
                </h2>
                <p id="transition-modal-description" className="descricao-modal">
                  Pode editar este utilizador, <b> {utilizadorNome}</b>, que terá o devido perfil
                  associado.
                </p>
              </div>

              <div>
                <TextField
                  id="Nome"
                  name="nome"
                  label="Nome"
                  // variant='outlined'
                  // color='primary'
                  value={utilizador.nome || ''}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="nome"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsUtilizador.nome && {
                    error: true,
                    helperText: errorsUtilizador.nome,
                  })}
                />
              </div>
              <div>
                <TextField
                  id="Email"
                  name="email"
                  label="Email"
                  // variant='outlined'
                  // color='primary'
                  value={utilizador.email || ''}
                  onChange={handleChange}
                  fullWidth
                  autoComplete="off"
                  className="email"
                  // style={{ marginBottom: 8, marginRight: '4%' }}
                  {...(errorsUtilizador.email && {
                    error: true,
                    helperText: errorsUtilizador.email,
                  })}
                />
              </div>

              <div>
                <Autocomplete
                  id="perfisId"
                  name="perfisId"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={perfis}
                  getOptionLabel={(option) => `${option.descricao}` || ''}
                  value={utilizadorSelecionado || ''}
                  onInputChange={(event, value, reason) => {
                    if (reason == 'clear') {
                      setUtilizador({
                        ...utilizador,
                        perfisId: 0,
                      });
                    }
                  }}
                  getOptionSelected={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  // style={{ width: '100%' }}
                  onChange={(e, value) => handleChangePerfisId(e, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Perfil"
                      placeholder="Perfil"
                      // variant='outlined'
                      {...(errorsUtilizador.perfisId && {
                        error: true,
                        helperText: errorsUtilizador.perfisId,
                      })}
                    />
                  )}
                />
              </div>

              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button className={classes.btnConfirmar} onClick={handleSubmit}>
                  Confirmar
                </Button>

                <Button className={classes.btnCancelar} onClick={(e) => handleClose('edit', e)}>
                  Cancelar
                </Button>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalEdit;
