import Instance from '../../../Axios';

const RemoverUtilizador = (id) => async (dispatch) => {
  console.log(id);
  await Instance()
    .delete(`/backend/utilizadores/${id}`)
    .then(({ data }) => {
      dispatch({ type: 'DELETE_UTILIZADOR', payload: data });
    })
    .catch(({ err }) => console.log(err));
};

export default RemoverUtilizador;
