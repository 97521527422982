/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
//PACKAGES

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

//IMAGES
import Logo from '../../../images/logo_valleybeach_black.png';

//ACTIONS
import DadosSenhaUtilizador from '../../../utils/redux/actions/senha/dadosSenhaAction';
import SenhaJaAssociada from './components/senhaJaAssociada';
import FormDefinirSenha from './components/formDefinirSenha';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const DefinirSenha = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const hash = location.pathname.split('/')[2];
  console.log(hash);
  const fetchHash = () => {
    console.log(hash);
    dispatch(DadosSenhaUtilizador(hash));
  };

  useEffect(() => fetchHash(), []);

  const dados = useSelector((state) => state.senha.dadosDefSenha);

  const storage = localStorage;
  console.log(storage);

  return (
    <>
      {storage.token ? (
        history.push('/app/reservas')
      ) : (
        <ThemeProvider theme={defaultMaterialTheme}>
          <div className="home">
            <div className="home-left">
              <img src={Logo} alt="" className="logo" />
              <div className="login-form">
                <div className="titulo">
                  <h2>Bem Vindo!</h2>
                </div>
                {/* {dados.length === 0 ? <SenhaJaAssociada /> : null} */}
                {dados === undefined ? (
                  fetchHash()
                ) : (
                  <div style={{ width: '100%' }}>
                    {dados.length === 0 ? (
                      <SenhaJaAssociada
                        onSubmit
                        form="log_in_form"
                        dispatch={dispatch}
                        // fetch={fetch}
                        // hash={hash}
                      />
                    ) : (
                      <>
                        <FormDefinirSenha
                          onSubmit
                          form="log_in_form"
                          dispatch={dispatch}
                          dados={dados}
                          // fetch={fetch}
                          hash={hash}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="bottom"></div>
            </div>
            <div className="home-right"></div>
          </div>
        </ThemeProvider>
      )}
    </>
  );
};

export default DefinirSenha;
