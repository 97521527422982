import Instance from '../../../../Axios';

const RemoverReserva = (id) => async (dispatch) => {
  console.log(id);
  await Instance()
    .delete(`/backend/reservas/${id}`)
    .then(({ data }) => {
      dispatch({ type: 'DELETE_RESERVA', payload: data });
    })
    .catch(({ err }) => console.log(err));
};

export default RemoverReserva;
