/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import './apartamentos.css';

//PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { Button, Snackbar } from '@material-ui/core';
import { ThemeProvider, makeStyles, createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';

//COMPONENTS
import CustomToolbar from './components/customToolbar';
import ModalEdit from './components/modalEdit';
import ModalDelete from './components/modalDelete';
import ModalAdicionar from './components/modalAdicionar';
import ModalLimpezas from './components/modalLimpezas';

//ACTIONS
import GetUnidadesApartamento from '../../../utils/redux/actions/apartamentos/getUnidadesApartamentoAction';
import GetApartamento from '../../../utils/redux/actions/apartamentos/getApartamentoAction';
import GetApartamentos from '../../../utils/redux/actions/apartamentos/apartamentosAction';
import GetLimpezasApartamento from '../../../utils/redux/actions/apartamentos/getLimpezasApartamentos';

//IMAGES
import Broom from '../../../images/broom_white.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    color: theme.palette.getContrastText(yellow[600]),
    // backgroundColor: yellow[600],
    border: `1px solid #fdd835`,
    '&:hover': {
      // backgroundColor: yellow[700],
      border: '1px solid yellow[700]',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnAdicionar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  btnLimpezas: {
    // color: theme.palette.getContrastText(red[500]),
    backgroundColor: '#ce4aff',
    '&:hover': {
      backgroundColor: '#aa3dd3',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#aa3dd3',
      },
    },
  },
}));

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Apartamentos = () => {
  const classes = useStyles();
  const [responsive] = useState('vertical');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openLimpezas, setOpenLimpezas] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [apartamento, setApartamento] = useState({
    unidadesId: 0,
    wpApartamentoId: '',
    descricao: '',
    tipologia: '',
    numapartamento: '',
    numandar: '',
  });
  const [apartamentoId, setApartamentoId] = useState();
  const [apartamentoNome, setApartamentoNome] = useState();
  const [unidadeSelecionada, setUnidadeSelecionada] = useState({
    id: 0,
    descricao: '',
  });

  const [fotoSelected, setFotoSelected] = useState(null);

  const dispatch = useDispatch();

  const unidades = useSelector((state) => state.unidades.unidades);

  const resetApartamento = () => {
    setApartamento({
      unidadesId: 0,
      wpApartamentoId: '',
      descricao: '',
      tipologia: '',
      numapartamento: '',
      numandar: '',
    });
  };

  // const unidades = useSelector((state) => state.unidades.unidades);
  const apartamentos = useSelector((state) => state.apartamentos.apartamentos);
  const apartamentoInfo = useSelector((state) => state.apartamentos.apartamento);

  const limpezaInfo = useSelector((state) => state.apartamentos.limpezas);

  const fetchApartamento = (id) => {
    return dispatch(GetApartamento(id));
  };

  const fetchApartamentos = () => {
    dispatch(GetApartamentos());
    dispatch({ type: 'CLEAR_DATA_APARTAMENTOS' });
  };

  const fetchUnidades = () => {
    dispatch(GetUnidadesApartamento());
  };

  const fetchLimpezas = (id) => {
    return dispatch(GetLimpezasApartamento(id));
  };

  useEffect(() => fetchApartamentos(), []);
  useEffect(() => fetchUnidades(), []);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setErrorsApartamento({});
    if (btn == 'edit') {
      setApartamentoId(id);
      setApartamentoNome(tableMeta.rowData[0]);
      fetchApartamento(id, e).then(() => {
        setOpenEdit(true);
      });
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setApartamentoId(id);
      setApartamentoNome(tableMeta.rowData[0]);
    } else if (btn == 'limpezas') {
      setApartamentoId(id);
      setApartamentoNome(tableMeta.rowData[0]);
      fetchLimpezas(id, e).then(() => setOpenLimpezas(true));
    } else {
      resetApartamento();
      setOpenAdicionar(true);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    setFotoSelected(null);
    setErrorsApartamento({});
    if (btn == 'edit') {
      setOpenEdit(false);
      setErrorsApartamento({});
      // dispatch({ type: 'CLEAR_DATA_APARTAMENTOS' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else if (btn == 'limpezas') {
      setOpenLimpezas(false);
    } else {
      setOpenAdicionar(false);
      setErrorsApartamento({});
      // resetCategoria();
    }
    // resetApartamento();
  };

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackAdd, setOpenSnackAdd] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackDelete, setOpenSnackDelete] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'edit') {
      setOpenSnack(true);
    } else if (btn == 'delete') {
      setOpenSnackDelete(true);
    } else if (btn == 'add') {
      setOpenSnackAdd(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
    setOpenSnackDelete(false);
    setOpenSnackAdd(false);
  };

  //------------------------------------------------------------------------//

  const columns = [
    {
      name: 'descricao',
      label: 'Descrição',
    },

    {
      name: 'numandar',
      label: 'Andar',
    },
    {
      name: 'numapartamento',
      label: 'Porta',
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-categorias">
              <Button
                onClick={(e) => handleOpen('edit', e, value, tableMeta)}
                className={classes.btnEditar}
                style={{ marginRight: '20px' }}
              >
                <EditIcon style={{ color: 'white' }} />
              </Button>

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
                className={classes.btnApagar}
                style={{ marginRight: '20px' }}
              >
                <DeleteIcon style={{ color: 'white' }} />
              </Button>
              <Button
                onClick={(e) => {
                  handleOpen('limpezas', e, value, tableMeta);
                }}
                className={classes.btnLimpezas}
              >
                {/* <FaBroom style={{ color: 'white' }} size='1.4em' /> */}
                <img src={Broom} alt="limpeza" style={{ height: '1.6em', color: 'red' }} />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    customToolbar: () => {
      return <CustomToolbar handleOpen={handleOpen} />;
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  // -------------------------- Validacao apartamentos --------------------//
  const [errorsApartamento, setErrorsApartamento] = useState({});

  const validationApartamento = (fieldValues = apartamento) => {
    const temp = { ...errorsApartamento };
    if ('unidadesId' in fieldValues)
      temp.unidadesId = fieldValues.unidadesId ? '' : 'Campo Obrigatório';

    if ('wpApartamentoId' in fieldValues)
      temp.wpApartamentoId = fieldValues.wpApartamentoId ? '' : 'Campo Obrigatório';

    if ('descricao' in fieldValues)
      temp.descricao = fieldValues.descricao ? '' : 'Campo Obrigatório';

    if ('tipologia' in fieldValues)
      temp.tipologia = fieldValues.tipologia ? '' : 'Campo Obrigatório';

    if ('numapartamento' in fieldValues)
      temp.numapartamento = fieldValues.numapartamento ? '' : 'Campo Obrigatório';

    if ('numandar' in fieldValues) temp.numandar = fieldValues.numandar ? '' : 'Campo Obrigatório';

    setErrorsApartamento({
      ...temp,
    });
    if (fieldValues == apartamento) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <>
        <Snackbar open={openSnackAdd} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Apartamento adicionado com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Apartamento editado com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackDelete} autoHideDuration={3000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="success">
            Apartamento removido com sucesso!
          </Alert>
        </Snackbar>

        <ModalAdicionar
          openAdicionar={openAdicionar}
          handleClose={handleClose}
          apartamento={apartamento}
          setApartamento={setApartamento}
          validationApartamento={validationApartamento}
          errorsApartamento={errorsApartamento}
          fetchApartamentos={fetchApartamentos}
          handleOpenAlert={handleOpenAlert}
          unidades={unidades}
          fotoSelected={fotoSelected}
          setFotoSelected={setFotoSelected}
          openSnackAdd={openSnackAdd}
        />
        <ModalEdit
          openEdit={openEdit}
          handleClose={handleClose}
          apartamento={apartamento}
          setApartamento={setApartamento}
          validationApartamento={validationApartamento}
          errorsApartamento={errorsApartamento}
          fetchApartamentos={fetchApartamentos}
          apartamentoId={apartamentoId}
          handleOpenAlert={handleOpenAlert}
          apartamentoInfo={apartamentoInfo}
          apartamentoNome={apartamentoNome}
          unidadeSelecionada={unidadeSelecionada}
          setUnidadeSelecionada={setUnidadeSelecionada}
          unidades={unidades}
          fotoSelected={fotoSelected}
          setFotoSelected={setFotoSelected}
          resetApartamento={resetApartamento}
        />
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          apartamentoId={apartamentoId}
          fetchApartamentos={fetchApartamentos}
          apartamentoNome={apartamentoNome}
          handleOpenAlert={handleOpenAlert}
        />
        <ModalLimpezas
          openLimpezas={openLimpezas}
          handleClose={handleClose}
          apartamentoId={apartamentoId}
          limpezaInfo={limpezaInfo}
          // fetchApartamentos={fetchApartamentos}
          // apartamentoNome={apartamentoNome}
          handleOpenAlert={handleOpenAlert}
        />

        <MUIDataTable
          title="Apartamentos"
          description="Nada para mostrar"
          data={apartamentos}
          columns={columns}
          options={options}
        />
        {/* </MuiThemeProvider> */}
      </>
    </ThemeProvider>
  );
};

export default Apartamentos;
